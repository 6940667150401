
/**
 * Conectarnos con la API de canary lofts
 */
import {call} from './canaryLofts.js'

/**
 * Suscribe un correo
 * @param {String} email
 */
export const agregarSuscripcion = (email) => {
    return call('agregarSuscripcion', {
        email: email
    })
}