<template>
    <CanaryLoft
    nombre="Canary Lofts Glorieta"

    imagenPortada="./images/canary-loft-glorieta/portada.jpg"

    googleMapsSrc="https://www.google.es/maps/place/Glorieta+29+de+mayo,+8,+38007+Santa+Cruz+de+Tenerife/"
    :geolocalizacion="{
        lat: 28.4657831,
        lng: -16.2684055
    }"

    carpetaImagenesCarousel="canary-loft-glorieta/carousel/"
    :nImagenesCarousel="24"

    :indiceEdificioPredeterminado="3"
    :direccion="direccion"
    :servicios="servicios"
    :comentariosClientes="comentariosClientes"
    :habitaciones="habitaciones"
    :textosDescripcion="textosDescripcion" />
</template>

<script>

/**
 * Perfil edificio Canary Loft
 */
import CanaryLoft from '../components/CanaryLoft.vue'

export default {
    name: 'CanaryLoftGlorieta',
    data(){
        return {
            portada: 'https://www.barcelo.com/pinandtravel/wp-content/uploads/2018/06/1-12.jpg',

            direccion: 'Plaza 29 de Mayo, 8, Santa Cruz de tenerife, 38005, Santa cruz de tenerife, España',

            textosDescripcion:[
                'Este edificio se encuentra en la entrada a Santa Cruz de Tenerife, muy cerca del Parque La Granja, a 7 minutos andando, y de la Piscina municipal de Santa Cruz, así como del Centro Comercial Meridiano que está a 2 Km del alojamiento',
                'Este edificio dispone de 3 alturas. Cada planta es independiente y se alquila por separado como si fuera una habitación con todo lo necesario para que te sientas como en casa. Están totalmente reformados y sus grandes ventanales proporcionan mucha luminosidad al interior de las estancias'
            ],

            servicios: [{
                nombre: 'Parking',
                icon: 'parking'
            },{
                nombre: 'Cocina equipada',
                icon: 'utensils'
            },{
                nombre: 'Wi-Fi gratuito',
                icon: 'wifi'
            },{
                nombre: 'Baño privado',
                icon: 'shower'
            },{
                nombre: 'Permitido niños',
                icon: 'children'
            },{
                nombre: 'Aceptadas tarjetas de crédito',
                icon: 'credit-card'
            },{
                nombre: 'Habitaciones familiares',
                icon: 'people-group'
            },{
                nombre: 'No fumadores',
                icon: 'smoking-ban'
            },{
                nombre: 'Fiestas no permitidas',
                icon: 'champagne-glasses',
                banned: true
            }],

            fotos: [],

            habitaciones: [{
                nombre: 'Apartamento Canary Lofts Glorieta',
                foto: './images/canary-loft-glorieta/room_0.jpg',
                descripcion: 'La decoración es sencilla y con mucho gusto, toda en tonos blancos y grises, lo que la hacen un espacio muy agradable y acogedor donde llegar y descansar después de un largo día de trabajo o de vacaciones disfrutando de la isla. Está completamente equipada y se compone por dos camas individuales de 90 cm unidas y de un sillón cama en caso de necesitar alojamiento para tres personas. Además, también tiene lavadora y tanto las toallas como las sábanas están incluidas. Disponemos de dos habitaciones iguales, con las mismas características, en la primera y la segunda planta de la casa. Estas habitaciones no disponen de terraza y para acceder a ellas es necesario subir unas escaleras',
                nImagenesCarousel: 11
            },{
                nombre: 'Ático Canary Lofts Glorieta',
                foto: './images/canary-loft-glorieta/room_1.jpg',
                descripcion: 'El ático de la casa es un espacio luminoso, con dos terrazas y vistas a la ciudad. Cuenta con todo lo necesario para que se alojen hasta un máximo de 5 personas y una ducha exterior para aprovechar y remojarse los días de calor',
                nImagenesCarousel: 13
            }],

            comentariosClientes: [
                {
                    comentario: 'Cogimos el ático y las vistas de la terraza son espectaculares. La localización es también excelente, un 10, al lado de un parque precioso, en un barrio tranquilo y con buenos restaurantes. Fuimos sin coche y estábamos cerca de todo a pie. El apartamento es pequeño (prácticamente es una habitación, porque la protagonista del ático es la terraza) pero todo estaba impecable y además tiene aire acondicionado en las habitaciones. Lorena también fue flexible con la hora de salida y pudimos quedarnos un poco más. Por cierto, la ducha es de lo mejor de la casa porque sigues viendo el mar.',
                    valoracion: 5,
                    nombre: 'Claudia'
                }
            ]
        }
    },
    components:{
        CanaryLoft
    }
}
</script>