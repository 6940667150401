/**
 * Bunabil
 */
import {call} from './bunabil.js'

/**
 * Enter
 */
export const enter = async () => {
    try {
        return await call('enter')
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Login
 * @param {String} nombreUsuario
 * @param {String} codigoAcceso
 */
export const login = async (nombreUsuario, codigoAcceso) => {
    try {
        return await call('login', {
            nombreUsuario: nombreUsuario,
            codigoAcceso: codigoAcceso
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Logout
 */
export const logout = async () => {
    try {
        return await call('logout')
    } catch(e){
        throw new Error(e)
    }
}
