<template>
    <Inventario
    ref="lavanderiaEdificio"
    :items="lavanderia"
    @confirmarCambios="actualizarItemsLavanderia($event)" />
</template>

<script>

/**
 * Inventario
 */
import Inventario from './Inventario.vue'

/**
 * Manejar iventario lavanderías
 */
import {actualizarItemsLavanderiaEdificio} from '../services/lavanderias.js'

export default {
    name:'LavanderiaEdificio',
    components:{
        Inventario
    },
    props:{
        indiceEdificio:null,
        lavanderia:null
    },
    computed:{
        edificio(){
            if(this.indiceEdificio !== null && typeof this.edificios[this.indiceEdificio] !== 'undefined'){
                return this.edificios[this.indiceEdificio]
            }

            return null
        },
        edificios(){
            return this.$store.state.edificios
        }
    },
    methods:{
        async actualizarItemsLavanderia(cambiosItemsLavanderia){
            try {
                let respuesta = await actualizarItemsLavanderiaEdificio(
                    this.indiceEdificio,
                    cambiosItemsLavanderia
                )

                if(typeof respuesta.status !== 'string' || respuesta.status !== 'OK' || typeof respuesta.data === 'undefined'){
                    let error = typeof respuesta.error === 'string' ? respuesta.error : 'Error leyendo respuesta'

                    throw new Error(error)
                }

                alert('Lavanderia ' + this.edificio.nombre + ' actualizado')

                this.$emit('update', respuesta.data)

                this.$refs.lavanderiaEdificio.cambiosConfirmados()
            } catch(e){
                console.error('Error actualizando lavandería:', e)

                alert('Error actualizando lavandería: ' + e.message)
            }
        }
    }
}
</script>