/**
 * 
 */
const textos = {
    en: {
        'Apartamentos totalmente equipados en el centro de Santa Cruz de Tenerife': 'Fully equipped apartments in the center of Santa Cruz de Tenerife',
        'Bienvenido a': 'Welcome to',
        'Contáctanos': 'Contact us',
        'Sobre nosotros': 'About us',
        'Actividades': 'Activities',
        'Nuestros apartamentos': 'Our apartments',
        'Apartamentos en el centro de Santa Cruz de Tenerife para alojarte por días, meses o largas temporadas': 'Apartments in the center of Santa Cruz de Tenerife to stay for days, months or long periods',
        'Información general': 'General information',
        'Inicio': 'Home',
        'Acerca de nosotros': 'About us',
        'Reserva con nosotros': 'Book with us',
        'Nuestros edificios': 'Our buildings',
        'Tarifas': 'Rates',
        'Conoce a nuestro equipo': 'Know our team',
        'Gestión de mi reserva': 'Manage my booking',
        'Quiero cancelar mi reserva': 'I want to cancel my booking',
        'Condiciones y políticas': 'Conditions and policies',
        'Aviso legal': 'Legal warning',
        'Política de cookies': 'Cookies policy',
        'Política de privacidad': 'Privacy policy',
        'Eventos locales': 'Local events',
        'Reserva de actividades': 'Activities booking',
        '¿Qué ver en Santa Cruz de Tenerife?': 'What to see in Santa Cruz de Tenerife?',
        'Restaurantes': 'Restaurants',
        '¿Necesitas un taxi?': 'Do you need a taxi?',
        'Ayuda': 'Help',
        'Preguntas frecuentes': 'FAQ',
        'Mapa': 'Map',
        'Manuales': 'Manuals',
        'Encuéntranos en': 'Find us on',
        'Ver más': 'See more',
        'Ver comentarios': 'Show comments',
        'Ocultar comentarios': 'Hide comments',
        'Qué debes saber':'What you need to know',
        'Normas de la casa':'House\'s norms',
        'Llegada':'Check In',
        'Salida':'Check Out',
        'a partir de las':'from',
        'Disponibilidad':'Availability',
        'Buscar':'Search',
        'Prohibido fumar':'No Smoking',
        'No se admiten mascotas':'Pets Not Allowed',
        'No se admiten fiestas':'Parties Not Allowed',
        'Cocina equipada':'Equipped Kitchen',
        'Wi-Fi gratuito':'Free Wi-Fi',
        'Baño privado':'Private Bathroom',
        'Permitido niños':'Children Allowed',
        'Terraza comunitaria':'Community Terrace',
        'Aceptadas tarjetas de crédito':'Credit Cards Accepted',
        'Habitaciones familiares':'Family Rooms',
        'Fácil acceso':'Easy Access',
        'No fumadores':'No Smoking',
        'Fiestas no permitidas':'Parties Not Allowed',
        'Adultos':'Adults',
        'Niños':'Children',
        '¿De qué edades son los niños?':'What are the children\'s ages?',
        '¿A dónde quieres ir?':'Where do you want to stay?',
        'Código de huésped':'Guest code',
        'Introduce tu código de huésped':'Introduce your Guest Code',
        'Cargando datos Check In...':'Loading Check In data...',
        'Cambiar código de huésped':'Change Guest Code',
        'Suscribirme':'Subscribe',
        'Cancelar':'Cancel',
        'Confirmar':'Confirm',
        'Tu dirección de correo electrónico':'Your email address',
        'Al hacer click en confirmar, estás aceptando nuestra':'By clicking confirm, you are accepting our',

        // descripciones edificios
        'Este edificio es el más céntrico de nuestros alojamientos. Se encuentra en el corazón de Santa Cruz a 2 minutos andando del Parque García Sanabria y de la principal zona comercial del centro': 'This is the most central building of our accommodations. It is found in the heart of Santa Cruz de Tenerife, being a 2 minute walk away from Parque García Sanabria and the main shopping area',
        'Este edificio se encuentra en Santa Cruz de Tenerife, a 1,5 km del Parque García Sanabria, el mayor parque urbano de las islas Canarias. Es perfecto para cortas o largas estancias por motivos laborales o de turismo ya que dispone de un mobiliario moderno, cómodo y bonito': 'This building is in Santa Cruz de Tenerife. 1,5 km away from Parque García Sanabria, the biggest urban park in Canary Islands. It\'s perfect for short or long stays for work or turism reasons, as it counts with a modern, comfortable and pretty furniture',
        'Este edificio se encuentra en la entrada a Santa Cruz de Tenerife, muy cerca del Parque La Granja, a 7 minutos andando, y de la Piscina municipal de Santa Cruz, así como del Centro Comercial Meridiano que está a 2 Km del alojamiento': 'This building is found in the entrance of Santa Cuz de Tenerife, very close to Parque La Granja, a 7 minute walk away, and to the local pool, as well as from the Meridiano Shopping Center which is 2 km away from the accommodation',
        
    }
}

/**
 * Devuelve la traducción de un texto
 * @param {String} texto 
 * @param {String} idioma 
 */
export const traducir = (texto, idioma) => {
    if(idioma === 'es'){
        return texto
    }

    if(typeof textos[idioma] === 'object' && typeof textos[idioma][texto] === 'string'){
        return textos[idioma][texto]
    }

    return texto
}
