<template>
    <Inventario
    ref="almacenEdificio"
    :items="almacen"
    @rechazar="rechazarItemsAlmacen($event)"
    @confirmarCambios="actualizarItemsAlmacen($event)" />
</template>

<script>

/**
 * Inventario
 */
import Inventario from './Inventario.vue'

/**
 * Manejar iventario lofts
 */
import {actualizarItemsAlmacenEdificio/*,rechazarItemsAlmacen*/} from '../services/almacenes.js'

export default {
    name:'AlmacenEdificio',
    components:{
        Inventario
    },
    props:{
        indiceEdificio:null,
        almacen:null
    },
    computed:{
        edificio(){
            if(this.indiceEdificio !== null && typeof this.edificios[this.indiceEdificio] !== 'undefined'){
                return this.edificios[this.indiceEdificio]
            }

            return null
        },
        edificios(){
            return this.$store.state.edificios
        }
    },
    methods:{
        /**
         * Manda items del almacén al rechazo
         */
        /*async rechazarItemsAlmacen(datos){
            try {
                let respuesta = await rechazarItemsAlmacen(
                    this.indiceEdificio,
                    datos.idItem,
                    datos.unidades
                )

                alert('Items enviados al rechazo de ' + this.edificio.nombre)
            } catch(e){
                console.error('Error rechazando ítems:', e)

                alert('Error rechazando ítems: ' + e.message)
            }
        },*/
        /**
         * Actualiza los items del almacén
         */
        async actualizarItemsAlmacen(cambiosItemsAlmacen){
            try {
                let respuesta = await actualizarItemsAlmacenEdificio(
                    this.indiceEdificio,
                    cambiosItemsAlmacen
                )

                if(typeof respuesta.status !== 'string' || respuesta.status !== 'OK' || typeof respuesta.data === 'undefined'){
                    let error = typeof respuesta.error === 'string' ? respuesta.error : 'Error leyendo respuesta'

                    throw new Error(error)
                }

                alert('Almacén ' + this.edificio.nombre + ' actualizado')

                this.$emit('update', respuesta.data)

                this.$refs.almacenEdificio.cambiosConfirmados()
            } catch(e){
                console.error('Error actualizando almacén :', e)

                alert('Error actualizando almacén : ' + e.message)
            }
        }
    }
}
</script>