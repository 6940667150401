<template>
  <section class="hero has-background-primary is-fullheight">
    <div class="hero-body">
      <div class="container">
        <h1 class="title has-text-centered has-text-white">Bunabil</h1>

        <b-field
        @keyup.native.enter="login"
        label="Usuario" type="is-light" custom-class="has-text-white">
          <b-input v-model="nombreUsuario" icon="user" size="is-large"></b-input>
        </b-field>

        <b-field
        @keyup.native.enter="login"
        label="Contraseña" type="is-light" custom-class="has-text-white">
          <b-input v-model="codigoAcceso" type="password" icon="key" size="is-large"></b-input>
        </b-field>

        <b-button @click="login" type="is-primary" size="is-large" inverted outlined expanded class="my-6">
          Login
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>

/**
 * Login
 */
import {login} from '../services/session.js'

export default {
  name: 'LoginPortalBunabil',
  data(){
    return {
      nombreUsuario: '',
      codigoAcceso: ''
    }
  },
  methods:{
    /**
     * Intenta iniciar sesión
     */
    async login(){
      try {
        let respuesta = await login(
          this.nombreUsuario,
          this.codigoAcceso
        ),
        error = typeof respuesta.error === 'string' ? respuesta.error : undefined

        if(typeof respuesta.data === 'undefined' || !respuesta.data){
          throw new Error(error ? error : 'Error iniciando sesión')
        }

        let datosSesion = respuesta.data

        if(typeof datosSesion.usuario === 'object'){
          this.$store.dispatch('login', datosSesion)

          this.$router.push('/portal-bunabil/jornada')

          let hora = new Date().getHours()

          alert(hora > 12 ? 'Buenas tardes' : 'Buenos días')
        } else {
          throw new Error(error ? error : 'Error iniciando sesión')
        }
        
      } catch(e){
        console.error(e)

        alert('Error iniciando sesión: ' + e.message)
      }
    }
  }
}
</script>