<template>
    <section class="section">
        <div class="container">
            <h1 class="title is-size-2 has-text-weight-light has-text-centered has-text-primary">
                Ideal para
            </h1>

            <div class="columns">
                <div class="column is-4">
                    <div class="card">
                        <div class="card-image">
                            <figure class="image">
                                <b-image src="https://www.becas-santander.com/es/blog/mujeres-empresarias/_jcr_content/root/container/responsivegrid/image_688851163_copy.coreimg.jpeg/1641997612895/mujeres-empresarias-becas.jpeg" responsive></b-image>
                            </figure>
                        </div>
                        <div class="card-content has-background-secondary">
                            <h2 class="subtitle has-text-weight-light has-text-primary has-text-centered">
                                Empresarios
                            </h2>
                        </div>
                    </div>
                </div>

                <div class="column is-4">
                    <div class="card">
                        <div class="card-image">
                            <figure class="image">
                                <b-image src="https://www.slowsuites.com/wp-content/uploads/2019/02/slowsuites-En-San-Valenti%CC%81n-sorprende-a-tu-pareja-con-una-estancia-en-SlowSuites.jpg" responsive></b-image>
                            </figure>
                        </div>
                        <div class="card-content has-background-secondary">
                            <h2 class="subtitle has-text-weight-light has-text-primary has-text-centered">
                                Parejas
                            </h2>
                        </div>
                    </div>
                </div>

                <div class="column is-4">
                    <div class="card">
                        <div class="card-image">
                            <figure class="image">
                                <b-image src="https://img.freepik.com/foto-gratis/papa-toma-fotos-su-familia-telefono-inteligente_85574-9755.jpg?w=2000" responsive></b-image>
                            </figure>
                        </div>
                        <div class="card-content has-background-secondary">
                            <h2 class="subtitle has-text-weight-light has-text-primary has-text-centered">
                                Familias
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mt-3">
            <h1 class="title is-size-2 has-text-weight-light has-text-centered has-text-primary">
                Grupos
            </h1>

            <h2 class="subtitle has-text-weight-light has-text-centered has-text-primary">
                Empresas - Productoras - Equipos Deportivos
            </h2>

            <div class="columns">
                <div class="column is-4">
                    <div class="card">
                        <div class="card-image">
                            <figure class="image">
                                <b-image src="https://barradeideas.com/wp-content/uploads/2019/06/shutterstock_506954929-grupos-restaurante.jpg" responsive></b-image>
                            </figure>
                        </div>
                        <div class="card-content has-background-secondary">
                            <h2 class="subtitle has-text-weight-light has-text-primary has-text-centered">
                                De <span class="is-size-2">8</span> a <span class="is-size-2">15</span> personas
                            </h2>
                        </div>
                    </div>
                </div>

                <div class="column is-4">
                    <div class="card">
                        <div class="card-image">
                            <figure class="image">
                                <b-image src="https://nadaconexceso.com/wp-content/uploads/2017/06/equipo-de-natacion-1080x675.jpg" responsive></b-image>
                            </figure>
                        </div>
                        <div class="card-content has-background-secondary">
                            <h2 class="subtitle has-text-weight-light has-text-primary has-text-centered">
                                De <span class="is-size-2">16</span> a <span class="is-size-2">24</span> personas
                            </h2>
                        </div>
                    </div>
                </div>

                <div class="column is-4">
                    <div class="card">
                        <div class="card-image">
                            <figure class="image">
                                <b-image src="https://i.blogs.es/ce6244/trucos-consejos-retratos-grupo-14/450_1000.jpg" responsive></b-image>
                            </figure>
                        </div>
                        <div class="card-content has-background-secondary">
                            <h2 class="subtitle has-text-weight-light has-text-primary has-text-centered">
                                De <span class="is-size-2">25</span> a <span class="is-size-2">50</span> personas
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Paquetes'
}
</script>

<style scoped>
.card-image{
    max-height: 30vh;
    overflow: hidden;
}
</style>