/**
 * 
 */

const XLSX = require('xlsx')

const worksheet = XLSX.utils.json_to_sheet([
    {
        name:'Test',
        horas:45
    }
])

const workbook = XLSX.utils.book_new()

XLSX.utils.book_append_sheet(workbook, worksheet, 'Fechas')

/**
 * Exporta un excel
 */
export const exportarExcel = () => {
    XLSX.writeFile(workbook, 'Presidents.xlsx')
}
