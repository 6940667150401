<template>
    <section class="section vista-servicios pb-0">
        <div class="container">
            <h1 class="title is-size-2 has-text-weight-light has-text-centered has-text-primary">
                {{traducir('Nuestros apartamentos')}}
            </h1>

            <NuestrosEdificios />
            
        </div>
    </section>
</template>

<script>

/**
 * Nuestros edificios
 */
import NuestrosEdificios from '../components/NuestrosEdificios.vue'

/**
 * Traducciones
 */
import {traducir} from '../services/traducciones.js'

export default {
    name: 'Servicios',
    components:{
        NuestrosEdificios
    },
    computed:{
        idioma(){
            return this.$store.state.idioma
        }
    },
    methods:{
        traducir(texto){
            return traducir(texto, this.idioma)
        },
        irInicio(){
            this.$router.push('/')

            window.scrollTo(0, 0)
        }
    }
}
</script>

<style>

.vista-servicios figure{
    overflow: hidden;
}

.vista-servicios section.tab-content{
    border-color:#dbdbdb !important;
    border-top: none;
}

.vista-servicios article img{
    border-radius: 0.5rem;
}

.vista-servicios figure.image.otro-servicio.movil{
    max-height:25vh;
    overflow: hidden;
}

</style>