/**
 * Bunabil
 */
import {call} from './bunabil.js'

/**
 * Actualizar los items de un loft
 * @param {String} idLoft
 * @param {Object} items
 */
export const actualizarItemsLoft = async (idLoft, items) => {
    try {
        return await call('actualizarItemsLoft', {
            idLoft: idLoft,
            items: items
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Crear un nuevo loft
 * @param {Number} indiceEdificio
 * @param {String} nombreLoft
 * @param {Boolean} esZonaComun
 */
export const crearLoft = async (indiceEdificio, nombreLoft, esZonaComun) => {
    try {
        return await call('crearLoft', {
            indiceEdificio: indiceEdificio,
            nombreLoft: nombreLoft,
            zonaComun: esZonaComun
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Elimina un loft
 * @param {String} idLoft
 */
export const eliminarLoft = async (idLoft) => {
    try {
        return await call('eliminarLoft', {
            idLoft: idLoft
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Edita un loft
 * @param {String} idLoft
 * @param {String} propiedad
 * @param {String} nuevoValor
 */
export const editarLoft = async (idLoft, propiedad, nuevoValor) => {
    try {
        return await call('editarLoft', {
            idLoft: idLoft,
            propiedad:propiedad,
            nuevoValor: nuevoValor
        })
    } catch(e){
        throw new Error(e)
    }
}
