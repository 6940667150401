import {call} from './bunabil.js'

/**
 * Registra un rango de vacaciones para un usuario
 * @param {idUsuario} Number
 * @param {String} fechaDesde
 * @param {String} fechaHasta
 * @param {String} motivoBaja
 */
 export const registrarBaja = async (idUsuario, fechaDesde, fechaHasta, motivoBaja) => {
    try {
        return await call('registrarBaja', {
            idUsuario: idUsuario,
            fechaDesde: fechaDesde,
            fechaHasta: fechaHasta,
            motivoBaja: motivoBaja
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Registra un rango de vacaciones para un usuario
 * @param {idUsuario} Number
 * @param {String} fechaDesde
 * @param {String} fechaHasta
 */
export const registrarVacaciones = async (idUsuario, fechaDesde, fechaHasta) => {
    try {
        return await call('registrarVacaciones', {
            idUsuario: idUsuario,
            fechaDesde: fechaDesde,
            fechaHasta: fechaHasta
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Obtener vacaciones y bajas usuarios
 * @param {idUsuario} Number
 */
export const obtenerVacacionesBajasUsuario = async (idUsuario) => {
    try {
        return await call('obtenerVacacionesBajasUsuario', {
            idUsuario: idUsuario
        })
    } catch(e){
        throw new Error(e)
    }
}