<template>
    <div id="portal-bunabil" style="min-height:100vh">
        <Menu @logout="logout" />
        
        <section class="section">
            <div class="container">
                <router-view></router-view>
            </div>
        </section>
    </div>
</template>

<script>

/**
 * Menu Portal Bunabil
 */
import Menu from '../components/Menu.vue'

/**
 * Comprobar sesión
 */
import {enter} from '../services/session.js'

/**
 * Logout
 */
import {logout} from '../services/session.js'

export default {
    name:'PortalBunabil',
    data(){
        return {
            loginComprobado:false
        }
    },
    components:{
        Menu
    },
    mounted(){
        try {
            this.enter()
        } catch(e){
            console.log('No hay sesión')
        }
    },
    methods:{
        /**
         * Desconecta al usuario
         */
        async logout(){
            try {
                await logout()

                this.$store.dispatch('logout')

                this.$router.push('/login-portal-bunabil')
                
            } catch(e){
                console.error()
            }
        },
        /**
         * Comprueba sesión existente
         */
        async enter(){
            if(!this.loginComprobado){
                this.loginComprobado = true

                try {
                    let respuesta = await enter()

                    this.respuestaEnter(respuesta)

                    if(this.$route.name === 'PortalBunabil'){
                        this.$router.push('/portal-bunabil/jornada')
                    }
                } catch(e){
                    console.error('Error comprobando sesión:', e)

                    this.$store.dispatch('logout')

                    this.$router.push('/login-portal-bunabil')
                }
            }
        },
        /**
         * Procesa la respuesta de enter
         * @param {Object} respuesta
         */
        respuestaEnter(respuesta){
            let estamosEnVistaLogin = this.$route.path.indexOf('login') !== -1,
            loginCorrecto = typeof respuesta.data === 'object' && respuesta.data && typeof respuesta.data.usuario === 'object'

            if(loginCorrecto){
                // actualizamos store
                this.$store.dispatch('login', respuesta.data)

                if(estamosEnVistaLogin){
                    this.$router.push('/portal-bunabil/jornada')
                }
            } else if(!estamosEnVistaLogin){
                this.$router.push('/login-portal-bunabil')
            }
        }
    }
}
</script>