<template>
    <div class="content has-background-secondary">

        <AvailRoom />

    </div>
</template>

<script>

/**
 * AvailRoom
 */
import AvailRoom from './AvailRoom.vue'

export default {
    name: 'MotorReservasAvailRoom',
    components:{
        AvailRoom
    },
    mounted(){
        this.$emit('mounted')
    }
}
</script>

<style>
iframe{
    width: 100%;
    height: 100vh;
}
.tabs-buscador-disponibilidad section.tab-content{
    padding-left: 0;
    padding-right: 0;
}

/** AvailRoom */
.Home_Home__1q_bh {
    background-color: #F4EEE7 !important;
    color: #72664F !important;
}

.Home_Home__1q_bh button{
    background-color: #72664F !important;
}

.Home_Home__1q_bh button, .Home_Home__1q_bh button *{
    color: #F4EEE7 !important;
}

.Home_Home__1q_bh .PropertyCard_propertyCard__1EwDu, .InfoCard_info_container__1_4dH, .RoomRates_rates_selectors__BMIZN, .UserInformation_wrapper__1GGec, .PaymentInformation_info_container__1HlkE{
    background-color: white !important;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}

.Home_Home__1q_bh .TopBar_languageContainer__1_1q3, .Home_propertyCardTitle__3Jiel, .PropertyHead_head_title__IPKaD, .InfoCard_info_title__a_H2Z, .InfoCard_info_title__a_H2Z p, .CustomHeading_title__2aj1t, .RoomInfoCard_info_title__24TFR, .DateSelector_date_title__2j-lf, .OccupancySelector_date_title__JFljL, .RatesCard_rate_container_left__20Xe9 p, .RatesCard_price_price__1fIIv, .MuiCheckbox-root{
    color: #72664F !important;
}

.Home_Home__1q_bh *, .Home_Home__1q_bh .css-187b8al, .InfoCard_info_container__1_4dH, .CustomHeading_divider_main__1QX2P, .RoomInfoCard_info_divider__2qPZG, .RoomRates_rates_selectors__BMIZN, .RatesTabs_tab_selector_active__3mcuk, .ReviewCard_review_container__1SKlr, .chakra-input__group, .PaymentInformation_info_divider__19edZ, .PropertyCard_card_divider__KTSut{
    border-color: #72664F !important;
}

.FilterBar_mainContainer__1T6E9 .FilterBar_switch__k5UTG .FilterBar_slider___z_mQ, button.bCKQQO, .css-1oi6yiz[aria-checked="true"], .css-1oi6yiz[data-checked]{
    background-color: #72664F !important;
}

.UserInformation_tab_selector__5njeE, .UserInformation_tab_selector__5njeE p{
    background-color: #F4EEE7 !important;
    color: #72664F !important;
    border-color: #72664F !important;
}

.chakra-input::placeholder, .chakra-input:-ms-input-placeholder, .chakra-input::-ms-input-placeholder {
  color: #72664F !important;
}

</style>