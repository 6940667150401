<template>
    <div class="container">
        <h1 class="title">Usuarios</h1>
        <div class="content" style="overflow:auto;">
            <table
            @mouseleave="usuarioHover = undefined"
            class="table is-fullwidth has-text-secondary has-background-primary">
                <thead>
                    <tr>
                        <th class="has-text-secondary">Nombre</th>
                        <th class="has-text-secondary">Rol</th>
                        <th class="has-text-secondary has-text-centered">Horas semanales</th>
                        <th class="has-text-secondary has-text-right">Opciones</th>
                    </tr>
                </thead>
                <tbody
                v-for="(usuario,idUsuario) in usuarios"
                :key="idUsuario"
                @mouseover="usuarioHover = idUsuario"
                :class="{'has-background-secondary': idUsuario === usuarioHover, 'has-text-primary': idUsuario === usuarioHover}">
                    <tr>
                        <td>
                            <b-field v-if="editandoEsteUsuario(idUsuario)" type="is-light">
                                <b-input v-model="usuarioEditar.nombre" placeholder="Nuevo nombre"></b-input>
                            </b-field>

                            <span v-else>
                                {{usuario.nombre}}
                            </span>
                        </td>
                        <td>
                            <b-field v-if="editandoEsteUsuario(idUsuario)" type="is-light">
                                <b-select v-model="usuarioEditar.rol">
                                    <option
                                    v-for="(rol,indiceRol) in roles"
                                    :key="indiceRol"
                                    :value="indiceRol">
                                        {{rol}}
                                    </option>
                                </b-select>
                            </b-field>

                            <span v-else>
                                {{usuario.rol == 0 ? 'Administrador' : 'Estándar'}}
                            </span>
                        </td>
                        <td class="has-text-centered">
                            <b-field v-if="editandoEsteUsuario(idUsuario)" type="is-light">
                                <b-input v-model="usuarioEditar.horas" type="number" placeholder="Horas semanales"></b-input>
                            </b-field>

                            <span v-else>{{usuario.horas ? usuario.horas : 'No establecidas'}}</span>
                        </td>
                        <td class="has-text-right">
                            <div class="buttons is-right">
                                <b-button
                                @click="$refs.modalVacaciones.setUsuario(usuario)"
                                v-show="!editandoEsteUsuario(idUsuario)"
                                icon-left="calendar"
                                type="is-secondary">
                                    Vacaciones &amp; Bajas
                                </b-button>

                                <b-button
                                @click="editarUsuario"
                                v-if="editandoEsteUsuario(idUsuario)"
                                type="is-secondary"
                                icon-left="save">
                                    Confirmar cambios
                                </b-button>
                                
                                <b-button
                                @click="usuarioEditar = editandoEsteUsuario(idUsuario) ? null : clonarObjeto(usuario)"
                                class="mx-1"
                                type="is-secondary"
                                :icon-left="editandoEsteUsuario(idUsuario) ? 'times' : 'pencil'">
                                    <span v-if="editandoEsteUsuario(idUsuario)">
                                        Descartar
                                    </span>
                                    <span v-else>Editar</span>
                                </b-button>

                                <b-button
                                v-show="!editandoEsteUsuario(idUsuario)"
                                v-if="usuarioLogueado"
                                @click="usuarioBorrar = usuario;borrandoUsuario = true"
                                :disabled="idUsuario == usuarioLogueado.id"
                                type="is-danger" icon-left="trash">
                                    Borrar
                                </b-button>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="4">
                            <b-button @click="creandoUsuario = !creandoUsuario" icon-left="plus-circle" type="is-secondary" expanded>
                                {{creandoUsuario ? 'Descartar' : 'Crear'}} usuario
                            </b-button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <!-- Modal vacaciones -->
        <ModalVacaciones ref="modalVacaciones" />
        <!-- Modal bajas -->

        <!-- Modal crear usuario -->
        <b-modal v-model="creandoUsuario">
            <div class="card has-text-justified has-background-info">
                <div class="card-content">
                    <div class="content">
                        <div class="columns">
                            <div class="column">
                                <b-field type="is-light" label="Nombre" custom-class="has-text-white">
                                    <b-input
                                    v-model="nuevoUsuario.nombre"
                                    expanded placeholder="Nombre">
                                        
                                    </b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field type="is-light" label="Rol" custom-class="has-text-white">
                                    <b-select
                                    v-model="nuevoUsuario.rol">
                                        <option
                                        v-for="(rol,indiceRol) in roles"
                                        :key="indiceRol"
                                        :value="indiceRol">
                                            {{rol}}
                                        </option>
                                    </b-select>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <b-field type="is-light" label="Código acceso" custom-class="has-text-white">
                                    <b-input
                                    v-model="nuevoUsuario.codigoAcceso"
                                    expanded placeholder="Código acceso" type="password">
                                        
                                    </b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field type="is-light" label="Confirmar código acceso" custom-class="has-text-white">
                                    <b-input
                                    v-model="nuevoUsuario.confirmarCodigoAcceso"
                                    expanded placeholder="Confirmar código acceso" type="password">
                                        
                                    </b-input>
                                </b-field>
                            </div>
                        </div>

                        <div class="is-right buttons">
                            <b-button @click="creandoUsuario = false" type="is-danger">
                                Cancelar
                            </b-button>
                            <b-button
                            :disabled="!nuevoUsuarioValido"
                            @click="crearUsuario" type="is-info" inverted outlined>
                                Confirmar
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>

        <!-- Modal borrar usuario -->
        <b-modal v-model="borrandoUsuario">
            <div class="card has-text-justified has-background-info">
                <div class="card-content">
                    <div class="content">
                        <h1 v-if="usuarioBorrar" class="subtitle has-text-white">
                            ¿Borrar al usuario {{usuarioBorrar.nombre}}?
                        </h1>

                        <div class="is-right buttons">
                            <b-button @click="borrandoUsuario = false" type="is-danger">
                                Cancelar
                            </b-button>
                            <b-button @click="eliminarUsuario" type="is-info" inverted outlined>
                                Confirmar
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>

/**
 * Modal vacaciones
 */
import ModalVacaciones from '../components/ModalVacaciones.vue'

/**
 * Gestionar usuarios
 */
import {crearUsuario,editarUsuario,eliminarUsuario} from '../services/usuarios.js'

export default {
    name: 'VistaUsuarios',
    components:{
        ModalVacaciones
    },
    data(){
        return {
            // usuario hover
            usuarioHover: undefined,

            // roles
            roles: ['Administrador', 'Estándar'],

            // crear
            nuevoUsuario:{
                nombre:'',
                rol:1,
                codigoAcceso:'',
                confirmarCodigoAcceso:''
            },
            creandoUsuario: false,

            // editar
            usuarioEditar: {
                nombre: '',
                rol: 1,
                horas: 0
            },

            // borrar
            borrandoUsuario: false,
            usuarioBorrar: null
        }
    },
    computed:{
        nuevoUsuarioValido(){
            if(this.nuevoUsuario.nombre.length < 4){
                return false
            }

            if(this.nuevoUsuario.codigoAcceso.length < 4 || this.nuevoUsuario.codigoAcceso !== this.nuevoUsuario.confirmarCodigoAcceso){
                return false
            }

            return true
        },
        esAdministrador(){
            return this.$store.state.esAdministrador
        },
        usuarioLogueado(){
            return this.$store.state.usuarioLogueado
        },
        usuarios(){
            return this.$store.state.usuarios
        }
    },
    mounted(){
        this.$emit('enter')
    },
    methods:{
        /**
         * Clona un objeto
         */
        clonarObjeto(objeto){
            return JSON.parse(JSON.stringify(objeto))
        },
        /**
         * Elimina un usuario
         */
        async eliminarUsuario(){
            try {
                let respuesta = await eliminarUsuario(
                    this.usuarioBorrar.id
                )

                if(typeof respuesta.data === 'undefined' || !respuesta.data){
                    let error = typeof respuesta.error === 'string' ? respuesta.error : 'Error inesperado'

                    throw new Error(error)
                }

                alert('Usuario eliminado correctamente')

                this.$store.dispatch('updateUsuarios', respuesta.data)

                this.borrandoUsuario = false
            } catch(e){
                console.error('Error eliminando usuario:', e)

                alert('Error eliminando usuario: ' + e)
            }
        },
        /**
         * Edita un usuario
         */
        async editarUsuario(){

            try {
                let respuesta = await editarUsuario(
                    this.usuarioEditar.id,
                    this.usuarioEditar
                )

                if(typeof respuesta.data === 'undefined' || !respuesta.data){
                    let error = typeof respuesta.error === 'string' ? respuesta.error : 'Error inesperado'

                    throw new Error(error)
                }

                alert('Usuario editado correctamente')

                this.$store.dispatch('updateUsuarios', respuesta.data)
            } catch(e){
                console.error('Error editando usuario:', e)

                alert('Error editando usuario: ' + e)
            }
        },
        /**
         * Crea un usuario
         */
        async crearUsuario(){
            try {
                let respuesta = await crearUsuario(
                    this.nuevoUsuario.nombre,
                    this.nuevoUsuario.rol,
                    this.nuevoUsuario.codigoAcceso
                )

                if(typeof respuesta.data === 'undefined' || !respuesta.data){
                    let error = typeof respuesta.error === 'string' ? respuesta.error : 'Error inesperado'

                    throw new Error(error)
                }

                alert('Usuario creado correctamente')

                this.$store.dispatch('updateUsuarios', respuesta.data)

                this.creandoUsuario = false
                this.nuevoUsuario.nombre = ''
                this.nuevoUsuario.rol = ''
                this.nuevoUsuario.codigoAcceso = ''
                this.nuevoUsuario.confirmarCodigoAcceso = ''
            } catch(e){
                console.error('Error editando usuario:', e)

                alert('Error editando usuario: ' + e)
            }
        },
        /**
         * Pregunta si se está editando al usuario
         */
        editandoEsteUsuario(idUsuario){
            return this.usuarioEditar && this.usuarioEditar.id == idUsuario
        }
    }
}
</script>
