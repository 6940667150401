import axios from 'axios'

/**
 * Endpoint servicios Bunabil
 * @var {String}
 */
//const apiEndPoint = 'http://localhost/bunabil/'
const apiEndPoint = 'https://www.canarylofts.es/v2/bunabil/'

export const call = async (service, params) => {
    let requestBody = new URLSearchParams({
        service: service,
        params: JSON.stringify(params)
    })

    return axios.post(apiEndPoint, requestBody, {
        withCredentials: true,
        responseType: 'text'
    })

    // Procesar respuesta
    .then((response) => {
        if(response.status !== 200){
            throw new Error(response)
        }

        return response.data
    })

    // Manejar error
    .catch((e) => {
        throw new Error(e)
    })
}
 