/**
 * Bunabil
 */
import {call} from './bunabil.js'

/**
 * Emite los correos de una plantilla
 * @param {Number} idPlantilla
 * @return {Promise}
 */
export const emitirPlantillaCorreo = async (idPlantilla) => {
    try {
        return await call('emitirPlantillaCorreo', {
            idPlantilla: idPlantilla
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Obtener plantillas creadas
 * @return {Promise}
 */
export const obtenerPlantillas = async () => {
    try {
        return await call('obtenerPlantillasCorreo')
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Crear plantilla
 * @param {String} nombre
 * @param {String} texto
 * @return {Promise}
 */
export const crearPlantilla = async (nombre, texto) => {
    try {
        return await call('crearPlantillaCorreo', {
            nombre: nombre,
            texto: texto
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Editar plantilla
 * @param {String} idPlantilla
 * @param {String} nombre
 * @param {String} texto
 * @return {Promise}
 */
export const editarPlantilla = async (idPlantilla, nombre, texto) => {
    try {
        return await call('editarPlantillaCorreo', {
            idPlantilla: idPlantilla,
            nombre: nombre,
            texto: texto
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Borrar plantilla
 * @param {String} idPlantilla
 * @return {Promise}
 */
export const borrarPlantilla = async (idPlantilla) => {
    try {
        return await call('borrarPlantillaCorreo', {
            idPlantilla: idPlantilla
        })
    } catch(e){
        throw new Error(e)
    }
}