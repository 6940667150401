<template>
    <div class="nuestros-edificios columns">
        <div class="column">
            <div class="card has-background-secondary has-text-primary">
                <div class="card-image">
                    <router-link :to="{ name: 'CanaryLoftPalace' }">
                        <!--<figure class="image palace">
                            <b-image responsive src="./images/canary-loft-palace/fachada.jpg" alt="Canary Lofts Palace"></b-image>
                        </figure>-->
                        <b-image responsive src="./images/canary-loft-palace/fachada.jpg" alt="Canary Lofts Palace"></b-image>
                    </router-link>
                </div>

                <div class="card-content">
                    <div class="content">
                        <h1 class="title has-text-weight-light has-text-centered mb-3">
                            Canary Lofts Palace
                        </h1>

                        <b-dropdown :triggers="['hover']" aria-role="dialog">
                            <template #trigger>
                                <p>
                                    <a href="https://www.google.com/maps/place/Canary+Lofts+Palace/@28.4746956,-16.2545729,19z/data=!3m1!4b1!4m5!3m4!1s0xc41cdf43751c6f7:0xa3446795a1fa2cba!8m2!3d28.4746956!4d-16.2540257?hl=en-US" target="_blank">
                                        <span class="subtitle is-6 has-text-primary"><b-icon icon="location-dot"></b-icon> Calle Doctor Jose Naveiras 13, Santa Cruz de Tenerife, 38001, Santa cruz de tenerife, España</span>
                                    </a>
                                </p>
                            </template>

                            <b-dropdown-item custom focusable paddingless>
                                <GmapMap
                                :center="geolocalizaciones[0]"
                                :zoom="zoomMapas"
                                map-type-id="roadmap"
                                style="width: 500px; height: 300px">
                                    <GmapMarker
                                    :position="geolocalizaciones[0]"
                                    :clickable="true"
                                    :draggable="true"
                                    @click="center=geolocalizaciones[0]" />
                                </GmapMap>
                            </b-dropdown-item>
                        </b-dropdown>

                        <p class="has-text-justified mt-3">
                            {{traducir('Este edificio es el más céntrico de nuestros alojamientos. Se encuentra en el corazón de Santa Cruz a 2 minutos andando del Parque García Sanabria y de la principal zona comercial del centro')}}
                        </p>
                    </div>
                </div>
                
                <div class="card-footer">
                    <b-button
                    tag="router-link"
                    :to="{ name: 'CanaryLoftPalace' }"
                    class="card-footer-item" type="is-ghost" size="is-large" outlined expanded>
                        {{traducir('Ver más')}}
                    </b-button>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="card has-background-secondary has-text-primary">
                <div class="card-image">
                    <router-link :to="{ name: 'CanaryLoftSalamanca' }">
                        <!--<figure class="image salamanca">
                            <b-image responsive src="./images/canary-loft-salamanca/fachada.jpg" alt="Canary Lofts Salamanca"></b-image>
                        </figure>-->
                        <b-image responsive src="./images/canary-loft-salamanca/fachada.jpg" alt="Canary Lofts Salamanca"></b-image>
                    </router-link>
                </div>
                
                <div class="card-content">
                    <div class="content">
                        <h1 class="title has-text-weight-light has-text-centered mb-3">
                            Canary Lofts Salamanca
                        </h1>

                        <b-dropdown :triggers="['hover']" aria-role="dialog">
                            <template #trigger>
                                <p>
                                    <a href="https://www.google.com/maps/@28.469254,-16.266028,20z?hl=en-US" target="_blank">
                                        <span class="subtitle is-6 has-text-primary"><b-icon icon="location-dot"></b-icon> C/ Manuel Verdugo, 4, Santa Cruz de Tenerife, 38006, Santa cruz de tenerife, España</span>
                                    </a>
                                </p>
                            </template>

                            <b-dropdown-item custom focusable paddingless>
                                <GmapMap
                                :center="geolocalizaciones[1]"
                                :zoom="zoomMapas"
                                map-type-id="roadmap"
                                style="width: 500px; height: 300px">
                                    <GmapMarker
                                    :position="geolocalizaciones[1]"
                                    :clickable="true"
                                    :draggable="true"
                                    @click="center=geolocalizaciones[1]" />
                                </GmapMap>
                            </b-dropdown-item>
                        </b-dropdown>

                        <p class="has-text-justified">
                            {{traducir('Este edificio se encuentra en Santa Cruz de Tenerife, a 1,5 km del Parque García Sanabria, el mayor parque urbano de las islas Canarias. Es perfecto para cortas o largas estancias por motivos laborales o de turismo ya que dispone de un mobiliario moderno, cómodo y bonito')}}
                        </p>
                    </div>
                </div>
                
                <div class="card-footer">
                    <b-button
                    tag="router-link"
                    :to="{ name: 'CanaryLoftSalamanca' }"
                    class="card-footer-item"
                    type="is-ghost" size="is-large"
                    outlined
                    expanded>
                        {{traducir('Ver más')}}
                    </b-button>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="card has-background-secondary has-text-primary">
                <div class="card-image">
                    <router-link :to="{ name: 'CanaryLoftGlorieta' }">
                        <!--<figure class="image glorieta">
                            <b-image responsive src="./images/canary-loft-glorieta/fachada.jpg" alt="Canary Lofts Glorieta"></b-image>
                        </figure>-->
                        <b-image responsive src="./images/canary-loft-glorieta/fachada.jpg" alt="Canary Lofts Glorieta"></b-image>
                    </router-link>
                </div>

                <div class="card-content">
                    <div class="content">
                        <h1 class="title has-text-weight-light has-text-centered mb-3">
                            Canary Lofts Glorieta
                        </h1>

                        <b-dropdown :triggers="['hover']" aria-role="dialog">
                            <template #trigger>
                                <p>
                                    <a href="https://www.google.com/maps?ll=28.465717,-16.270798&z=20&t=m&hl=en-US&gl=US&mapclient=apiv3" target="_blank">
                                        <span class="subtitle is-6 has-text-primary"><b-icon icon="location-dot"></b-icon> Plaza 29 de Mayo, 8, Santa Cruz de tenerife, 38005, Santa cruz de tenerife, España</span>
                                    </a>
                                </p>
                            </template>

                            <b-dropdown-item custom focusable paddingless>
                                <GmapMap
                                :center="geolocalizaciones[2]"
                                :zoom="zoomMapas"
                                map-type-id="roadmap"
                                style="width: 500px; height: 300px">
                                    <GmapMarker
                                    :position="geolocalizaciones[2]"
                                    :clickable="true"
                                    :draggable="true"
                                    @click="center=geolocalizaciones[2]" />
                                </GmapMap>
                            </b-dropdown-item>
                        </b-dropdown>

                        <p class="has-text-justified">
                            {{traducir('Este edificio se encuentra en la entrada a Santa Cruz de Tenerife, muy cerca del Parque La Granja, a 7 minutos andando, y de la Piscina municipal de Santa Cruz, así como del Centro Comercial Meridiano que está a 2 Km del alojamiento')}}
                        </p>
                    </div>
                </div>
                
                <div class="card-footer">
                    <b-button
                    tag="router-link"
                    :to="{ name: 'CanaryLoftGlorieta' }"
                    class="card-footer-item"
                    type="is-ghost" size="is-large"
                    outlined
                    expanded>
                        {{traducir('Ver más')}}
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

/**
 * Traducciones
 */
import {traducir} from '../services/traducciones.js'

export default {
    name: 'NuestrosEdificiosComponent',
    data(){
        return {
            zoomMapas: 18,
            geolocalizaciones:[
                // Palace
                {
                    lat: 28.4746956,
                    lng: -16.2540257
                },

                // Salamanca
                {
                    lat: 28.4692543,
                    lng: -16.2660282
                },

                // Glorieta
                {
                    lat: 28.4657831,
                    lng: -16.2684055
                }
            ],
            marcadores: [
                // Palace
                {
                    position:{
                        lat: 28.4746956,
                        lng: -16.2540257
                    }
                },
                
                // Salamanca
                {
                    position:{
                        lat: 28.4692543,
                        lng: -16.2660282
                    }
                },
                
                // Glorieta
                {
                    position:{
                        lat: 28.4657171,
                        lng: -16.270798
                    }
                }
            ]
        }
    },
    computed:{
        idioma(){
            return this.$store.state.idioma
        }
    },
    methods:{
        traducir(texto){
            return traducir(texto, this.idioma)
        }
    }
}
</script>