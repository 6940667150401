<template>
    <b-modal :active.sync="active">
        <div class="card">
            <div class="card-header has-background-primary">
                <h1 v-if="usuario" class="card-header-title title is-centered has-text-secondary">{{usuario.nombre}}</h1>
            </div>
            <div class="card-content">
                <div class="content">
                    <div class="columns">
                        <div class="column">
                            <b-datepicker
                            :loading="cargando"
                            inline
                            v-model="dates"
                            range
                            :events="eventos"
                            indicators="bars"
                            locale="es"></b-datepicker>
                        </div>
                        <div class="column">
                            <b-tabs v-model="tabActiva" expanded>
                                <b-tab-item label="Vacaciones">
                                    <h2 class="subtitle has-text-centered">{{fechasProcesadas.vacaciones.length}} / 30</h2>
                                    <b-autocomplete
                                    @select="setRangoFechas"
                                    size="is-large"
                                    placeholder="Seleccionar rango de vacaciones"
                                    open-on-focus
                                    :data="vacaciones">
                                        <template slot-scope="props">
                                            <div class="media">
                                                <div class="media-content has-text-centered">
                                                    Del <span class="has-text-weight-bold">{{props.option.fechaDesde | moment('DD/MM/YYYY')}}</span> al <span class="has-text-weight-bold">{{props.option.fechaHasta | moment('DD/MM/YYYY')}}</span> ( {{diferenciaEnDias(props.option.fechaDesde, props.option.fechaHasta)}} días )
                                                </div>
                                            </div>
                                        </template>
                                    </b-autocomplete>
                                </b-tab-item>

                                <b-tab-item label="Bajas">
                                    <h2 class="subtitle has-text-centered">{{fechasProcesadas.bajas.length}}</h2>
                                    <b-autocomplete
                                    @select="setRangoFechas"
                                    size="is-large"
                                    placeholder="Seleccionar rango de bajas"
                                    open-on-focus
                                    :data="bajas">
                                        <template slot-scope="props">
                                            <div class="media">
                                                <div class="media-content has-text-centered">
                                                    <p>
                                                        Del <span class="has-text-weight-bold">{{props.option.fechaDesde | moment('DD/MM/YYYY')}}</span> al <span class="has-text-weight-bold">{{props.option.fechaHasta | moment('DD/MM/YYYY')}}</span> ( {{diferenciaEnDias(props.option.fechaDesde, props.option.fechaHasta)}} días )
                                                    </p>
                                                    <p>
                                                        <b>Motivo :</b> {{props.option.motivo}}
                                                    </p>
                                                </div>
                                            </div>
                                        </template>
                                    </b-autocomplete>
                                </b-tab-item>
                            </b-tabs>

                            <b-button
                            @click="modalConfirmarRegistro = true"
                            type="is-primary" size="is-large" expanded>Registrar {{labelTabUsuario}}</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal :active.sync="modalConfirmarRegistro">
            <b-notification :closable="false" type="is-info">
                <h1 class="subtitle">
                    ¿Registrar {{labelTabUsuario}} del {{dates[0] | moment('DD/MM/YYYY')}} al {{dates[1] | moment('DD/MM/YYYY')}} ( {{diasDiferenciaRangoEnDias}} días ) a {{usuario.nombre}}?
                </h1>

                <b-field v-if="tabActiva === 1" label="Motivo de la baja" type="is-light" custom-class="has-text-white">
                    <b-input v-model="motivoBaja" placeholder="Argumentar brevemente el motivo de la baja" type="textarea" maxlength="500"></b-input>
                </b-field>

                <div class="buttons is-right">
                    <b-button @click="modalConfirmarRegistro = false" type="is-danger">
                        Cancelar
                    </b-button>

                    <b-button @click="registrar" type="is-info" outlined inverted>
                        Confirmar
                    </b-button>
                </div>
            </b-notification>
        </b-modal>
    </b-modal>
</template>

<script>

/**
 * Vacaciones y bajas usuarios
 */
import {obtenerVacacionesBajasUsuario, registrarVacaciones, registrarBaja} from '../services/vacacionesBajasUsuarios.js'

export default {
    name: 'ModalVacaciones',
    data(){
        return {
            usuario: {
                id: 0,
                nombre: '',
                rol: 1
            },
            active : false,
            cargando: false,
            tabActiva: 0,
            dates: [
                new Date(),
                new Date()
            ],
            motivoBaja: '',
            modalConfirmarRegistro: false,
            vacaciones:[],
            bajas:[],
            eventos: [],
            fechasProcesadas: {
                vacaciones: [],
                bajas: []
            }
        }
    },
    computed:{
        diasDiferenciaRangoEnDias(){
            return this.diferenciaEnDias(
                this.dates[0],
                this.dates[1]
            )
        },
        labelTabUsuario(){
            return this.tabActiva === 0 ? 'vacaciones' : 'baja'
        }
    },
    methods:{
        setRangoFechas(option){
            let fechaDesde = new Date(
                Date.parse(option.fechaDesde)
            ),
            fechaHasta = new Date(
                Date.parse(option.fechaHasta)
            )

            this.dates = [fechaDesde, fechaHasta]
        },
        diferenciaEnDias(fechaDesde, fechaHasta){
            if(typeof fechaDesde === 'string'){
                fechaDesde = new Date(
                    Date.parse(fechaDesde)
                )
            }

            if(typeof fechaHasta === 'string'){
                fechaHasta = new Date(
                    Date.parse(fechaHasta)
                )
            }

            let desde = this.$moment(fechaDesde),
            hasta = this.$moment(fechaHasta),
            dias = hasta.diff(desde, 'days')

            return dias + 1
        },
        registrar(){
            if(this.tabActiva === 0){
                this.registrarVacaciones()
            } else {
                this.registrarBaja()
            }
        },
        /**
         * Registrar una baja para un usuario
         */
        async registrarBaja(){
            try {
                let respuesta = await registrarBaja(
                    this.usuario.id,
                    this.$moment(this.dates[0]).format('YYYY-MM-DD'),
                    this.$moment(this.dates[1]).format('YYYY-MM-DD'),
                    this.motivoBaja
                )

                this.procesarRespuesta(respuesta)

                this.motivoBaja = ''

                this.modalConfirmarRegistro = false

                alert('Baja registrada')
            } catch(e){
                console.error(e)

                alert('Error registrando baja')
            }
        },
        /**
         * Registrar rango de vacaciones para un usuario
         */
        async registrarVacaciones(){
            try {
                let respuesta = await registrarVacaciones(
                    this.usuario.id,
                    this.$moment(this.dates[0]).format('YYYY-MM-DD'),
                    this.$moment(this.dates[1]).format('YYYY-MM-DD')
                )

                this.procesarRespuesta(respuesta)

                this.modalConfirmarRegistro = false

                alert('Vacaciones registradas')
            } catch(e){
                console.error(e)

                alert('Error registrando vacaciones')
            }
        },
        /**
         * Obtener vacaciones y bajas del usuario
         */
        async obtenerVacacionesBajasUsuario(){
            this.cargando = true

            try {
                let respuesta = await obtenerVacacionesBajasUsuario(this.usuario.id)

                this.procesarRespuesta(respuesta)
            } catch(e){
                console.error(e)

                alert('Error obteniendo datos del usuario')
            }
        },
        /**
         * Procesa la respuesta
         */
        procesarRespuesta(respuesta){
            this.vacaciones = []
            this.bajas = []
            this.eventos = []
            this.fechasProcesadas.vacaciones = []
            this.fechasProcesadas.bajas = []

            if(typeof respuesta.data === 'object'){
                // vacaciones
                this.vacaciones = respuesta.data.vacaciones

                // bajas
                this.bajas = respuesta.data.bajas

                // eventos calendario
                for(let tipoRango in respuesta.data){
                    for(let indiceRango in respuesta.data[tipoRango]){
                        let tipoDato = tipoRango === 'bajas' ? 'is-danger' : 'is-success',
                        fechaDesde = new Date(
                            Date.parse(respuesta.data[tipoRango][indiceRango].fechaDesde)
                        ),
                        fechaHasta = new Date(
                            Date.parse(respuesta.data[tipoRango][indiceRango].fechaHasta)
                        ),
                        fechaContador = this.$moment(
                            fechaDesde
                        ),
                        fechaLimite = this.$moment(
                            fechaHasta
                        )

                        while(fechaContador.isSameOrBefore(fechaLimite)){
                            fechaContador = this.$moment(fechaContador)

                            let fechaStr = fechaContador.format('YYYY-MM-DD'),
                            evento = {
                                date: new Date(
                                    Date.parse(fechaStr)
                                ),
                                type: tipoDato
                            }

                            // agregamos evento
                            if(tipoRango === 'bajas'){
                                evento.label = respuesta.data[tipoRango][indiceRango].motivo
                            }

                            this.eventos.push(evento)

                            if(this.fechasProcesadas[tipoRango].indexOf(fechaStr) === -1){
                                this.fechasProcesadas[tipoRango].push(fechaStr)
                            }

                            // sumamos fecha al bucle
                            fechaContador = fechaContador.add(1, 'days')
                        }
                    }
                }
            }
        },
        /**
         * Establece al usuario
         */
        setUsuario(usuario){
            this.usuario = usuario

            this.obtenerVacacionesBajasUsuario()

            this.active = true
        }
    }
}
</script>