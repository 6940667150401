<template>
    <section class="section">
        <div class="container">
            <b-field custom-class="has-text-primary">
                <b-autocomplete
                open-on-focus
                v-model="filtroPreguntas"
                :data="preguntasFiltradas"
                field="pregunta"
                clearable
                placeholder="Filtrar preguntas"
                size="is-large"
                @select="(option) => irAPregunta(option)">
                    <template slot-scope="props" class="has-background-secondary">
                        <p>
                            <b-icon
                            type="is-primary"
                            icon="question-circle"></b-icon>

                            <span class="has-text-primary has-text-weight-bold">{{ props.option.pregunta }}</span>
                            <br>
                            <small>
                                <span v-html="props.option.respuesta"></span>
                            </small>
                        </p>
                    </template>
                </b-autocomplete>
            </b-field>

            <b-collapse
                class="card"
                animation="slide"
                v-for="(collapse, index) of preguntasCollapse"
                :key="index"
                :open="isOpen == index"
                @open="isOpen = index"
                :aria-id="'contentIdForA11y5-' + index">

                <template #trigger="props">
                    <div
                    class="card-header"
                    role="button"
                    :aria-controls="'contentIdForA11y5-' + index"
                    :aria-expanded="props.open">
                        <p
                        :id="'cardHeaderPregunta_' + index"
                        class="card-header-title has-background-primary has-text-secondary">
                            {{ collapse.pregunta }}
                        </p>
                        <a class="card-header-icon has-background-primary has-text-secondary">
                            <b-icon
                                :icon="props.open ? 'chevron-down' : 'chevron-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content has-background-secondary">
                    <h1 class="title has-text-weight-light has-text-primary">
                        {{collapse.pregunta}}
                    </h1>

                    <div class="content has-text-primary">
                        {{ collapse.respuesta }}
                    </div>
                </div>
            </b-collapse>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'PreguntasFrecuentes',
        data() {
            return {
                filtroPreguntas: '',
                isOpen: 0,
                preguntasCollapse: [{
                    pregunta: '¿Horarios de entrada y salida? Check in / check out',
                    respuesta: 'Nuestros horario de check in es a partir de las 15:00h y nuestro horario de check out antes de las 10:00h.'
                },{
                    pregunta: '¿Habrá alguien cuando llegue al apartamento?',
                    respuesta: 'No disponemos de recepción por lo que cuando llegue al apartamento no habrá nadie para recibirle. Sin embargo, en el enlace de check in tiene toda la información para poder acceder sin necesidad de estar dependiendo de un horario para la entrega de llaves.'
                },{
                    pregunta: '¿Cómo coordinamos la entrega de llaves?',
                    respuesta: 'Ahora mismo, tenemos un sistema de cajas de seguridad y códigos donde no es necesario que nos veamos en la entrega de llaves. Siguiendo los pasos que encontrará en el email que le llegue cuando haya hecho el check in online podrá acceder al edificio y al apartamento. Es muy sencillo. Necesitará un código para abrir la puerta principal del edificio y una vez dentro encontrará unas cajas de seguridad. Le habremos indicado que caja es la que tiene que abrir y dentro estará la llave. De todas formas, si tuviera cualquier tipo de problema con el acceso, siempre puede llamarnos al +34 922 099 046 o +34 922 099 047. En caso de no estar en horario de oficina, pero ser un problema de acceso o avería técnica, pulse el 1 y le atenderemos lo antes posible. Solo en esos casos también nos puede llamar al + 34 625 306 424 o enviarnos un whatsapp a ese número.'
                },{
                    pregunta: '¿Por qué hora de check in?',
                    respuesta: 'Siempre intentamos ser flexibles con el horario del check in, que es a las 15:00h. Sin embargo, hay veces que nos es imposible adelantar la entrada porque han sido todo salidas del mismo día y no tenemos el apartamento preparado. En caso de estar listo, no tendremos ningún problema en que acceda antes sin ningún coste adicional.'
                },{
                    pregunta: '¿Por qué hora de check out?',
                    respuesta: 'La hora del check out es a las 10:00am ya que necesitamos limpiar las habitaciones para nuestros siguientes huéspedes.  Al igual que con la hora del check in, intentamos ser flexibles en este aspecto pero hay veces que no podemos.'
                }/*,{
                    pregunta: 'Late check out: Precios /Disponibilidad. Cómo contratarlo?',
                    respuesta: 'Respuesta ...'
                },{
                    pregunta: '¿Comidas en el apartamento?',
                    respuesta: 'Respuesta ...'
                },{
                    pregunta: '¿Equipamiento? ¿Sábanas, toallas, utensilios para concinar, cafetera nespresso y fetera italiana, secador, tostadora)',
                    respuesta: 'Respuesta ...'
                },{
                    pregunta: 'Disponibilidad y reservas',
                    respuesta: 'Respuesta ...'
                },{
                    pregunta: 'A pesar de que nuestro calendario muestra que está disponible no me deja reservas en unas determinadas fechas? ¿Por qué?',
                    respuesta: 'Respuesta ...'
                },{
                    pregunta: 'Tarifas - reembolsable /no reembolsable / Productora / Equipos ',
                    respuesta: 'Respuesta ...'
                },{
                    pregunta: 'Devoluciones',
                    respuesta: 'Respuesta ...'
                },{
                    pregunta: 'Facturación',
                    respuesta: 'Respuesta ...'
                },{
                    pregunta: 'Forma de pago',
                    respuesta: 'Respuesta ...'
                },{
                    pregunta: 'Actividades no son contratadas con nosotros',
                    respuesta: 'Respuesta ...'
                },{
                    pregunta: 'Fianza',
                    respuesta: 'Respuesta ...'
                },{
                    pregunta: 'Pérdida de llaves',
                    respuesta: 'Respuesta ...'
                }*/]
            }
        },
        computed:{
            preguntasFiltradas(){
                let preguntasFiltradas = [],
                filtroNormalizado = this.filtroPreguntas.toLowerCase().trim()

                for(let p in this.preguntasCollapse){
                    if(this.preguntasCollapse[p].pregunta.toLowerCase().indexOf(filtroNormalizado) !== -1){
                        let clonPregunta = JSON.parse(JSON.stringify(this.preguntasCollapse[p]))

                        clonPregunta.originalIndex = p

                        preguntasFiltradas.push(clonPregunta)
                    }
                }

                return preguntasFiltradas
            }
        },
        methods:{
            irAPregunta(pregunta){
                if(pregunta !== null){
                    this.isOpen = pregunta.originalIndex
                    
                    document.getElementById('cardHeaderPregunta_' + pregunta.originalIndex).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }
            }
        }
    }
</script>