<template>
    <div>
        <ImagenesPortada
        :isFullHeight="false"
        :isMedium="true"
        :imageUrl="habitacion.foto"/>

        <section class="section">
            <div class="container">
                <div class="columns">
                    <div class="column is-4 has-background-primary has-text-secondary" style="margin-top:-15vh;border-radius:0.15rem;">
                        <h1 @click="scrollServicios()" id="ancla-titulo-habitacion" class="pt-2 pb-1 title has-text-weight-light has-text-centered has-text-secondary is-clickable">
                            {{habitacion.nombre}}<br>
                        </h1>
                        <h5 @click="irAEdificio" class="pt-2 pb-1 subtitle has-text-weight-light has-text-centered has-text-secondary is-clickable">
                            {{edificio.nombre}}<br>
                        </h5>

                        <DatosContacto />

                        <hr class="m-0 p-0 has-background-secondary" />

                        <p
                        class="has-text-justified mt-4">
                            {{habitacion.descripcion}}
                        </p>

                        <!-- Servicios -->
                        <div
                        id="ancla-servicios-habitacion"
                        v-for="(grupoServicio,indiceGrupoServicio) in gruposServicios"
                        :key="'grp'+indiceGrupoServicio"
                        class="mt-3 columns is-mobile has-text-secondary caja-servicios-canary-loft">
                            <div
                            v-for="(servicio,indiceServicio) in grupoServicio"
                            :key="'srv'+indiceServicio"
                            class="column has-text-centered is-size-7">
                                <vue-fontawesome-layers v-if="servicio.banned" class="mb-3">
                                    <b-icon icon="slash"></b-icon>
                                    <b-icon :icon="servicio.icon"></b-icon>
                                </vue-fontawesome-layers>

                                <b-icon v-else :icon="servicio.icon"></b-icon>

                                <br>

                                {{servicio.nombre}}
                            </div>
                        </div>
                    </div>

                    <div class="column pt-0">
                        <!--Separador móvil-->
                        <div class="is-hidden-tablet pt-3"></div>

                        <!--Fotos-->
                        <b-carousel
                        ref="carouselGaleria"
                        icon-pack="fas"
                        icon-prev="chevron-left"
                        icon-next="chevron-right"
                        :autoplay="false"
                        :indicator="false"
                        :indicator-inside="false"
                        :overlay="gallery"
                        @click="switchGallery(true)">
                            <b-carousel-item
                            v-for="i in habitacion.nImagenesCarousel"
                            :key="i">
                                <a class="image">
                                    <img :src="getImgUrl(i)">
                                </a>
                            </b-carousel-item>
                            <span v-if="gallery" @click="switchGallery(false)" class="modal-close is-large"/>
                        </b-carousel>

                        <div class="container mt-3">
                            <div class="columns">
                                <div class="column">
                                    <h2 class="is-size-4 has-text-primary has-text-weight-light">
                                        Qué debes saber
                                    </h2>
                                    <b class="has-text-primary">Normas de la casa</b>
                                    <ul class="caja-normas-casa">
                                        <li class="has-text-primary">
                                            <b-icon icon="clock" class="mr-1" size="is-small"></b-icon>
                                            <span>Llegada: a partir de las 15:00</span>
                                        </li>
                                        <li class="has-text-primary">
                                            <b-icon icon="clock" class="mr-1" size="is-small"></b-icon>
                                            <span>Salida: 10:00</span>
                                        </li>
                                        <li class="has-text-primary">
                                            <b-icon icon="smoking-ban" class="mr-1" size="is-small"></b-icon>
                                            <span>Prohibido fumar</span>
                                        </li>
                                        <li class="has-text-primary">
                                            <vue-fontawesome-layers class="mr-1">
                                                <b-icon icon="slash"></b-icon>
                                                <b-icon icon="dog"></b-icon>
                                            </vue-fontawesome-layers>
                                            <span>No se admiten mascotas</span>
                                        </li>
                                        <li class="has-text-primary">
                                            <!--<b-icon icon="" class="has-text-primary mr-1" size="is-small"></b-icon>-->
                                            <vue-fontawesome-layers class="mr-1">
                                                <b-icon icon="slash"></b-icon>
                                                <b-icon icon="champagne-glasses"></b-icon>
                                            </vue-fontawesome-layers>
                                            <span>No se admiten fiestas</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="column"></div>
                            </div>
                            
                            <h2 class="my-3 is-size-4 has-text-primary has-text-weight-light has-text-centered">
                                Disponibilidad
                            </h2>

                            <Buscador
                            ref="buscadorDispo"
                            :indiceEdificioPredeterminado="edificio.indice"
                            :showSelectorEdificios="false"
                            :isMedium="false" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

/**
 * Dispositivos
 */
import {getDeviceSizeType} from '../services/devices.js'

/**
 * Buscador
 */
import Buscador from '../components/Buscador.vue'

/**
 * Datos contacto
 */
import DatosContacto from '../components/DatosContacto.vue'

/**
 * Imágenes portada
 */
import ImagenesPortada from '../components/ImagenesPortada.vue'

export default {
    name: 'Habitacion',
    data() {
        return {
            gallery: false,

            // edificio
            edificio: {
                nombre: '',
                descripcion: '',
                id: ''
            },

            // carpetas fotos carousel
            carpetasFotosCarousel:{
                // palace
                1: './images/canary-loft-palace',
                // salamanca
                2: './images/canary-loft-salamanca',
                // glorieta
                3: './images/canary-loft-glorieta'
            },

            // habitacion
            habitacion: {
                nombre: '',
                descripcion: '',
                id: '',
                foto: '',
                nImagenesCarousel: 5
            },
            indiceHabitacion: 0
        }
    },
    components:{
        Buscador,
        DatosContacto,
        ImagenesPortada
    },
    props:{
        nImagenesCarousel:{
            type: Number,
            default: 0
        },
        carpetaImagenesCarousel:{
            type: String,
            default: ''
        },
        imagenPortada:{
            type: String,
            default: ''
        },
        googleMapsSrc:{
            type: String,
            default: ''
        }
    },
    computed:{
        isMobile(){
            return this.tipoDispositivo === 'is-mobile'
        },
        tipoDispositivo(){
            return this.getDeviceSizeType()
        },
        gruposServicios(){
            let gruposServicios = [],
            indiceGrupoActual = 0,
            serviciosPorFila = 4,
            contador = 0

            for(let s in this.edificio.servicios){
                if(typeof gruposServicios[indiceGrupoActual] === 'undefined'){
                    gruposServicios[indiceGrupoActual] = []
                }

                gruposServicios[indiceGrupoActual].push(this.edificio.servicios[s])

                contador++
                
                if(contador % serviciosPorFila === 0){
                    indiceGrupoActual++
                }
            }

            return gruposServicios
        }
    },
    beforeMount(){
        if(typeof this.$route.params.edificio === 'object' && typeof this.$route.params.habitacion === 'object' ){
            this.edificio = this.$route.params.edificio

            this.habitacion = this.$route.params.habitacion

            this.indiceHabitacion = this.$route.params.indiceHabitacion
        } else {
            this.$router.replace({
                name: 'Edificios'
            })
        }
    },
    mounted(){
        document.getElementById('ancla-titulo-habitacion').scrollIntoView({
            behavior:'smooth',
            block:'end'
        })

        this.$refs.buscadorDispo.setHabitacion(this.habitacion)
    },
    methods: {
        irAEdificio(){
            let vistaEdificio = ''

            switch(this.edificio.indice){
                case 1:
                    vistaEdificio = 'CanaryLoftPalace'
                    break;
                case 2:
                    vistaEdificio = 'CanaryLoftSalamanca'
                    break;
                case 3:
                    vistaEdificio = 'CanaryLoftGlorieta'
                    break;
            }

            if(vistaEdificio){
                this.$router.push({name: vistaEdificio})
            }
        },
        getDeviceSizeType:getDeviceSizeType,
        scrollServicios(){
            document.getElementById('ancla-servicios-habitacion').scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            })
        },
        getImgUrl(value) {
            return this.carpetasFotosCarousel[this.edificio.indice] + '/room_' + this.indiceHabitacion + '/' + value + '.jpg'
        },
        switchGallery(value) {
            this.gallery = value
            if (value) {
                return document.documentElement.classList.add('is-clipped')
            } else {
                return document.documentElement.classList.remove('is-clipped')
            }
        }
    }
}
</script>

<style>
p{
    text-indent: 1rem;
}

.caja-habitacion.desktop figure.image{
    height: 30vh;
    overflow: hidden;
}

.caja-habitacion img{
    border-radius: 0.15rem;
}

.caja-habitacion.desktop img{
    margin-top: -125px !important;
}

#ancla-servicios-habitacion svg{
    height: 2rem !important;
}

</style>