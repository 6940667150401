<template>
    <b-navbar
    class="banner-politicas"
    style="z-index:400"
    fixed-bottom
    type="is-primary">
        <template v-if="isMobile" #brand>
            <b-navbar-item tag="a">
                <b-icon icon="exclamation-circle" class="mr-1"></b-icon><span>Política de cookies</span>
            </b-navbar-item>
        </template>

        <template #start>
            <b-navbar-item tag="div">
                <p class="has-text-centered">
                    Esta web almacena datos usando cookies para garantizar el funcionamiento básico del sitio, así como personalización, análisis y marketing.
                    <br>
                    Puede cerrar este banner para continuar con las cookies esenciales o leer nuestra <u><router-link :to="{path:'/cookies'}" :class="{'has-text-secondary': !isMobile}">Política de cookies</router-link></u>
                </p>
            </b-navbar-item>
        </template>

        <template #end>
            <b-navbar-item tag="div">
                <b-button
                @click="aceptarPoliticas()"
                type="is-primary" :outlined="!isMobile" :inverted="!isMobile" :expanded="isMobile">
                    Aceptar
                </b-button>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>

/**
 * Funciones cookies políticas
 */
import {usuarioAceptaPoliticas, aceptarPoliticas} from '../services/cookiesCanaryLoft.js'

/**
 * Dispositivos
 */
import {getDeviceSizeType} from '../services/devices.js'

export default {
    name: 'BannerPoliticas',
    computed:{
        isMobile(){
            return this.tipoDispositivo === 'is-mobile'
        },
        tipoDispositivo(){
            return this.getDeviceSizeType()
        }
    },
    beforeMount(){
        if(this.usuarioAceptaPoliticas()){
            this.$emit('politicasAceptadas')
        }
    },
    methods:{
        getDeviceSizeType:getDeviceSizeType,
        usuarioAceptaPoliticas:usuarioAceptaPoliticas,
        aceptarPoliticas(){
            aceptarPoliticas()

            this.$emit('politicasAceptadas')
        }
    }
}
</script>

<style scoped>
#banner-politicas{
    height: 10vh;
    z-index: 400;
}

#banner-politicas .columns{
    border-radius:0.15em;
}
</style>