<template>
    <b-navbar
    style="z-index:400"
    fixed-top
    type="is-secondary">
        <template #brand>
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <span class="has-text-weight-light">Canary</span>
                <span class="has-text-weight-bold">Lofts</span>
            </b-navbar-item>
        </template>

        <template #start>
            <b-navbar-item :active="$route.path.indexOf('nuestros-edificios') !== -1" href="#" tag="router-link" :to="{ path: '/nuestros-edificios' }" class="has-text-primary">
                {{traducir('Nuestros apartamentos')}}
            </b-navbar-item>
            <b-navbar-item :active="$route.path.indexOf('checkin') !== -1" href="#" tag="router-link" :to="{ path: '/checkin' }" class="has-background-primary has-text-secondary has-text-weight-bold">
                Check In Online
            </b-navbar-item>
            <b-navbar-item :active="$route.path.indexOf('experiencias') !== -1" href="#" tag="router-link" :to="{ path: '/experiencias' }" class="has-text-primary">
                {{traducir('Actividades')}}
            </b-navbar-item>
        </template>

        <template #end>
            <b-navbar-dropdown :label="idiomas[idioma] + ' (' + idioma + ')'">
                <b-navbar-item
                v-for="(labelIdioma,indiceIdioma) in idiomas"
                :key="indiceIdioma"
                @click="cambiarIdioma(indiceIdioma, true)">
                    <span class="has-text-primary" :class="{'has-text-weight-bold': idioma === indiceIdioma}">
                        {{labelIdioma}} ({{indiceIdioma}})
                    </span>
                </b-navbar-item>
            </b-navbar-dropdown>
            <b-navbar-item :active="$route.path.indexOf('sobre-nosotros') !== -1" href="#" tag="router-link" :to="{ path: '/sobre-nosotros' }" class="has-text-primary">
                {{traducir('Sobre nosotros')}}
            </b-navbar-item>
            <b-navbar-item  @click="$emit('abrirContacto')" class="has-text-primary">
                {{traducir('Contáctanos')}}
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>

/**
 * Traducciones
 */
import {traducir} from '../services/traducciones.js'

/**
 * Cookies
 */
import {setCookie,getCookie} from '../services/cookies.js'

export default {
    name: 'TopNavbar',
    data(){
        return {
            cookieIdioma: 'canaryloftlang'
        }
    },
    computed:{
        idioma(){
            return this.$store.state.idioma
        },
        idiomas(){
            return this.$store.state.idiomasDisponibles
        }
    },
    beforeMount(){
        let idiomaCookie = getCookie(this.cookieIdioma)

        if(typeof idiomaCookie === 'string' && idiomaCookie.length > 0){
            this.cambiarIdioma(idiomaCookie)
        }
    },
    methods:{
        traducir(texto){
            return traducir(texto, this.idioma)
        },
        cambiarIdioma(idioma, escribirCookie){
            this.$store.dispatch('setIdioma', idioma)

            if(escribirCookie){
                setCookie(this.cookieIdioma, idioma)
            }
        }
    }
}
</script>