<template>
    <section class="section p-0">
        <div class="container is-fluid is-paddingless">
            <MotorReservas ref="motorReservas" @mounted="irAlMotor()" />
        </div>
    </section>
</template>

<script>

/**
 * Motor reservas avail room
 */
import MotorReservas from '../components/MotorReservasAvailRoom.vue'

export default {
    name: 'Busqueda',
    components:{
        MotorReservas
    },
    methods:{
        irAlMotor(){
            this.$refs.motorReservas.$el.scrollIntoView({
                behavior: 'smooth',
                block: 'end'
            })
        }
    }
}
</script>