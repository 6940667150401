<template>
    <section>
      <!-- Escritorio -->
      <div class="hero is-hidden-mobile" :class="{'is-fullheight': isFullHeight,'is-mitad': isMedium}">
        <div
        :style="getBgStyles()"
        class="hero-body has-text-centered"></div>
      </div>

      <!-- Móvil -->
      <b-image :src="imageUrl" responsive class="is-hidden-tablet"></b-image>
      <!--<div class="hero is-hidden-tablet" style="height:80vh">
        <div
        :style="getBgStyles()"
        class="hero-body has-text-centered"></div>
      </div>-->
    </section>
</template>

<script>
export default {
    name: 'ImagenesPortada',
    data(){
        return {
          gallery: false
        }
    },
    props:{
      imageUrl:{
        type:String,
        default: './images/portada.jpg'
      },
      isFullHeight:{
        type:Boolean,
        default: false
      },
      isMedium:{
        type:Boolean,
        default: false
      }
    },
    methods:{
        getBgStyles(){
          return {
            backgroundImage: 'url(' + this.imageUrl + ')',
            backgroundPosition: 'bottom',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover'
          }
        },
        switchGallery(value) {
          this.gallery = value

          if (value) {
            return document.documentElement.classList.add('is-clipped')
          }

          return document.documentElement.classList.remove('is-clipped')
        }
    }
}
</script>

<style scoped>
.hero.is-mitad{
  height: 40vh !important;
}
</style>