<template>
  <div id="app" class="has-background-secondary">

    <TopNavbar v-if="canaryLoftsEs" @abrirContacto="abrirContacto" />

    <router-view />

    <Suscripcion v-if="canaryLoftsEs" />

    <Footer @abrirContacto="abrirContacto" v-if="canaryLoftsEs" />

    <b-modal :active.sync="modalContacto">
      <Contacto @cerrar="modalContacto = false" />
    </b-modal>
    
    <BannerPoliticas v-if="mostrarBannerPoliticas && canaryLoftsEs" @politicasAceptadas="mostrarBannerPoliticas = false" />

  </div>
</template>

<script>

/**
 * Navegador
 */
import TopNavbar from './components/TopNavbar'

/**
 * Suscripción
 */
import Suscripcion from './components/Suscripcion'

/**
 * Footer
 */
import Footer from './components/Footer'

/**
 * Formulario contacto
 */
import Contacto from './components/Contacto'

/**
 * Banner políticas
 */
import BannerPoliticas from './components/BannerPoliticas.vue'

export default {
  name: 'App',
  data(){
    return {
      modalContacto: false,
      mostrarBannerPoliticas: true
    }
  },
  components:{
    TopNavbar,
    Footer,
    Suscripcion,
    Contacto,
    BannerPoliticas
  },
  computed:{
    canaryLoftsEs(){
      return this.$route.path.indexOf('portal-bunabil') === -1
    }
  },
  watch:{
    $route(){
      this.irAlTop()
    }
  },
  methods:{
    abrirContacto(){
      this.modalContacto = true
    },
    irAlTop(){
      scroll(0, 0)
    }
  }
}
</script>

<style lang="scss">

/** Dropdown */
.dropdown-menu{
  z-index: 500 !important;
}

/** Iconos pequeños */
#portal-bunabil svg, .dropdown-item svg, .carousel-arrow svg, .controlar, .nuestros-edificios svg, .banner-politicas svg, .caja-servicios-canary-loft svg, .caja-normas-casa svg, .fa-layers svg, .columna-comentarios svg{
  max-height: 1rem !important;
}

/** Iconos grandes */
div.control svg.svg-inline--fa{
  max-height: 2rem !important;
}

/** Iconos redes sociales */
.fa-instagram, .fa-facebook{
  height: 1rem;
}

/** Textos dentro de títulos */
.title span{
  font-size: inherit !important;
}

/** Tamaño textos navbar */
a.navbar-item, a.navbar-item span, button span{
  font-size: 1rem !important;
}

/** Modal encima del navbar para poder cerrarlo */
.modal.is-active, .carousel.is-overlay{
  z-index:500 !important;
}

/** Arreglo modal publicidad */
.modal-publicidad .animation-content{
  overflow: hidden !important;
}

/** Arreglo iconos superpuestos */
#ancla-servicios-edificio .fa-layers{
  width: 100%;
}

.fa-layers .icon{
  display: inline;
}

</style>
