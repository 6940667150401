/**
 * Bunabil
 */
import {call} from './bunabil.js'

/**
 * Actualizar los items del almacén de un edificio
 */
export const actualizarItemsLavanderiaEdificio = async (indiceEdificio, items) => {
    try {
        return await call('actualizarItemsLavanderiaEdificio', {
            indiceEdificio: indiceEdificio,
            items: items
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Actualizar los items del almacén de un edificio
 */
export const obtenerLavanderias = async () => {
    try {
        return await call('obtenerLavanderias')
    } catch(e){
        throw new Error(e)
    }
}
