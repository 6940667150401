<template>
    <b-navbar
    fixed-top
    type="is-primary">
        <template #start v-if="esAdministrador">
            <b-navbar-item
            :active="$route.path.indexOf('jornada') !== -1"
            tag="router-link" to="jornada">
                <b-icon icon="list" class="mr-1"></b-icon> Jornada
            </b-navbar-item>
            <!--<b-navbar-item
            :active="$route.path.indexOf('inventario') !== -1"
            tag="router-link" to="inventario">
                <b-icon icon="box-open" class="mr-1"></b-icon> Inventario
            </b-navbar-item>-->

            <b-navbar-item
            :active="$route.path.indexOf('editor') !== -1"
            tag="router-link" to="editor">
                <b-icon icon="pencil" class="mr-1"></b-icon> Plantillas correos
            </b-navbar-item>

            <b-navbar-dropdown label="Contenido">
                <b-navbar-item
                :active="$route.path.indexOf('usuarios') !== -1"
                tag="router-link" to="usuarios">
                    <b-icon icon="users" class="mr-1"></b-icon> Usuarios
                </b-navbar-item>

                <b-navbar-item
                :active="$route.path.indexOf('items') !== -1"
                tag="router-link" to="items">
                    <b-icon icon="toolbox" class="mr-1"></b-icon> Ítems
                </b-navbar-item>
                
                <b-navbar-item
                :active="$route.path.indexOf('lofts') !== -1"
                tag="router-link" to="lofts">
                    <b-icon icon="door-closed" class="mr-1"></b-icon> Lofts
                </b-navbar-item>
            </b-navbar-dropdown>
        </template>

        <template #end>
            <b-navbar-item @click="$emit('logout')" icon="power-off">
                Salir
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>
export default {
    data(){
        return {
            sidebar: false
        }
    },
    computed:{
        esAdministrador(){
            return this.$store.state.esAdministrador
        }
    }
}
</script>