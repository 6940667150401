<template>
    <section v-show="show" class="section px-0">
        <div class="container is-fluid">
            <b-button
            v-if="urlFinal"
            id="ancla"
            @click="urlFinal = ''" expanded type="is-primary" size="is-large">
                {{textoBoton}}
            </b-button>

            <b-field
            @keyup.native.enter="buscar()"
            v-else
            :label="traducir('Código de huésped')"
            custom-class="has-text-primary has-text-centered"
            horizontal>
                <b-input
                v-model="guestCode" :placeholder="traducir('Introduce tu código de huésped')"
                expanded></b-input>

                <b-button @click="buscar()" :disabled="!codigoValido" type="is-primary" expanded>{{traducir('Buscar')}}</b-button>
            </b-field>
        </div>

        <transition name="slide">
            <div v-if="urlFinal" class="container is-fluid mt-3 px-0">
                <iframe id="iframeCheckinAR" ref="iframeCheckin" :src="urlFinal" @load="iframeLoaded" frameborder="0"></iframe>
            </div>
        </transition>

        <ModalPublicidad :active.sync="cargando" />
    </section>
</template>

<script>

/**
 * Modal publicidad
 */
import ModalPublicidad from '../components/ModalPublicidad.vue'

/**
 * Traducciones
 */
import {traducir} from '../services/traducciones.js'

export default {
    name: 'CheckIn',
    components:{
        ModalPublicidad
    },
    data(){
        return {
            guestCode: '',
            baseUrl: 'https://checkin.availroom.com/?',
            urlFinal: '',
            cargando: false,
            solicitado: false
        }
    },
    props:{
        show:{
            type: Boolean,
            default: true
        }
    },
    computed:{
        textoBoton(){
            let texto = 'Cambiar código de huésped'

            if(this.cargando){
                texto = 'Cargando datos Check In...'
            }

            return this.traducir(texto)
        },
        baseUrlConIdioma(){
            return this.baseUrl + 'langid=' + this.idioma
        },
        codigoValido(){
            return this.guestCode.length > 6
        },
        idioma(){
            return this.$store.state.idioma
        }
    },
    methods:{
        traducir(texto){
            return traducir(texto, this.idioma)
        },
        setGuestCode(guestCode){
            this.guestCode = guestCode
        },
        buscar(){
            this.cargando = true

            this.urlFinal = this.baseUrlConIdioma + '&guestcode=' + this.guestCode
        },
        iframeLoaded(){
            this.$emit('iframeLoaded')

            this.solicitado = true

            this.cargando = false

            if(this.show){
                document.getElementById('ancla').scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                })
            }
        }
    }
}
</script>