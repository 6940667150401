/**
 * Escribe una cookie con la duración establecida en días
 * @param {String} name 
 * @param {String} value 
 * @param {Number} days 
 */
export const setCookie = (name, value, days) => {
    let expires = ""

    if (days) {
        let date = new Date()

        date.setTime(date.getTime() + (days*24*60*60*1000))

        expires = "; expires=" + date.toUTCString()
    }

    document.cookie = name + "=" + (value || "")  + expires + "; path=/"
}

/**
 * Devuelve el valor de una cookie
 * @param {String} name 
 * @returns {String}
 */
export const getCookie = (name) => {
    let nameEQ = name + "=",
    ca = document.cookie.split(';')

    for(var i=0;i < ca.length;i++) {
        let c = ca[i]

        while (c.charAt(0)==' '){
            c = c.substring(1,c.length)
        }

        if (c.indexOf(nameEQ) == 0){
            return c.substring(nameEQ.length, c.length)
        }
    }

    return null;
}

/**
 * Elimina una cookie
 * @param {String} name 
 */
export const eraseCookie = (name) => {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
