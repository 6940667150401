<template>
    <b-autocomplete
    icon="location-dot"
    open-on-focus
    :placeholder="traducir('¿A dónde quieres ir?')"
    :size="size"
    v-model="filtroEdf"
    :data="edificiosFiltrados"
    :append-to-body="true"
    keep-first
    field="label"
    clearable
    @select="option => seleccionar(option)">
        <template slot-scope="props" class="has-background-secondary">
            <p class="is-size-7">
                <span class="has-text-primary has-text-weight-bold">{{ props.option.label }}</span>
                <br>
                <b-icon type="is-primary" icon="location-dot" size="is-small" class="mr-1"></b-icon>
                <span v-html="props.option.addr"></span>
            </p>
        </template>
    </b-autocomplete>
</template>

<script>

/**
 * Traducciones
 */
import {traducir} from '../services/traducciones.js'

export default {
    name: 'AutocompleteEficios',
    props:{
        size: {
            type: String,
            default: 'is-large'
        }
    },
    data(){
        const edificios = [/*{
            label: 'Canary Lofts',
            indice: 0,
            addr: 'Santa Cruz de Tenerife, España'
        },*/{
            label: 'Canary Lofts Palace',
            indice: 1,
            addr: 'Calle Doctor Jose Naveiras 13<br>38001, Santa Cruz de Tenerife, España'
        },{
            label: 'Canary Lofts Salamanca',
            indice: 2,
            addr: 'C/ Manuel Verdugo, 4<br>38006, Santa Cruz de Tenerife, España'
        },{
            label: 'Canary Lofts Glorieta',
            indice: 3,
            addr: 'Plaza 29 de Mayo, 8<br>38005, Santa Cruz de Tenerife, España'
        }]

        return {
            edificioSeleccionado: null,

            edificios:edificios,

            filtroEdf: ''
        }
    },
    computed:{
        idioma(){
            return this.$store.state.idioma
        },
        edificiosFiltrados(){
            let filtroMinus = this.filtroEdf,
            resultados = []

            for(let e in this.edificios){
                let labelMinus = this.edificios[e].label.toLowerCase(),
                addrMinus = this.edificios[e].addr.toLowerCase()

                if(labelMinus.indexOf(filtroMinus) !== -1 || addrMinus.indexOf(filtroMinus) !== -1){
                    resultados.push(this.edificios[e])
                }
            }

            return resultados
        }
    },
    methods:{
        traducir(texto){
            return traducir(texto, this.idioma)
        },
        seleccionar(option){
            this.edificioSeleccionado = option

            this.$emit('select', option)
        }
    }
}
</script>