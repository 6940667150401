<template>
    <div class="card form-contacto">
        <!-- Móvil -->
        <div class="card-image is-hidden-tablet m-0">
            <b-image
            src="./images/contacto.jpg"
            responsive></b-image>
        </div>

        <!-- Resto -->
        <div class="card-image is-hidden-mobile m-0">
            <b-image
            src="./images/contacto.jpg"
            responsive></b-image>
        </div>

        <div class="card-content has-text-justified">
            <DatosContacto :hasTextWhite="false" />

            <b-field class="is-hidden-mobile">
                <p class="control">
                    <b-button type="is-primary">
                        Nombre
                    </b-button>
                </p>

                <b-input v-model="nombre" type="text" placeholder="Nombre" expanded></b-input>

                <p class="control">
                    <b-button type="is-primary">
                        Correo
                    </b-button>
                </p>

                <b-input v-model="email" type="text" placeholder="Correo electrónico" expanded></b-input>
            </b-field>

            <b-field label="Nombre" class="is-hidden-tablet" custom-class="has-text-primary">
                <b-input v-model="nombre" type="text" placeholder="Nombre" expanded></b-input>
            </b-field>

            <b-field label="Correo" class="is-hidden-tablet" custom-class="has-text-primary">
                <b-input v-model="email" type="text" placeholder="Correo electrónico" expanded></b-input>
            </b-field>

            <b-field label="Mensaje" custom-class="has-text-primary">
                <b-input v-model="mensaje" type="textarea" placeholder="Mensaje" minlength="10" maxlength="300"></b-input>
            </b-field>

            <b-button
            :loading="enviandoCorreo"
            @click="enviar()"
            icon-left="paper-plane" type="is-primary" size="is-large" expanded>
                {{enviandoCorreo ? 'Contactando...' : 'Contactar'}}
            </b-button>
        </div>
    </div>
</template>

<script>

/**
 * Datos de contacto
 */
import DatosContacto from '../components/DatosContacto.vue'

/**
 * Enviar correo contacto
 */
import {enviarCorreo} from '../services/contacto.js'

export default {
    name: 'Contacto',
    data(){
        return {
            nombre: '',
            email: '',
            mensaje: '',
            enviandoCorreo: false
        }
    },
    components:{
        DatosContacto
    },
    methods:{
        async enviar(){
            this.enviandoCorreo = true

            try {
                let respuesta = await enviarCorreo(this.nombre, this.email, this.mensaje)

                if(typeof respuesta.error === 'string'){
                    alert(respuesta.error)
                } else {
                    alert('Hemos recibido tu correo, estaremos contigo lo antes posible')

                    this.mensaje = ''

                    this.$emit('cerrar')
                }

                this.enviandoCorreo = false
            } catch(e){
                this.enviandoCorreo = false

                console.error(e.message)
            }
        }
    }
}
</script>

<style>

.form-contacto .card-image{
    max-height: 35vh;
    overflow: hidden;
}

.form-contacto .card-image.is-hidden-mobile img{
    margin-top: -250px !important;
}

/*.imagen-contacto{
    height: 40vh;
    background-image: url(https://t-cf.bstatic.com/xdata/images/hotel/max1024x768/218911750.jpg?k=330d9c61d3b2ec6c6a13d6359c4d8bdc512dadd9d999ee9c03f7e8f735c77bb8&o=&hp=1);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom;
    background-size: cover;
}*/
</style>