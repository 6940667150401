/**
 * Bunabil
 */
import {call} from './bunabil.js'

/**
 * Obtener el inventario completo: almacenes y lavanderías
 */
export const obtenerInventarioCompleto = async () => {
    try {
        return await call('obtenerInventarioCompleto')
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Crear un nuevo ítem en el inventario
 * @param {String} nombreNuevoItem
 * @param {String} imagenNuevoItem
 */
export const crearItemInventario = async (nombreNuevoItem, imagenNuevoItem) => {
    try {
        return await call('crearItemInventario', {
            nombre: nombreNuevoItem,
            imagen: imagenNuevoItem
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Borra un ítem en el inventario
 * @param {String} idItemBorrar
 */
export const borrarItemInventario = async (idItemBorrar) => {
    try {
        return await call('borrarItemInventario', {
            idItemBorrar: idItemBorrar
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Edita un ítem del inventario
 * @param {String} idItemEditar
 * @param {String} propiedad
 * @param {String} nuevoValor
 */
export const editarItemInventario = async (idItemEditar, propiedad, nuevoValor) => {
    try {
        return await call('editarItemInventario', {
            idItemEditar: idItemEditar,
            propiedad: propiedad,
            nuevoValor: nuevoValor
        })
    } catch(e){
        throw new Error(e)
    }
}
