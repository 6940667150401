<template>
  <div>

    <ImagenesPortada isMedium imageUrl="./images/portada.jpg" />

    <section class="section has-background-primary">
      <div class="container">
        <section class="hero portada-web is-small is-secondary">
          <div class="hero-body has-text-centered">
            <p id="titulo" class="title has-text-primary">
              <span class="has-text-weight-light">Canary</span>
              <span class="has-text-weight-bold">Lofts</span>
            </p>

            <Buscador ref="formBuscador"/>

          </div>
        </section>
      </div>
    </section>

    <BannerBienvenida />

    <NuestrosEdificios style="margin-top:-7rem;" />
    
  </div>
</template>

<script>

/**
 * Imagenes portada
 */
import ImagenesPortada from '../components/ImagenesPortada.vue'

/**
 * Buscador de disponibilidad
 */
import Buscador from '@/components/Buscador.vue'

/**
 * Banner bienvenida
 */
import BannerBienvenida from '@/components/BannerBienvenida.vue'

/**
 * Nuestros edificios
 */
import NuestrosEdificios from '@/views/NuestrosEdificios.vue'

export default {
  name: 'Home',
  components: {
    Buscador,
    BannerBienvenida,
    ImagenesPortada,
    NuestrosEdificios
  },
  mounted(){
    setTimeout(() => {
      this.ajustarTop()
    }, 200)
  },
  methods: {
      ajustarTop(){
        let elementoAncla = document.getElementById('titulo')

        elementoAncla.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        })
      }
    }
}
</script>

<style>

#welcome{
  position: absolute;
  bottom: 1em;
}

.hero.portada-web{
  position:relative;
  bottom:7rem;
  border-radius:0.25rem;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}

.card:hover{
  cursor: pointer;
}

.datepicker-cell.is-selected{
  color: #ffffff !important;
}

</style>