/**
 * Bunabil
 */
import {call} from './bunabil.js'

/**
 * Obtener jornadas de limpieza
 */
export const obtenerJornadasHoy = async () => {
    try {
        return await call('obtenerJornadasHoy')
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Crear jornada de limpieza para otro usuario
 */
export const crearJornadaUsuario = async (indiceEdificio, idUsuario, fecha, horaDesde, horaHasta) => {
    try {
        return await call('crearJornadaUsuario', {
            indiceEdificio: indiceEdificio,
            idUsuario: idUsuario,
            fecha: fecha,
            horaDesde: horaDesde,
            horaHasta: horaHasta
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Editar jornada de limpieza
 */
export const editarJornadaUsuario = async (idJornada, horaDesde, horaHasta) => {
    try {
        return await call('editarJornadaUsuario', {
            idJornada: idJornada,
            horaDesde: horaDesde,
            horaHasta: horaHasta
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Crear jornada de limpieza de un loft para otro usuario
 */
 export const crearJornadaLoftUsuario = async (idJornada, horaDesde, horaHasta) => {
    try {
        return await call('crearJornadaLoftUsuario', {
            idJornada: idJornada,
            horaDesde: horaDesde,
            horaHasta: horaHasta
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Iniciar jornada de limpieza
 */
export const iniciarJornada = async (indiceEdificio) => {
    try {
        return await call('iniciarJornada', {
            indiceEdificio: indiceEdificio
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Finalizar jornada de limpieza
 */
export const finalizarJornada = async () => {
    try {
        return await call('finalizarJornada')
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Iniciar jornada de limpieza de un loft
 */
export const iniciarJornadaLoft = async (indiceEdificio, idLoft) => {
    try {
        return await call('iniciarJornadaLoft', {
            indiceEdificio: indiceEdificio,
            idLoft: idLoft
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Terminar jornada de limpieza de un loft
 */
export const terminarJornadaLoft = async () => {
    try {
        return await call('terminarJornadaLoft')
    } catch(e){
        throw new Error(e)
    }
}
