<template>
    <Paquetes />
</template>

<script>

/**
 * Paquetes de huéspedes
 */
import Paquetes from '../components/Paquetes.vue'

export default {
    name: 'VistaPaquetes',
    components:{
        Paquetes
    }
}
</script>