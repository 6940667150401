<template>
    <b-modal :active="active" class="modal-publicidad">
        <div class="card" style="background-color:transparent;">
            <div class="card-content">
                <div class="content">
                    <h1 class="title has-text-weight-light has-text-secondary has-text-centered">
                        Canary<span class="has-text-weight-bold">Lofts</span>
                    </h1>
                </div>
            </div>
            <div class="card-image">
                <figure class="image">
                    <BannerPublicidad />
                </figure>
            </div>
        </div>
    </b-modal>
</template>

<script>

/**
 * Imagen publicidad
 */
import BannerPublicidad from './BannerPublicidad.vue'

export default {
    name: 'ModalPublicidad',
    components:{
        BannerPublicidad
    },
    props:{
        active:{
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
.card-content{
    overflow: hidden;
}
</style>