/**
 * Bunabil
 */
import {call} from './bunabil.js'

/**
 * Obtener usuarios
 */
export const obtenerUsuarios = async () => {
    try {
        return await call('obtenerUsuarios')
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Crear usuario
 * @param {String} nombreUsuario
 * @param {Number} rol
 * @param {String} codigoAcceso
 */
export const crearUsuario = async (nombreUsuario, rol, codigoAcceso) => {
    try {
        return await call('crearUsuario', {
            nombreUsuario:nombreUsuario,
            rol:rol,
            codigoAcceso:codigoAcceso
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Eliminar usuario
 * @param {String} idUsuarioEliminar
 */
export const eliminarUsuario = async (idUsuarioEliminar) => {
    try {
        return await call('eliminarUsuario', {
            idUsuarioEliminar: idUsuarioEliminar
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Editar usuario
 * @param {String} idUsuarioEditar
 * @param {Object} nuevoUsuario
 */
export const editarUsuario = async (idUsuarioEditar, nuevoUsuario) => {
    if(typeof nuevoUsuario.id !== 'undefined'){
        delete nuevoUsuario.id
    }

    try {
        return await call('editarUsuario', {
            idUsuarioEditar: idUsuarioEditar,
            nuevoUsuario: nuevoUsuario
        })
    } catch(e){
        throw new Error(e)
    }
}
