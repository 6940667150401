<template>
    <section class="section m-0 pt-3 px-0">
        <div class="iframe-experiencias container is-fluid px-0">
            <iframe
            v-show="!cargando"
            :src="'https://s.ticketinhotel.es/l/tqkbj?locale=' + idioma"
            width="100%"
            height="1000"
            @load="cargando = false"></iframe>
        </div>

        <ModalPublicidad :active.sync="cargando" />
    </section>
</template>

<script>

/**
 * Modal publicidad
 */
import ModalPublicidad from '../components/ModalPublicidad.vue'

export default {
    name: 'Experiencias',
    components:{
        ModalPublicidad
    },
    data(){
        return {
            cargando: false
        }
    },
    computed:{
        idioma(){
            return this.$store.state.idioma
        }
    },
    mounted(){
        this.cargando = true
    }
}
</script>

<style>
.bg-gradient-primary-h{
    background: #72664F !important;
}
</style>