<template>
    <div class="container vista-inventario">
        <h1 class="title">Inventario</h1>
        <b-tabs
        v-if="edificios"
        expanded
        type="is-info">
            <b-tab-item
            v-for="(edificio,indiceEdificio) in edificios"
            :key="indiceEdificio"
            :label="edificio.nombre"
            icon="building">
                <b-tabs
                v-model="tabContenidoActivo"
                expanded
                type="is-info">
                    <b-tab-item label="Lofts" icon="door-closed">
                        <h2 class="subtitle"><b>Lofts</b> {{edificio.nombre}}</h2>

                        <b-field type="is-light">
                            <b-select
                            v-model="loftActivo"
                            expanded>
                                <option value="">Seleccionar loft ...</option>
                                <option
                                v-for="(loft,idLoft) in lofts[indiceEdificio]"
                                :key="idLoft"
                                :value="idLoft">
                                    {{loft.nombre}}

                                    <span v-if="loftEsZonaComun(loft)">(zona pública)</span>
                                </option>
                            </b-select>
                        </b-field>

                        <InventarioLoft
                        v-if="loftActivo && lofts[indiceEdificio] && lofts[indiceEdificio][loftActivo] && !loftEsZonaComun(lofts[indiceEdificio][loftActivo])"
                        :loft="lofts[indiceEdificio][loftActivo]"
                        @update="actualizarLoft(indiceEdificio, $event)" />
                        <b-notification v-else-if="loftActivo && lofts[indiceEdificio] && lofts[indiceEdificio][loftActivo] && loftEsZonaComun(lofts[indiceEdificio][loftActivo])" type="is-info">
                            <b>{{lofts[indiceEdificio][loftActivo].nombre}}</b> es una zona común donde <u>no registramos el inventario</u>
                        </b-notification>
                        <b-notification v-else :closable="false" type="is-info">
                            No has seleccionado ningún loft
                        </b-notification>
                    </b-tab-item>

                    <b-tab-item label="Lavandería" icon="soap">
                        <h2 class="subtitle"><b>Lavandería</b> {{edificio.nombre}}</h2>

                        <LavanderiaEdificio
                        v-if="lavanderias"
                        :indiceEdificio="indiceEdificio"
                        :lavanderia="lavanderias[indiceEdificio]"
                        @update="refrescarDatos($event, indiceEdificio)" />
                    </b-tab-item>

                    <b-tab-item label="Almacén" icon="box-open">
                        <h2 class="subtitle"><b>Almacén</b> {{edificio.nombre}}</h2>

                        <AlmacenEdificio
                        v-if="almacenes"
                        :indiceEdificio="indiceEdificio"
                        :almacen="almacenes[indiceEdificio]"
                        @update="refrescarDatos($event, indiceEdificio)" />
                    </b-tab-item>
                </b-tabs>
            </b-tab-item>
        </b-tabs>
    </div>
</template>


<script>

/**
 * Almacén loft
 */
import InventarioLoft from '../components/InventarioLoft.vue'

/**
 * Lavandería edificio
 */
import LavanderiaEdificio from '../components/LavanderiaEdificio.vue'

/**
 * Almacén edificio
 */
import AlmacenEdificio from '../components/AlmacenEdificio.vue'

/**
 * Manejar almacenes y lavanderias
 */
import { obtenerInventarioCompleto } from '../services/inventario.js'

export default {
    name:'VistaInventario',
    components:{
        InventarioLoft,
        LavanderiaEdificio,
        AlmacenEdificio
    },
    data(){
        return {
            tabInventarioActivo: 0,
            tabContenidoActivo: 0,
            loftActivo: '',
            almacenes: null,
            lavanderias: null
        }
    },
    computed:{
        edificios(){
            return this.$store.state.edificios
        },
        lofts(){
            return this.$store.state.lofts
        }
    },
    mounted(){
        this.cargarInventario()
    },
    methods:{
        /**
         * Indica si un loft es una zona común
         */
        loftEsZonaComun(loft){
            return typeof loft.zonaComun === 'boolean' && loft.zonaComun
        },
        /**
         * Indica que se actualizó un loft
         */
        actualizarLoft(indiceEdificio, datos){
            this.$store.dispatch('updateLoft', {
                indiceEdificio: indiceEdificio,
                loft: datos.loft
            })

            this.refrescarDatos(datos, indiceEdificio)
        },
        refrescarDatos(datos, indiceEdificio){
            // almacenes
            if(typeof datos.almacenes !== 'undefined'){
                this.almacenes = datos.almacenes
            }

            // lavanderías
            if(typeof datos.lavanderias !== 'undefined'){
                this.lavanderias = datos.lavanderias
            }

            // rechazos
            if(typeof datos.rechazos !== 'undefined'){
                this.rechazos = datos.rechazos
            }

            let hayIndiceEdificio = !isNaN(indiceEdificio)

            console.log('refrescamos datos', datos, indiceEdificio, hayIndiceEdificio)

            // almacén actualizado
            if(typeof datos.almacen !== 'undefined' && hayIndiceEdificio){
                this.$set(
                    this.almacenes,
                    indiceEdificio,
                    datos.almacen
                )
            }

            // lavandería actualizada
            if(typeof datos.lavanderia !== 'undefined' && hayIndiceEdificio){
                this.$set(
                    this.lavanderias,
                    indiceEdificio,
                    datos.lavanderia
                )
            }
        },
        /**
         * Solicita el nuevo estado de los almacenes y lavanderías
         */
        async cargarInventario(){
            try {
                let respuesta = await obtenerInventarioCompleto()

                this.refrescarDatos(respuesta.data)
            } catch(e){
                console.error(e)

                alert('Error cargando almacenes')
            }
        }
    }
}
</script>