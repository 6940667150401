<template>
    <div
    class="buttons"
    :class="{'is-centered': centered}">
        <b-button
        @click="abrirNuevaPestana('https://www.instagram.com/canarylofts/')"
        :type="type"
        rounded>
            <vue-fontawesome icon="fa-brands fa-instagram" />
        </b-button>
        <b-button
        @click="abrirNuevaPestana('https://es-es.facebook.com/canarylofts/')"
        :type="type"
        rounded>
            <vue-fontawesome icon="fa-brands fa-facebook" />
        </b-button>
    </div>
</template>

<script>

/**
 * Gestionar ventanas
 */
import { abrirNuevaPestana } from '../services/ventanas.js'

export default {
    name: 'Footer',
    methods:{
        abrirNuevaPestana: abrirNuevaPestana
    },
    props:{
        centered:{
            type: Boolean,
            default: false
        },
        type:{
            type: String,
            default: 'is-primary'
        }
    }
}
</script>