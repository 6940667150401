<template>
    <footer class="footer has-background-secondary has-text-primary pb-3">
        <div class="column">
            <b-field
            @keyup.native.enter="registrarCorreo">
                <b-input
                v-model="emailSuscribir"
                size="is-large" :placeholder="traducir('Tu dirección de correo electrónico')" expanded rounded></b-input>
            </b-field>

            <div class="buttons is-centered">
                <b-button @click="registrarCorreo" :disabled="!correoValido" type="is-primary" rounded>
                    {{traducir('Suscribirme')}}
                </b-button>
            </div>
        </div>

        <b-modal :active.sync="modalActivo">
            <div class="card has-background-primary has-text-secondary">
                <div class="card-content">
                    <h2 class="is-size-4 has-text-weight-light mb-3">
                        {{traducir('Al hacer click en confirmar, estás aceptando nuestra')}} <span @click="modalActivo = false"><router-link :to="{name: 'PoliticaPrivacidad'}" class="has-text-secondary is-size-5 is-underlined">{{traducir('Política de privacidad')}}</router-link></span>
                    </h2>
                    <div class="buttons is-right">
                        <b-button @click="modalActivo = false" type="is-primary" outlined inverted size="is-large">
                            {{traducir('Cancelar')}}
                        </b-button>

                        <b-button :loading="suscribiendo" @click="agregarSuscripcion()" type="is-primary" outlined inverted size="is-large">
                            {{traducir('Confirmar')}}
                        </b-button>
                    </div>
                </div>
            </div>
        </b-modal>
    </footer>
</template>

<script>

/**
 * Suscripciones
 */
import {agregarSuscripcion} from '../services/suscripciones.js'

/**
 * Traducciones
 */
import {traducir} from '../services/traducciones.js'

export default {
    name: 'Suscripcion',
    data(){
        return {
            suscribiendo: false,

            emailSuscribir: '',

            modalActivo: false
        }
    },
    computed:{
        idioma(){
            return this.$store.state.idioma
        },
        correoValido(){
            return this.emailSuscribir.length > 5 && this.emailSuscribir.indexOf('@') !== -1 && this.emailSuscribir.indexOf('.') !== -1
        }
    },
    methods:{
        /**
         * Agrega una nueva suscripción
         */
        async agregarSuscripcion(){
            this.suscribiendo = true

            try {
                let respuesta = await agregarSuscripcion(this.emailSuscribir)

                if(typeof respuesta.status === 'string' && respuesta.status === 'OK'){
                    alert('Te has suscrito correctamente')

                    this.emailSuscribir = ''

                    this.modalActivo = false
                } else {
                    alert('Error suscribiendo correo')
                }

                this.suscribiendo = false
            } catch(e){
                console.error('Error suscribiendo correo: ' + e.message)

                this.suscribiendo = false
            }
        },

        /**
         * Traduce un texto
         */
        traducir(texto){
            return traducir(texto, this.idioma)
        },

        /**
         * Registra un nuevo suscriptor
         */
        registrarCorreo(){
            console.log(this.emailSuscribir)

            this.modalActivo = true
        }
    }
}
</script>