
/**
 * Leer y escribir cookies
 */
import {getCookie, setCookie} from './cookies.js'

/**
 * Indica si el usuario ha aceptado las políticas que le mostramos
 * @return {Boolean}
 */
export const usuarioAceptaPoliticas = () => {
    let valorCookie = getCookie('usuarioAceptaPoliticas')

    if(typeof valorCookie === 'string' && valorCookie.length > 0){
        return true
    }

    return false
}

/**
 * Escribe la cookie indicando que el usuario aceptó las políticas que le mostramos
 */
export const aceptarPoliticas = () => {
    setCookie(
        'usuarioAceptaPoliticas',
        '1',
        15
    )
}
