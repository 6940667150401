<template>
    <section class="hero is-small is-primary pb-5" style="position:relative;bottom:7rem;">
      <div class="hero-body has-text-centered">
        <p class="title pb-3" style="border-bottom:solid 1px white;">
          <span class="has-text-weight-bold">{{traducir('Bienvenido a', idioma)}} <span class="has-text-weight-light">Canary</span>Lofts</span>
        </p>
        
        <div class="columns">
          <div class="column"></div>
          <div class="column is-6">
            <p class="subtitle has-text-centered mb-3">
              {{traducir('Apartamentos totalmente equipados en el centro de Santa Cruz de Tenerife', idioma)}}
            </p>
          </div>
          <div class="column"></div>
        </div>

        <RedesSociales centered />

        <DatosContacto />

      </div>
    </section>
</template>

<script>

/**
 * Redes sociales
 */
import RedesSociales from '@/components/RedesSociales.vue'

/**
 * Datos de contacto
 */
import DatosContacto from '@/components/DatosContacto.vue'

/**
 * Traducciones
 */
import {traducir} from '../services/traducciones.js'

export default {
    name: 'BannerBienvenida',
    components:{
      RedesSociales,
      DatosContacto
    },
    computed:{
      idioma(){
        return this.$store.state.idioma
      }
    },
    methods:{
      traducir:traducir
    }
}
</script>

<style scoped>
.subtitle{
  text-indent: 2rem;
}
</style>