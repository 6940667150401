<template>
    <div class="container is-fluid has-background-secondary pt-6">
        <div id="availRoomBookingApp">
            <BannerPublicidad />
        </div>
    </div>
</template>

<script>

/**
 * Imagen publicidad
 */
import BannerPublicidad from './BannerPublicidad.vue'

export default {
    name: 'AvailRoom',
    components:{
        BannerPublicidad
    },
    data(){
        return {
            intervaloComprobarHabitacion: null
        }
    },
    mounted(){
        /*let availRoomScript = document.createElement('script')

        availRoomScript.setAttribute('src', 'https://booking.availroom.com/v2/availRoomBooking.js')

        document.head.appendChild(availRoomScript)*/

        this.iniciar()
    },
    methods:{
        comprobarHabitacion(paramHabitacion){
            let elementos = document.getElementById('availRoomBookingApp').childNodes.item(0).childNodes.item(0).childNodes.item(0).childNodes,
            elementosContados = 0,
            nombreHabitacionBuscar = paramHabitacion.trim().toLowerCase()

            for(let e in elementos){
                let clase = typeof elementos[e].className === 'string' ? elementos[e].className : ''
                
                if(clase && clase.indexOf('room') !== -1){
                    let elementoNombreHabitacion = elementos[e].childNodes.item(0).childNodes.item(0).childNodes.item(1).childNodes.item(0),
                    nombreHabitacion = elementoNombreHabitacion.innerHTML

                    if(nombreHabitacion.trim().toLowerCase() === nombreHabitacionBuscar){
                        elementoNombreHabitacion.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        })
                    }
                }

                elementosContados++
            }

            if(elementosContados > 7){
                clearInterval(this.intervaloComprobarHabitacion)
            }
        },
        iniciar(){
            new window.availRoomBookingApp({
                divId: 'availRoomBookingApp',
                environmentId: 'CF098C43C61E4BB488ECB0A7EC902204',
                type: 'multiunit',
                defaultMargin: true
            })

            let queryString = window.location.search,
            urlParams = new URLSearchParams(queryString),
            paramHabitacion = urlParams.get('habitacion')

            if(typeof paramHabitacion === 'string' && paramHabitacion.length > 0){
                this.intervaloComprobarHabitacion = setInterval(() => {
                    this.comprobarHabitacion(paramHabitacion)
                }, 500)
            }
        }
    }
}
</script>

<style scoped>

@import url(https://booking.availroom.com/v2/static/css/main.css);

</style>