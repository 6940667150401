
/**
 * Determina el tipo del dispositivo en base a su tamaño
 */
export const getDeviceSizeType = () => {
    let anchuraDispositivo = (window.innerWidth > 0) ? window.innerWidth : screen.width

    // móviles
    if(anchuraDispositivo <= 768){
        return 'is-mobile'
    }

    // tablets
    else if(anchuraDispositivo >= 769 && anchuraDispositivo <= 1023){
        return 'is-tablet'
    }


    // escritorio
    else if(anchuraDispositivo >= 1024 && anchuraDispositivo <= 1215) {
        return 'is-desktop'
    }

    // widescreen
    else if(anchuraDispositivo >= 1216 && anchuraDispositivo <= 1407) {
        return 'is-widescreen'
    }
    
    // full-hd
    else {
        return 'is-fullhd'
    }
}
