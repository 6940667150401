<template>
    <div class="jornada-limpieza container">
        <b-tabs
        expanded
        v-model="tabActivo"
        type="is-toggle"
        size="is-large">
            <b-tab-item label="Mi jornada">
                <!-- Mi jornada -->
                <b-notification
                :type="miJornadaIniciada ? 'is-primary' : 'is-danger'"
                :closable="false" class="has-text-centered">
                    <h2 class="subtitle" v-if="edificioJornadaActual">
                        Estás en <span class="is-size-4 has-text-weight-bold">{{edificioJornadaActual.nombre}}</span><span v-if="miJornada.idLoft" class="is-size-4 has-text-weight-bold"> &gt; {{lofts[miJornada.indiceEdificio][miJornada.idLoft].nombre}}</span>
                    </h2>
                    <h2 class="subtitle" v-else>No has iniciado tu jornada</h2>
                </b-notification>

                <b-steps
                v-model="pasoJornadaActual"
                :has-navigation="false"
                type="is-primary">

                    <!-- Seleccionar edificio -->
                    <b-step-item step="1" label="Entrada" :clickable="false">
                        <div class="columns">
                            <div
                            class="column">
                                <div class="buttons is-centered">
                                    <b-button
                                    v-for="(edificio,indiceEdificio) in edificios"
                                    :key="indiceEdificio"
                                    @click="indiceEdificioIniciarJornada = indiceEdificio;modalConfirmarInicioJornadaActivo = true"
                                    :label="edificio.nombre"
                                    :icon-left="edificio.restaurante ? 'utensils' : 'building'"
                                    size="is-large"
                                    type="is-primary"></b-button>
                                </div>
                            </div>
                        </div>
                    </b-step-item>

                    <!-- Almacén y lofts edificio -->
                    <b-step-item step="2" :label="labelEdificioJornadaActual" :clickable="false">
                        <b-tabs
                        expanded
                        v-model="tabJornadaActualActivo"
                        type="is-toggle"
                        size="is-medium">
                            <b-tab-item
                            label="Lofts" icon="door-closed">
                                <b-collapse
                                class="card has-background-secondary"
                                animation="slide"
                                v-for="(loft,idLoft) in loftsEdificioActual"
                                :key="idLoft"
                                :id="'caja_loft_' + idLoft"
                                :open="idLoftActivoCollapse == idLoft"
                                @open="idLoftActivoCollapse = idLoft">
                                    <template #trigger="props">
                                        <div
                                        :id="'collapse_loft_' + idLoft"
                                        class="card-header has-background-primary mb-1"
                                        :class="{'has-background-success': loftTerminado(idLoft)}"
                                        role="button"
                                        :aria-controls="'contentIdForA11y5-' + idLoft"
                                        :aria-expanded="props.open">
                                            <p class="card-header-title has-text-white has-text-weight-light">
                                                <span class="is-size-4">{{ loft.zonaComun ? 'Zona común' : 'Loft' }} {{ loft.nombre }}</span>
                                                <b-tag
                                                v-show="!isMobile"
                                                v-for="(jornadaLoftUsuario,idUsuarioJornadaLoft) in usuariosLoft(idLoft)"
                                                :key="'ujl-' + idUsuarioJornadaLoft"
                                                :icon="loftTerminadoPorUsuario(idLoft, idUsuarioJornadaLoft) ? 'check-circle' : ''"
                                                :type="loftTerminadoPorUsuario(idLoft, idUsuarioJornadaLoft) ? 'is-success' : 'is-secondary'"
                                                size="is-large" class="ml-1">
                                                    <b>{{usuarios[idUsuarioJornadaLoft].nombre}}</b> {{jornadaLoftUsuario.progreso}} min.
                                                </b-tag>
                                            </p>
                                            <a class="card-header-icon">
                                                <b-icon
                                                    custom-class="has-text-white"
                                                    :icon="props.open ? 'chevron-down' : 'chevron-up'">
                                                </b-icon>
                                            </a>
                                        </div>
                                    </template>
                                    <div class="card-content">
                                        <div class="content">
                                            <div class="columns">
                                                <div v-if="miJornadaIniciada" class="column has-text-centered">
                                                    <b-tag
                                                    v-show="isMobile"
                                                    v-for="(jornadaLoftUsuario,idUsuarioJornadaLoft) in usuariosLoft(idLoft)"
                                                    :key="'ujl-' + idUsuarioJornadaLoft"
                                                    :type="loftTerminadoPorUsuario(idLoft, idUsuarioJornadaLoft) ? 'is-success' : 'is-primary'"
                                                    size="is-large" class="mb-3">
                                                        <b-icon v-if="loftTerminadoPorUsuario(idLoft, idUsuarioJornadaLoft)" icon="check-circle" size="is-small"></b-icon> <b>{{usuarios[idUsuarioJornadaLoft].nombre}}</b> {{jornadaLoftUsuario.progreso}} min. <span v-if="!jornadaLoftUsuario.terminado">(en progreso)</span>
                                                    </b-tag>

                                                    <P v-if="loftTerminarProgreso && loftTerminarProgreso.id != idLoft">
                                                        Tienes que cerrar <span @click="irALoft(loftTerminarProgreso.id)" class="has-text-weight-bold is-underlined">{{loftTerminarProgreso.nombre}}</span> para iniciar <span class="has-text-weight-bold">{{loft.nombre}}</span>
                                                    </P>

                                                    <b-button
                                                    v-if="loftTerminarProgreso && loftTerminarProgreso.id == idLoft"
                                                    @click="modalConfirmarFinJornadaLoftActivo = true"
                                                    type="is-danger"
                                                    icon-left="power-off"
                                                    expanded>
                                                        Terminar
                                                    </b-button>
                                                    <b-button
                                                    v-else
                                                    :disabled="(loftTerminarProgreso && loftTerminarProgreso.id != idLoft) || loftTerminado(idLoft) || loftTerminadoPorUsuario(idLoft, usuarioLogueado.id)"
                                                    @click="loftIniciarJornada = loft;modalConfirmarInicioJornadaLoftActivo = true"
                                                    type="is-primary"
                                                    icon-left="power-off"
                                                    expanded>
                                                        <span v-if="loftTerminado(idLoft) || loftTerminadoPorUsuario(idLoft, usuarioLogueado.id)">
                                                            Terminado
                                                        </span>
                                                        <span v-else>
                                                            Empezar
                                                        </span>
                                                    </b-button>
                                                </div>
                                            </div>

                                            <div v-if="false && ((loftTerminarProgreso && loftTerminarProgreso.id == idLoft) || esAdministrador)">
                                                <b-notification
                                                v-if="typeof loft.zonaComun === 'boolean' && loft.zonaComun"
                                                :closable="false"
                                                type="is-primary">
                                                    <b>{{loft.nombre}}</b> es una zona común donde <u>no registramos el inventario</u>
                                                </b-notification>

                                                <InventarioLoft
                                                v-else
                                                :loft="loft"
                                                @update="updateInventarioLoft(idLoft, $event)" />
                                            </div>
                                        </div>
                                    </div>
                                </b-collapse>
                            </b-tab-item>

                            <b-tab-item label="Lavandería" icon="soap">
                                <LavanderiaEdificio
                                v-if="miJornadaIniciada"
                                :indiceEdificio="indiceEdificioJornadaActual"
                                :lavanderia="miJornadaIniciada && miJornada.lavanderia ? miJornada.lavanderia : {}"
                                @update="refrescarDatos($event)" />
                            </b-tab-item>

                            <b-tab-item label="Almacén" icon="box-open">
                                <AlmacenEdificio
                                v-if="miJornadaIniciada"
                                :indiceEdificio="indiceEdificioJornadaActual"
                                :almacen="miJornadaIniciada && miJornada.almacen ? miJornada.almacen : {}"
                                @update="miJornada.almacen = $event" />
                            </b-tab-item>
                        </b-tabs>

                        <p v-if="loftTerminarProgreso" class="has-text-centered is-size-4">
                            ( Loft <span @click="irALoft(loftTerminarProgreso.id)" class="is-underlined has-text-weight-bold is-size-4">{{loftTerminarProgreso.nombre}}</span> activo )
                        </p>

                        <b-button
                        :disabled="loftTerminarProgreso"
                        @click="modalConfirmarFinJornadaActivo = true"
                        size="is-large" type="is-danger" expanded class="mt-3">
                            <span class="is-size-4">Finalizar jornada</span>
                        </b-button>
                    </b-step-item>

                    <!-- Salida -->
                    <b-step-item step="3" label="Salida"></b-step-item>
                </b-steps>
            </b-tab-item>

            <!-- Jornadas conjuntas -->
            <b-tab-item label="Todas" v-if="esAdministrador">
                <div v-if="hayJornadas">
                    <div class="columns">
                        <div class="column">
                            <b-field label="Edificio" type="is-light">
                                <b-select
                                expanded
                                v-model="indiceEdificioVerJornadas"
                                size="is-large">
                                    <option
                                    v-for="(edificio,indiceEdificio) in edificios"
                                    :key="indiceEdificio"
                                    :value="indiceEdificio">
                                        {{edificio.nombre}}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Rango" type="is-light">
                                <b-datepicker v-model="fechasJornadas" range size="is-large" expanded locale="es"></b-datepicker>
                            </b-field>
                        </div>
                    </div>

                    <div class="content" style="overflow:auto;">
                        <table
                        v-if="jornadasUsuarios[indiceEdificioVerJornadas]" class="table is-hoverable is-fullwidth has-background-secondary">
                            <thead>
                                <tr>
                                    <th>
                                        Usuario
                                    </th>
                                    <th>
                                        Entrada
                                    </th>
                                    <th>
                                        Salida
                                    </th>
                                    <th>
                                        Tiempo total
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                            v-for="(jornadaUsuario,idUsuario) in jornadasUsuarios[indiceEdificioVerJornadas]"
                            :key="idUsuario">
                                <tr
                                v-for="(jornadaEdificio,indiceJornadaEdificio) in jornadasUsuarios[indiceEdificioVerJornadas][idUsuario].jornadas"
                                :key="indiceJornadaEdificio"
                                class="has-background-info has-text-white">
                                    <td>
                                        <span v-if="indiceJornadaEdificio == 0">
                                            <span v-if="usuarios[idUsuario]" class="is-size-5">
                                                {{usuarios[idUsuario].nombre}}
                                            </span>
                                            <span v-else class="is-size-5">Usuario <b>{{idUsuario}}</b></span>
                                        </span>
                                    </td>
                                    <td>
                                        {{jornadaEdificio.entrada}}
                                    </td>
                                    <td>
                                        <b-tag v-if="jornadaEdificio.enProgreso" type="is-danger">En progreso</b-tag>
                                        <span v-else>{{jornadaEdificio.salida}}</span>
                                    </td>
                                    <td>
                                        <span>{{progresoTotalJornada(jornadaEdificio)}}</span>
                                    </td>
                                    <td>
                                        <div class="buttons is-right">
                                            <b-tooltip label="Editar">
                                                <b-button @click="crearJornada('editarJornada', jornadaEdificio, idUsuario)" type="is-info" icon-left="pencil-alt" outlined inverted></b-button>
                                            </b-tooltip>
                                            <b-tooltip v-if="!jornadaEdificio.enProgreso" label="Borrar">
                                                <b-button @click="borrarJornada(jornadaEdificio)" type="is-danger" icon-left="trash"></b-button>
                                            </b-tooltip>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="has-background-info-light">
                                    <th>Lofts gestionados</th>
                                    <th>Hora inicio</th>
                                    <th>Hora fin</th>
                                    <th colspan="2">Tiempo por loft</th>
                                </tr>
                                <tr
                                class="has-background-info-light"
                                v-for="(progresoLoft,idLoft) in jornadasUsuarios[indiceEdificioVerJornadas][idUsuario].lofts"
                                :key="idLoft">
                                    <td>
                                        <span v-if="lofts[indiceEdificioVerJornadas] && lofts[indiceEdificioVerJornadas][idLoft]">
                                            {{lofts[indiceEdificioVerJornadas][idLoft].nombre}}
                                        </span>
                                        <span v-else>Loft <b>{{idLoft}}</b></span>
                                    </td>
                                    <td>
                                        {{progresoLoft.inicio}}
                                    </td>
                                    <td>
                                        <span v-if="progresoLoft.fin">
                                            {{progresoLoft.fin}}
                                        </span>
                                        <span v-else>
                                            <b-tag type="is-danger">En progreso</b-tag>
                                        </span>
                                    </td>
                                    <td colspan="2">
                                        <span v-if="progresoLoft.progreso">
                                            {{parseFloat(progresoLoft.progreso).toFixed(0)}} minutos
                                        </span>
                                        <span v-else>
                                            <b-tag type="is-danger">En progreso</b-tag>
                                        </span>
                                    </td>
                                </tr>
                                <tr
                                v-if="jornadasUsuarios[indiceEdificioVerJornadas][idUsuario].movimientosInventario"
                                v-show="usuariosVerMovimientos[idUsuario]"
                                class="has-background-warning-light has-text-centered">
                                    <th>
                                        Hora
                                    </th>
                                    <th>
                                        Inventario movido
                                    </th>
                                    <th>
                                        Origen
                                    </th>
                                    <th>
                                        Destino
                                    </th>
                                    <th>
                                        Unidades
                                    </th>
                                </tr>
                                <tr
                                class="has-text-centered"
                                v-show="usuariosVerMovimientos[idUsuario]"
                                v-for="(movimiento,indiceMovimiento) in jornadasUsuarios[indiceEdificioVerJornadas][idUsuario].movimientosInventario"
                                :key="indiceMovimiento">
                                    <td>
                                        {{movimiento.hora}}
                                    </td>
                                    <td>
                                        <span v-if="inventario[movimiento.idItem]">
                                            {{inventario[movimiento.idItem].nombre}}
                                        </span>
                                        <span v-else>{{movimiento.idItem}}</span>
                                    </td>
                                    <td>
                                        <span v-if="movimiento.tipoDesde === 'loft' && lofts[indiceEdificioVerJornadas][movimiento.idLoft]">
                                            loft <b>{{lofts[indiceEdificioVerJornadas][movimiento.idLoft].nombre}}</b>
                                        </span>
                                        <span v-else>
                                            {{obtenerLabelTipoMovimiento(movimiento.tipoDesde, movimiento)}}
                                        </span>
                                    </td>
                                    <td>
                                        <span v-if="movimiento.tipoHasta === 'loft' && lofts[indiceEdificioVerJornadas][movimiento.idLoft]">
                                            loft <b>{{lofts[indiceEdificioVerJornadas][movimiento.idLoft].nombre}}</b>
                                        </span>
                                        <span v-else>
                                            {{obtenerLabelTipoMovimiento(movimiento.tipoHasta, movimiento)}}
                                        </span>
                                    </td>
                                    <td>
                                        {{movimiento.unidades}}
                                    </td>
                                </tr>
                                <!--<tr>
                                    <td colspan="5">
                                        <b-button
                                        v-if="usuariosVerMovimientos[idUsuario]"
                                        @click="$set(usuariosVerMovimientos, idUsuario, false)"
                                        type="is-warning"
                                        expanded>
                                            Ocultar movimientos inventario
                                        </b-button>
                                        <b-button
                                        v-else
                                        @click="$set(usuariosVerMovimientos, idUsuario, true)"
                                        type="is-warning"
                                        expanded>
                                            Ver movimientos inventario
                                        </b-button>
                                    </td>
                                </tr>-->
                                <tr>
                                    <td colspan="5" class="has-text-weight-bold has-text-right">
                                        <span class="is-size-6">
                                            Tiempo total: {{totalTiempoJornadaUsuario(jornadasUsuarios[indiceEdificioVerJornadas][idUsuario])}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <b-notification v-else-if="edificios[indiceEdificioVerJornadas]" :closable="false" type="is-info">
                            No se han registrado jornadas en <b>{{edificios[indiceEdificioVerJornadas].nombre}}</b> por el momento
                        </b-notification>
                    </div>
                </div>

                <!-- No hay jornadas -->
                <b-notification v-else :closable="false">
                    <b>No se encontraron jornadas de hoy</b>
                </b-notification>

                <div v-if="edificios[indiceEdificioVerJornadas]" class="buttons is-centered">
                    <b-button @click="crearJornada('crearJornadaUsuario')" size="is-large" type="is-primary" icon-left="pencil-alt">
                        Crear jornada en {{edificios[indiceEdificioVerJornadas].nombre}}
                    </b-button>
                    <!--<b-button @click="exportarExcel" size="is-large" type="is-primary" icon-left="file-excel">
                        Exportar Excel
                    </b-button>-->
                </div>
            </b-tab-item>
        </b-tabs>

        <!-- Modal borrar jornada -->
        <b-modal v-model="modalBorrarJornada">
            <b-notification :closable="false" type="is-info">
                <h1 class="title has-text-weight-light">
                    Se va a borrar la jornada
                </h1>
                <p v-if="jornadaBorrar" class="is-size-5">
                    {{usuarios[jornadaBorrar.idUsuario].nombre}} el {{jornadaBorrar.fecha | moment('DD/MM/YYYY')}} desde {{jornadaBorrar.entrada}} hasta {{jornadaBorrar.salida}}
                </p>
                <div class="buttons is-right">
                    <b-button @click="modalBorrarJornada = false" type="is-danger">Cancelar</b-button>
                    <b-button type="is-info" outlined inverted>Confirmar</b-button>
                </div>
            </b-notification>
        </b-modal>

        <!-- Modal crear / editar jornada edificio / loft (administradores) -->
        <b-modal v-model="modalCrearJornadaActivo">
            <div class="card has-background-info p-6">
                <div class="card-title">
                    <h1 v-if="edificios[indiceEdificioVerJornadas]" class="title has-text-white">
                        {{datosEditarJornada.jornadaEditar ? 'Editar jornada' : 'Nueva jornada'}} <span class="has-text-weight-light">{{edificios[indiceEdificioVerJornadas].nombre}}</span>
                    </h1>
                </div>
                <div class="card-content">
                    <div class="content">
                        <b-field>
                            <!--Selector de usuarios-->
                            <p v-if="datosEditarJornada.jornadaEditar" class="control">
                                <span class="button is-static">{{usuarios[datosEditarJornada.jornadaEditar.idUsuario].nombre}} @ {{datosEditarJornada.jornadaEditar.fecha | moment('DD/MM/YYYY')}}</span>
                            </p>
                            <b-autocomplete v-else v-model="inputSelectorUsuarios" :data="usuariosSelector" field="nombre" open-on-focus @select="option => $set(datosEditarJornada, 'usuario', option)"></b-autocomplete>

                            <!--Selector de fechas-->
                            <b-datepicker v-if="!datosEditarJornada.jornadaEditar" append-to-body label="Fecha" v-model="datosEditarJornada.nuevaJornada.desde" locale="es"></b-datepicker>

                            <!--Rango horas-->
                            <b-timepicker append-to-body label="Hora desde" v-model="datosEditarJornada.nuevaJornada.desde" locale="es" :max-time="datosEditarJornada.nuevaJornada.hasta"></b-timepicker>
                            <b-timepicker append-to-body label="Hora hasta" v-model="datosEditarJornada.nuevaJornada.hasta" locale="es" :min-time="datosEditarJornada.nuevaJornada.desde"></b-timepicker>
                        </b-field>
                    </div>
                    <div class="buttons is-right">
                        <b-button @click="modalCrearJornadaActivo = false" type="is-danger">Cancelar</b-button>
                        <b-button @click="confirmarCrearJornada" type="is-info" outlined inverted>Confirmar</b-button>
                    </div>
                </div>
            </div>
        </b-modal>

        <!-- Modal confirmar inicio jornada -->
        <b-modal v-model="modalConfirmarInicioJornadaActivo">
            <b-notification type="is-info" v-if="indiceEdificioIniciarJornada !== null" :closable="false">
                <h1 class="subtitle">
                    ¿Iniciar jornada en {{edificios[indiceEdificioIniciarJornada].nombre}}?
                </h1>

                <div class="buttons is-right">
                    <b-button
                    @click="modalConfirmarInicioJornadaActivo = false;indiceEdificioIniciarJornada = null"
                    type="is-danger">
                        Cancelar
                    </b-button>
                    <b-button
                    @click="iniciarJornada"
                    type="is-info" inverted outlined>
                        Confirmar
                    </b-button>
                </div>
            </b-notification>
        </b-modal>

        <!-- Modal confirmar fin jornada -->
        <b-modal v-model="modalConfirmarFinJornadaActivo">
            <b-notification type="is-info" :closable="false">
                <h1 class="subtitle" v-if="edificioJornadaActual">
                    ¿Finalizar jornada en <u>{{edificioJornadaActual.nombre}}</u>?
                </h1>

                <div class="buttons is-right">
                    <b-button
                    @click="modalConfirmarFinJornadaActivo = false"
                    type="is-danger">
                        Cancelar
                    </b-button>
                    <b-button
                    @click="finalizarJornada"
                    type="is-info" inverted outlined>
                        Confirmar
                    </b-button>
                </div>
            </b-notification>
        </b-modal>

        <!-- Modal confirmar inicio progreso loft -->
        <b-modal v-model="modalConfirmarInicioJornadaLoftActivo">
            <b-notification type="is-info" v-if="loftIniciarJornada !== null" :closable="false">
                <h1 class="subtitle">
                    ¿Iniciar progreso en el Loft {{loftIniciarJornada.nombre}}?
                </h1>

                <div class="buttons is-right">
                    <b-button
                    @click="modalConfirmarInicioJornadaLoftActivo = false;loftIniciarJornada = null"
                    type="is-danger">
                        Cancelar
                    </b-button>
                    <b-button
                    @click="iniciarJornadaLoft"
                    type="is-info" inverted outlined>
                        Confirmar
                    </b-button>
                </div>
            </b-notification>
        </b-modal>

        <!-- Modal confirmar fin progreso loft -->
        <b-modal v-model="modalConfirmarFinJornadaLoftActivo">
            <b-notification type="is-info" :closable="false">
                <h1 class="subtitle" v-if="loftTerminarProgreso">
                    ¿Terminar progreso en el Loft <u>{{loftTerminarProgreso.nombre}}</u>?
                </h1>

                <div class="buttons is-right">
                    <b-button
                    @click="modalConfirmarFinJornadaLoftActivo = false"
                    type="is-danger">
                        Cancelar
                    </b-button>
                    <b-button
                    @click="terminarJornadaLoft"
                    type="is-info" inverted outlined>
                        Confirmar
                    </b-button>
                </div>
            </b-notification>
        </b-modal>
    </div>
</template>

<script>

/**
 * Manejar ítems de un loft
 */
import InventarioLoft from './InventarioLoft.vue'

/**
 * Manejar ítems del almacén de un edificio
 */
import AlmacenEdificio from './AlmacenEdificio.vue'

/**
 * Manejar ítems de la lavandería de un edificio
 */
import LavanderiaEdificio from './LavanderiaEdificio.vue'

/**
 * Interactuar con las jornadas de limpieza
 */
import {obtenerJornadasHoy, iniciarJornada, finalizarJornada, iniciarJornadaLoft, terminarJornadaLoft, crearJornadaUsuario, editarJornadaUsuario} from '../services/jornadasLimpieza.js'

/**
 * Dispositivos
 */
import {getDeviceSizeType} from '../services/devices.js'

/**
 * SheetJS
 */
import {exportarExcel} from '../services/spreadsheet.js'

export default {
    name:'JornadaLimpieza',
    components:{
        InventarioLoft,
        AlmacenEdificio,
        LavanderiaEdificio
    },
    data(){
        let fechasJornadas = [
            new Date(),
            new Date()
        ]

        return {
            tabActivo:0,
            tabJornadaActualActivo:0,
            jornadasHoy:{},
            miJornada:null,

            // Loft activo
            idLoftActivoCollapse:0,

            // Iniciar jornada edificio
            indiceEdificioIniciarJornada:null,
            modalConfirmarInicioJornadaActivo:false,

            // Terminar jornada
            modalConfirmarFinJornadaActivo:false,

            // Iniciar jornada loft
            loftIniciarJornada:null,
            modalConfirmarInicioJornadaLoftActivo:false,

            // Terminar jornada loft
            modalConfirmarFinJornadaLoftActivo:false,

            // Ver todas las jornadas
            indiceEdificioVerJornadas: 0,
            jornadasUsuarios: {},
            jornadasLofts: {},
            paginaFechaActual: '',
            paginasFechasJornadas: {},
            usuariosVerMovimientos: {},
            fechasJornadas: fechasJornadas,

            // Crear jornada
            modalCrearJornadaActivo: false,
            inputSelectorUsuarios: '',
            datosEditarJornada:{
                servicio: '',
                usuario: null,
                jornadaEditar: null,
                nuevaJornada: {
                    fecha: new Date(),
                    desde: new Date(),
                    hasta: new Date()
                }
            },

            // Borrar jornada
            jornadaBorrar: null,
            modalBorrarJornada: false
        }
    },
    computed:{
        /*jornadasUsuariosFechaActual(){
            let jornadasUsuariosFechaActual = {}

            for(let indiceEdificio in this.jornadasUsuarios){
                for(let idUsuario in this.jornadasUsuarios[indiceEdificio]){
                    for(let i in this.jornadasUsuarios[indiceEdificio][idUsuario].jornadas){
                        if(this.jornadasUsuarios[indiceEdificio].jornadas[i].fecha === this.paginaFechaActual){
                            // indice edificio
                            if(typeof jornadasUsuariosFechaActual[indiceEdificio] === 'undefined'){
                                jornadasUsuariosFechaActual[indiceEdificio] = {}
                            }

                            // jornada usuario
                            if(typeof jornadasUsuariosFechaActual[indiceEdificio][idUsuario] === 'undefined'){
                                jornadasUsuariosFechaActual[indiceEdificio][idUsuario] = {
                                    jornadas: [],
                                    lofts: {}
                                }
                            }

                            jornadasUsuariosFechaActual[indiceEdificio][idUsuario].jornadas.push(
                                this.jornadasUsuarios[indiceEdificio][idUsuario].jornadas[i]
                            )

                            // lofts jornada
                            for(let idLoft in this.jornadasUsuarios[indiceEdificio][idUsuario].lofts){
                                if(this.jornadasUsuarios[indiceEdificio][idUsuario].lofts[idLoft].idJornadaLimpieza == this.jornadasUsuarios[indiceEdificio].jornadas[i].idJornadaLimpieza){
                                    jornadasUsuariosFechaActual[indiceEdificio][idUsuario].lofts[idLoft] = this.jornadasUsuarios[indiceEdificio][idUsuario].lofts[idLoft]
                                }
                            }
                        }
                    }
                }
            }

            return jornadasUsuariosFechaActual
        },*/
        usuariosSelector(){
            let usuariosSelector = [],
            filtroMinus = this.inputSelectorUsuarios.toLowerCase(),
            todos = filtroMinus.length === 0

            for(let u in this.usuarios){
                if(todos || this.usuarios[u].nombre.toLowerCase().indexOf(filtroMinus) !== -1){
                    usuariosSelector.push(
                        this.usuarios[u]
                    )
                }
            }

            return usuariosSelector
        },
        usuarioLogueado(){
            return this.$store.state.usuarioLogueado
        },
        isMobile(){
            return this.tipoDispositivo === 'is-mobile'
        },
        tipoDispositivo(){
            return this.getDeviceSizeType()
        },
        esAdministrador(){
            return this.$store.state.esAdministrador
        },
        loftTerminarProgreso(){
            if(this.miJornadaIniciada && typeof this.miJornada.idLoft === 'string'){
                if(typeof this.lofts[this.miJornada.indiceEdificio] === 'undefined'){
                    return null
                }

                if(typeof this.lofts[this.miJornada.indiceEdificio][this.miJornada.idLoft] === 'undefined'){
                    return null
                }

                return this.lofts[this.miJornada.indiceEdificio][this.miJornada.idLoft]
            }

            return null
        },
        loftsEdificioActual(){
            if(this.miJornadaIniciada){
                return this.lofts[this.indiceEdificioJornadaActual]
            }

            return {}
        },
        labelEdificioJornadaActual(){
            if(this.edificioJornadaActual){
                return this.edificioJornadaActual.nombre
            }

            return ''
        },
        edificioJornadaActual(){
            if(this.miJornadaIniciada){
                return this.edificios[this.indiceEdificioJornadaActual]
            }

            return null
        },
        miJornadaIniciada(){
            return this.indiceEdificioJornadaActual !== null && !isNaN(this.indiceEdificioJornadaActual)
        },
        indiceEdificioJornadaActual(){
            if(this.miJornada && typeof this.miJornada.indiceEdificio !== 'undefined'){
                return this.miJornada.indiceEdificio
            }

            return null
        },
        hayJornadas(){
            if(typeof this.jornadasHoy.edificios !== 'undefined'){
                for(let e in this.jornadasHoy.edificios){
                    return true
                }
            }

            return false
        },
        inventario(){
            return this.$store.state.inventario
        },
        usuarios(){
            return this.$store.state.usuarios
        },
        lofts(){
            return this.$store.state.lofts
        },
        edificios(){
            return this.$store.state.edificios
        },
        /**
         * Indica en qué paso de la jornada actual nos encontramos ahora mismo
         */
        pasoJornadaActual(){
            // si no hemos iniciado la jornada, estamos en el primer paso: seleccionar edificio
            if(!this.miJornada){
                return 0
            }

            // si la jornada está iniciada pero no se está limpiando un loft, podemos:
            //  1. interactuar con el almacén del edificio
            //  2. seleccionar loft para iniciarlo
            //  3. dar por terminada la jornada
            else if(!this.miJornada.idLoftActual){
                return 1
            }

            // si estamos limpiando un loft, podemos:
            //  1. interactuar con el almacén del edificio
            //  2. interactuar con el almacén del loft
            else if(this.miJornada.idLoftActual){
                return 2
            }

            // jornada terminada, dar las gracias
            else if(this.miJornada.terminada){
                return 3
            }

            // no se debería llegar aquí, pero en dicho caso volvemos al punto inicial
            return 0
        }
    },
    mounted(){
        this.cargar()
    },
    methods:{
        /**
         * Abre la confirmación para borrar una jornada
         */
        borrarJornada(jornada){
            this.jornadaBorrar = jornada

            this.modalBorrarJornada = true
        },
        /**
         * Ejecuta el servicio para crear la jornada a otro usuario
         */
        async confirmarCrearJornada(){
            try {
                let respuesta

                // Editar jornada
                if(this.datosEditarJornada.jornadaEditar){
                    respuesta = await editarJornadaUsuario(
                        this.datosEditarJornada.jornadaEditar.idJornadaLimpieza,
                        this.$moment(
                            this.datosEditarJornada.nuevaJornada.desde
                        ).format('HH:mm'),
                        this.$moment(
                            this.datosEditarJornada.nuevaJornada.hasta
                        ).format('HH:mm')
                    )
                }

                // Crear nueva jornada
                else {
                    respuesta = await crearJornadaUsuario(
                        this.indiceEdificioVerJornadas,
                        this.datosEditarJornada.usuario.id,
                        this.$moment(
                            this.datosEditarJornada.nuevaJornada.fecha
                        ).format('YYYY-MM-DD'),
                        this.$moment(
                            this.datosEditarJornada.nuevaJornada.desde
                        ).format('HH:mm'),
                        this.$moment(
                            this.datosEditarJornada.nuevaJornada.hasta
                        ).format('HH:mm')
                    )
                }

                this.procesarRespuesta(respuesta)

                alert(
                    this.datosEditarJornada.jornadaEditar ? 'Se editó la jornada' : 'Se creó la jornada'
                )

                this.modalCrearJornadaActivo = false
            } catch(e){
                alert(
                    this.datosEditarJornada.jornadaEditar ? 'Error editando jornada' : 'Error creando jornada'
                )

                console.error(e)
            }
        },
        /**
         * Configura y abre el modal para crear / editar una jornada
         */
        crearJornada(servicio, jornadaEditar, idUsuario){
            this.$set(
                this.datosEditarJornada,
                'servicio',
                servicio
            )

            if(jornadaEditar){
                jornadaEditar.idUsuario = idUsuario

                console.log(jornadaEditar)

                this.$set(
                    this.datosEditarJornada,
                    'jornadaEditar',
                    jornadaEditar
                )

                // hora desde
                this.$set(
                    this.datosEditarJornada.nuevaJornada,
                    'desde',
                    new Date(
                        Date.parse(
                            jornadaEditar.fecha + ' ' + jornadaEditar.entrada
                        )
                    )
                )

                // hora hasta
                if(jornadaEditar.salida){
                    this.$set(
                        this.datosEditarJornada.nuevaJornada,
                        'hasta',
                        new Date(
                            Date.parse(
                                jornadaEditar.fecha + ' ' + jornadaEditar.salida
                            )
                        )
                    )
                }

            } else if(this.datosEditarJornada.jornadaEditar) {
                this.$set(
                    this.datosEditarJornada,
                    'jornadaEditar',
                    null
                )
            }

            this.modalCrearJornadaActivo = true
        },
        /**
         * Exporta un excel con los datos recogidos
         */
        exportarExcel:exportarExcel,
        /**
         * Examina el tamaño del dispositivo
         */
        getDeviceSizeType:getDeviceSizeType,
        /**
         * Devuelve la etiqueta para lectura según el tipo de movimiento registrado
         */
        obtenerLabelTipoMovimiento(tipo){
            let labels = {
                almacen: 'almacén',
                lavanderia: 'lavandería',
                rechazo: 'rechazo',
                loft: 'loft'
            }

            return labels[tipo]
        },
        /**
         * Se actualizó un ítem de un loft
         */
        updateInventarioLoft(idLoft, datos){
            // refrescamos datos del loft en el store
            this.$store.dispatch('updateLoft', {
                indiceEdificio: this.indiceEdificioJornadaActual,
                loft: datos.loft
            })

            // refrescamos almacén y lavandería
            this.refrescarDatos(datos)
        },
        /**
         * Devuelve el progreso total de la jornada en horas, minutos
         */
        progresoTotalJornada(jornadaUsuario){
            let minutos = jornadaUsuario.progreso ? parseInt(jornadaUsuario.progreso) : 0,
            horas = 0

            if(minutos > 60){
                horas = parseInt(minutos / 60)

                let minutosRestar = horas * 60

                minutos = minutos - (minutosRestar)
            }

            let strProgresoTotalJornada = minutos + ' minutos'

            if(horas > 0){
                strProgresoTotalJornada = horas + ' horas, ' + strProgresoTotalJornada
            }

            return strProgresoTotalJornada
        },
        /**
         * Progreso total de un usuario
         */
        totalTiempoJornadaUsuario(jornadaUsuario){
            let totalMinutos = 0,
            totalHoras = 0,
            strTotalTiempoJornadaUsuario = ''

            for(let i in jornadaUsuario.jornadas){
                totalMinutos += parseInt(jornadaUsuario.jornadas[i].progreso.toFixed(2))
            }

            if(totalMinutos > 60){
                totalHoras = parseInt(totalMinutos / 60)

                totalMinutos = totalMinutos - (totalHoras * 60)

                let labelHora = totalHoras > 1 ? ' horas' : ' hora'

                strTotalTiempoJornadaUsuario = totalHoras + labelHora + ' y '
            }

            strTotalTiempoJornadaUsuario = strTotalTiempoJornadaUsuario.concat(totalMinutos + ' minutos')

            return strTotalTiempoJornadaUsuario
        },
        /**
         * Procesa las jornadas de los lofts
         */
        procesarJornadasLofs(){
            this.jornadasLofts = {}

            for(let indiceJornadaLoft in this.jornadasHoy.lofts){
                let jornadaLoft = {
                    usuarios: this.usuariosLoft(this.jornadasHoy.lofts[indiceJornadaLoft].idLoft, true),
                    terminado: true
                }

                for(let idUsuario in jornadaLoft.usuarios){
                    if(!jornadaLoft.usuarios[idUsuario].terminado){
                        jornadaLoft.terminado = false
                    }
                }

                this.$set(
                    this.jornadasLofts,
                    this.jornadasHoy.lofts[indiceJornadaLoft].idLoft,
                    jornadaLoft
                )
            }
        },
        /**
         * Procesa las jornadas de los usuarios
         */
        procesarJornadasUsuarios(){
            this.paginasFechasJornadas = {}
            this.jornadasUsuarios = {}
            let contadorFechas = 0

            if(this.hayJornadas){
                // recorremos edificios
                for(let indiceJornada in this.jornadasHoy.edificios){
                    let indiceEdificio = this.jornadasHoy.edificios[indiceJornada].idEdificio

                    // agrupamos por fecha
                    if(typeof this.paginasFechasJornadas[this.jornadasHoy.edificios[indiceJornada].fecha] === 'undefined'){
                        this.paginasFechasJornadas[this.jornadasHoy.edificios[indiceJornada].fecha] = true

                        // activamos la primera fecha por defecto
                        contadorFechas ++
                        if(contadorFechas === 1){
                            this.paginaFechaActual = this.jornadasHoy.edificios[indiceJornada].fecha
                        }
                    }

                    // agrupamos por edificio
                    if(typeof this.jornadasUsuarios[indiceEdificio] === 'undefined'){
                        this.$set(this.jornadasUsuarios, indiceEdificio, {})
                    }
                    
                    let idUsuario = this.jornadasHoy.edificios[indiceJornada].idUsuario

                    // nuevo usuario jornada
                    if(typeof this.jornadasUsuarios[indiceEdificio][idUsuario] === 'undefined'){
                        this.$set(
                            this.jornadasUsuarios[indiceEdificio],
                            idUsuario,
                            {
                                jornadas: [],
                                lofts: {}
                            }
                        )
                    }

                    this.jornadasUsuarios[indiceEdificio][idUsuario].jornadas.push({
                        idUsuario: idUsuario,
                        idJornadaLimpieza: this.jornadasHoy.edificios[indiceJornada].idJornadaLimpieza,
                        fecha: this.jornadasHoy.edificios[indiceJornada].fecha,
                        entrada: this.jornadasHoy.edificios[indiceJornada].horaDesde,
                        salida: this.jornadasHoy.edificios[indiceJornada].horaHasta,
                        progreso: this.jornadasHoy.edificios[indiceJornada].progresoJornada,
                        enProgreso: this.jornadasHoy.edificios[indiceJornada].enProgreso
                    })
                }

                // recorremos lofts
                for(let indiceJornadaLoft in this.jornadasHoy.lofts){
                    let idUsuario = this.jornadasHoy.lofts[indiceJornadaLoft].idUsuario,
                    idEdificio = this.jornadasHoy.lofts[indiceJornadaLoft].idEdificio,
                    idLoft = this.jornadasHoy.lofts[indiceJornadaLoft].idLoft

                    // comienzo loft
                    this.$set(
                        this.jornadasUsuarios[idEdificio][idUsuario].lofts,
                        idLoft,
                        {
                            idUsuario: idUsuario,
                            idJornadaLimpieza: this.jornadasHoy.lofts[indiceJornadaLoft].idJornadaLimpieza,
                            idJornadaLimpiezaLoft: this.jornadasHoy.lofts[indiceJornadaLoft].idJornadaLimpiezaLoft,
                            inicio: this.jornadasHoy.lofts[indiceJornadaLoft].horaDesdeLoft,
                            fin: this.jornadasHoy.lofts[indiceJornadaLoft].horaHastaLoft,
                            progreso: this.jornadasHoy.lofts[indiceJornadaLoft].progreso,
                            enProgreso: this.jornadasHoy.lofts[indiceJornadaLoft].enProgreso
                        }
                    )
                }
            }
        },
        /**
         * Indica si un usuario ha terminado cierto loft
         */
        loftTerminadoPorUsuario(idLoft, idUsuario){
            if(typeof this.jornadasLofts[idLoft] === 'object' && typeof this.jornadasLofts[idLoft].usuarios[idUsuario] === 'object'){
                return this.jornadasLofts[idLoft].usuarios[idUsuario].terminado
            }

            return false
        },
        /**
         * Indica si un loft está terminado
         */
        loftTerminado(idLoft){
            if(typeof this.jornadasLofts[idLoft] === 'object'){
                return this.jornadasLofts[idLoft].terminado
            }

            return false
        },
        /**
         * Progreso del loft
         */
        progresoLoft(idLoft){
            for(let indiceJornadaLoft in this.jornadasHoy.lofts){
                if(this.jornadasHoy.lofts[indiceJornadaLoft].idLoft == idLoft){
                    return parseFloat(
                        this.jornadasHoy.lofts[indiceJornadaLoft].progreso
                    ).toFixed(0)
                }
            }

            return null
        },
        /**
         * Quien dio por finalizado el loft
         */
        usuariosLoft(idLoft, forzar){
            if(typeof this.jornadasLofts[idLoft] === 'object' && !forzar){
                return this.jornadasLofts[idLoft].usuarios
            }

            let usuariosLoft = {}

            for(let idEdificio in this.jornadasUsuarios){
                for(let idUsuario in this.jornadasUsuarios[idEdificio]){
                    for(let idLoftJornadaUsuario in this.jornadasUsuarios[idEdificio][idUsuario].lofts){
                        if(idLoftJornadaUsuario == idLoft){
                            usuariosLoft[idUsuario] = {
                                progreso: parseInt(this.jornadasUsuarios[idEdificio][idUsuario].lofts[idLoftJornadaUsuario].progreso),
                                terminado: typeof this.jornadasUsuarios[idEdificio][idUsuario].lofts[idLoftJornadaUsuario].fin === 'string'
                            }
                        }
                    }
                }
            }

            return usuariosLoft
        },
        /**
         * Termina el progreso en el loft actual
         */
        async terminarJornadaLoft(){
            let copiaIdLoftTerminar = this.miJornada.idLoft

            try {
                let respuesta = await terminarJornadaLoft()

                this.procesarRespuesta(respuesta)

                alert('Progreso en loft ' + this.lofts[this.miJornada.indiceEdificio][copiaIdLoftTerminar].nombre + ' terminado')

                this.modalConfirmarFinJornadaLoftActivo = false
                this.$set(
                    this.miJornada,
                    'idLoft',
                    null
                )
            } catch(e){
                console.error('Error terminando progreso loft', e)

                alert('Error terminando progreso loft: ' + e.message)

                this.modalConfirmarFinJornadaLoftActivo = false
            }
        },
        /**
         * Inicia el progreso de un loft
         */
        async iniciarJornadaLoft(){
            try {
                let respuesta = await iniciarJornadaLoft(
                    this.miJornada.indiceEdificio,
                    this.loftIniciarJornada.id
                )

                this.procesarRespuesta(respuesta)

                alert('Progreso loft "' + this.lofts[this.miJornada.indiceEdificio][this.loftIniciarJornada.id].nombre + '" iniciado')

                // establecemos valores loft iniciado
                this.idLoftActivoCollapse = this.loftIniciarJornada.id
                this.$set(
                    this.miJornada,
                    'idLoft',
                    this.loftIniciarJornada.id
                )

                // restablecemos valores
                this.modalConfirmarInicioJornadaLoftActivo = false
                this.idLoftIniciarJornada = null
            } catch(e){
                console.error('Error iniciando jornada loft:', e)

                alert('Error iniciando jornada loft: ' + e.message)

                // restablecemos valores
                this.modalConfirmarInicioJornadaLoftActivo = false
                this.idLoftIniciarJornada = null
            }
        },
        /**
         * Finaliza la jornada
         */
        async finalizarJornada(){
            try {
                let respuesta = await finalizarJornada()

                this.procesarRespuesta(respuesta)

                this.miJornada = null

                // restablecemos valores
                this.modalConfirmarFinJornadaActivo = false

                alert('Has dado por finalizada la jornada')
            } catch(e){
                console.error('Error finalizando jornada:', e)

                alert('Error finalizandp jornada: ' + e.message)

                // restablecemos valores
                this.modalConfirmarFinJornadaActivo = false
            }
        },
        /**
         * Inicia la jornada
         */
        async iniciarJornada(){
            try {
                let respuesta = await iniciarJornada(
                    this.indiceEdificioIniciarJornada
                )

                this.procesarRespuesta(respuesta)

                // restablecemos valores
                this.modalConfirmarInicioJornadaActivo = false
                this.indiceEdificioIniciarJornada = null
            } catch(e){
                console.error('Error iniciando jornada:', e)

                alert('Error iniciando jornada: ' + e.message)

                // restablecemos valores
                this.modalConfirmarInicioJornadaActivo = false
                this.indiceEdificioIniciarJornada = null
            }
        },
        /**
         * Refresca las jornadas de hoy
         */
        async cargar(){
            try {
                let respuesta = await obtenerJornadasHoy()

                this.procesarRespuesta(respuesta)
            } catch(e){
                console.error('Error obteniendo jornadas de limpieza:', e)

                alert('Error obteniendo jornadas de limpieza:' + e.message)

                if(e.message.indexOf('Sesión no encontrada') !== -1){
                    this.$router.push('/login-portal-bunabil')
                }
            }
        },
        /**
         * Procesa la respuesta del servidor cuando interactuamos con las jornadas
         */
        procesarRespuesta(respuesta){
            if(typeof respuesta.data === 'undefined' || !respuesta.data){
                let error = typeof respuesta.error === 'string' ? respuesta.error : 'Respuesta malformada'

                throw new Error(error)
            }

            // refrescamos datos
            this.refrescarDatos(respuesta.data)
        },
        /**
         * Refresca los datos del componente
         */
        refrescarDatos(datos){
            // refrescamos las jornadas de hoy
            if(typeof datos.jornadasHoy === 'object' && datos.jornadasHoy){
               this.jornadasHoy = datos.jornadasHoy

               this.procesarJornadasUsuarios()
               this.procesarJornadasLofs()
            }

            // refrescamos nuestra jornada
            if(typeof datos.miJornada === 'object' && datos.miJornada){
                this.miJornada = datos.miJornada

                // selector autoseleccionado
                if(typeof this.miJornada.indiceEdificio !== 'undefined'){
                    this.indiceEdificioVerJornadas = this.miJornada.indiceEdificio
                }

                // si estamos en un loft, lo desplegamos directamente
                if(typeof this.miJornada.idLoft === 'string'){
                    this.irALoft(this.miJornada.idLoft)
                }
            }

            // almacén
            if(typeof datos.almacen === 'object' && datos.almacen){
                this.$set(this.miJornada, 'almacen', datos.almacen)
            }

            // lavandería
            if(typeof datos.lavanderia === 'object' && datos.lavanderia){
                this.$set(this.miJornada, 'lavanderia', datos.lavanderia)
            }

            // rechazo lavandería
            if(typeof datos.rechazo === 'object' && datos.rechazo){
                this.$set(this.miJornada, 'rechazo', datos.rechazo)
            }
        },
        /**
         * Despliega y hace scroll al loft
         */
        irALoft(idLoft){
            this.idLoftActivoCollapse = idLoft

            setTimeout(() => {
                document.getElementById('caja_loft_' + idLoft).scrollIntoView({
                    behavior:'smooth',
                    block:'center'
                })
            }, 500)
        }
    }
}
</script>

<style scoped>
.table__wrapper {
  overflow-x: auto;
}
</style>