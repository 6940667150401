/**
 * Bunabil
 */
import {call} from './bunabil.js'

/**
 * Actualizar los items del almacén de un edificio
 * @param {Number} indiceEdificio
 * @param {Object} items
 */
export const actualizarItemsAlmacenEdificio = async (indiceEdificio, items) => {
    try {
        return await call('actualizarItemsAlmacenEdificio', {
            indiceEdificio: indiceEdificio,
            items: items
        })
    } catch(e){
        throw new Error(e)
    }
}

/**
 * Devuelve los almacenes de los edificios en el servidor
 */
 export const obtenerAlmacenes = async () => {
    try {
        return await call('obtenerAlmacenes')
    } catch(e){
        throw new Error(e)
    }
}
