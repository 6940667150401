<template>
    <Inventario
    ref="inventarioLoft"
    :items="loft.almacen"
    @confirmarCambios="actualizarItemsLoft($event)" />
</template>

<script>

/**
 * Inventario
 */
import Inventario from './Inventario.vue'

/**
 * Manejar iventario lofts
 */
import {actualizarItemsLoft} from '../services/lofts.js'

export default {
    name:'InventarioLoft',
    components:{
        Inventario
    },
    props:{
        loft:null
    },
    methods:{
        async actualizarItemsLoft(cambiosItemsLoft){
            try {
                let respuesta = await actualizarItemsLoft(
                    this.loft.id,
                    cambiosItemsLoft
                )

                if(typeof respuesta.status !== 'string' || respuesta.status !== 'OK' || typeof respuesta.data !== 'object'){
                    let error = typeof respuesta.error === 'string' ? respuesta.error : 'Error leyendo respuesta'

                    throw new Error(error)
                }

                alert('Almacén Loft ' + this.loft.nombre + ' actualizado')

                this.$emit('update', respuesta.data)

                this.$refs.inventarioLoft.cambiosConfirmados()
            } catch(e){
                alert('Error actualizando items loft: ' + e.message)

                console.error('Error actualizando items loft:', e)
            }
        }
    }
}
</script>