<template>
    <div class="columns">
        <div class="column">
            <h2 class="subtitle">Editor</h2>
            <wysiwyg v-model="html" class="has-background-white" />
        </div>
        <div v-if="usarCajaPlantilla" class="column">
            <h2 class="subtitle">Vista previa</h2>
            <div class="card" :style="estilosCaja">
                <div class="card-content">
                    <h1 class="title has-text-centered" :style="{color: estilosCaja.color}">
                        <span class="has-text-weight-light">Canary</span>Lofts
                    </h1>

                    <div class="px-6" v-html="html"></div>
                </div>
                <div class="card-footer">
                    <div
                    class="card-footer-item">
                        <a :style="{color: estilosCaja.color + ' !important'}">
                            Política de privacidad
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EditorWysiwyg',
    data(){
        return {
            html: '',

            enlaces: [],

            usarCajaPlantilla: true,

            estilosCaja:{
                backgroundColor: '#72664F',
                color: '#F4EEE7'
            }
        }
    },
    methods:{
        getHTML(){
            return this.html
        },
        setHTML(html){
            this.html = html
        },
        agregarEnlace(){
            this.enlaces.push({
                nombre: '',
                href: ''
            })
        },
        quitarEnlace(){
            this.enlaces.slice(-1)
        }
    }
}
</script>

<style scoped>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>