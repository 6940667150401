import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    /** CanaryLofts */
    idioma: 'es',
    idiomasDisponibles: {
      es: 'Español',
      en: 'English'
    },

    /** Bunabil */
    /**
     * Si el usuario está logueado
     * @var {Boolean}
     */
     logueado:false,

     /**
      * Si el usuario logueado es administrador
      * @var {Boolean}
      */
     esAdministrador:false,
 
     /**
      * Datos del usuario logueado
      * @var {Object}
      */
     usuarioLogueado:null,
 
     /**
      * Diccionario de usuarios
      * @var {Object}
      */
     usuarios:{},
 
     /**
      * Diccionario de edificios
      * @var {Object}
      */
     edificios:{},
 
     /**
      * Diccionario de lofts
      * @var {Object}
      */
     lofts:{},
 
     /**
      * Diccionario de ítems del inventario
      * @var {Object}
      */
     inventario:{},
 
     /**
      * Almacenes de los edificios
      * @var {Object}
      */
     almacenes:{},
 
     /**
      * Lavanderías de los edificios
      * @var {Object}
      */
     lavanderias:{},
  },
  mutations: {
    /** CanaryLofts */

    /**
     * Establece el idioma de la web
     * @param {*} state 
     * @param {*} idioma 
     */
    setIdioma(state, idioma){
      state.idioma = idioma
    },

    /** Bunabil */
    /**
     * Destruye al usuario logueado
     * @param {Vuex.store.state} state 
     */
     logout(state){
      state.usuarioLogueado = null
      state.logueado = false
      state.esAdministrador = false
    },
    /**
     * Establece todos los datos de sesión
     * @param {Vuex.store.state} state 
     * @param {Object} datosSesion 
     */
    setDatosSesion(state,  datosSesion){
      state.usuarioLogueado = datosSesion.usuario
      state.logueado = true
      state.esAdministrador = datosSesion.usuario.rol == 0
      state.usuarios = datosSesion.usuarios
      state.edificios = datosSesion.edificios
      state.lofts = datosSesion.lofts
      state.inventario = datosSesion.inventario
    },
    /**
     * Actualiza el diccionario de usuarios
     * @param {Vuex.store.state} state 
     * @param {Object} usuarios 
     */
    setUsuarios(state, usuarios){
      state.usuarios = usuarios
    },
    /**
     * Actualiza un loft
     * @param {Vuex.store.state} state 
     * @param {Object} datosLoft 
     */
    setLoft(state, datosLoft){
      state.lofts[datosLoft.indiceEdificio][datosLoft.loft.id] = datosLoft.loft
    },
    /**
     * Actualiza todos los lofts
     * @param {Vuex.store.state} state 
     * @param {Object} lofts 
     */
    setLofts(state, lofts){
      state.lofts = lofts
    },
    /**
     * Actualiza el inventario
     * @param {Vuex.store.state} state 
     * @param {Object} inventario 
     */
    setInventario(state, inventario){
      state.inventario = inventario
    }
  },
  actions: {
    /**
     * Establece el idioma de la web
     * @param {*} store 
     * @param {*} idioma 
     */
    setIdioma(store, idioma){
      store.commit('setIdioma', idioma)
    },

    /** Bunabil */

    /**
     * Informa al store que se actualizó el inventario
     * @param {Vuex} store 
     * @param {Object} inventario 
     */
     updateInventario(store, inventario){
      store.commit('setInventario', inventario)
    },
    /**
     * Informa al store que se actualizó un loft
     * @param {Vuex} store 
     * @param {Object} datosLoft 
     */
    updateLoft(store, datosLoft){
      store.commit('setLoft', datosLoft)
    },
    /**
     * Informa al store que se actualizaron los lofts
     * @param {Vuex} store 
     * @param {Object} lofts 
     */
    updateLofts(store, lofts){
      store.commit('setLofts', lofts)
    },
    /**
     * Informa al store que se actualizaron los usuarios
     * @param {Vuex} store
     * @param {Object} usuarios 
     */
    updateUsuarios(store, usuarios){
      store.commit('setUsuarios', usuarios)
    },
    /**
     * Informa al store que se ha logueado un usuario
     * @param {Vuex} store 
     * @param {Object} datosSesion 
     */
    login(store, datosSesion){
      store.commit('setDatosSesion', datosSesion)
    },
    /**
     * Informa al store que se ha deslogueado el usuario
     * @param {Vuex} store
     */
    logout(store){
      store.commit('logout')
    }
  },
  modules: {
  }
})
