<template>
    <CanaryLoft
    googleMapsSrc="https://www.google.es/maps/place/Calle+Dr.+Jose+Naveiras,+13,+38004+Santa+Cruz+de+Tenerife/"
    :geolocalizacion="{
        lat: 28.4746956,
        lng: -16.2540257
    }"
    nombre="Canary Lofts Palace"
    imagenPortada="./images/canary-loft-palace/portada.jpg"
    carpetaImagenesCarousel="canary-loft-palace/carousel/"
    :nImagenesCarousel="32"
    :indiceEdificioPredeterminado="1"
    :direccion="direccion"
    :servicios="servicios"
    :comentariosClientes="comentariosClientes"
    :habitaciones="habitaciones"
    :textosDescripcion="textosDescripcion" />
</template>

<script>

/**
 * Perfil edificio Canary Loft
 */
import CanaryLoft from '../components/CanaryLoft.vue'

export default {
    name: 'CanaryLoftPalace',
    data(){
        return {
            portada: 'https://www.barcelo.com/pinandtravel/wp-content/uploads/2018/06/1-12.jpg',

            direccion: 'Calle Doctor Jose Naveiras 13, Santa Cruz de Tenerife, 38001, Santa Cruz de Tenerife, España',

            textosDescripcion:[
                'Este edificio es el más céntrico de nuestros alojamientos. Se encuentra en el corazón de Santa Cruz a 2 minutos andando del Parque García Sanabria y de la principal zona comercial del centro',
                'Además, si te decides por este edificio,podrás disfrutar  de nuestra terraza comunitaria siempre que quieras y contemplar las vistas de la ciudad. También llega la señal wifi gratuita por lo que muchos de nuestros huéspedes aprovechan para trabajar o simplemente pasar el rato conectados.Todos los alojamientos en este edificio cuentan con TV de pantalla plana vía satélite, baño privado y cocina totalmente equipada con microondas. Algunos alojamientos tienen aire acondicionado, patio y/o balcón y zona de estar. Además en este edificio tenemos la posibilidad de reservar parking por 6€ la noche siempre que esté disponible'
            ],

            servicios: [{
                nombre: 'Parking',
                icon: 'parking'
            },{
                nombre: 'Cocina equipada',
                icon: 'utensils'
            },{
                nombre: 'Wi-Fi gratuito',
                icon: 'wifi'
            },{
                nombre: 'Baño privado',
                icon: 'shower'
            },{
                nombre: 'Permitido niños',
                icon: 'children'
            },{
                nombre: 'Terraza comunitaria',
                icon: 'tree'
            },{
                nombre: 'Aceptadas tarjetas de crédito',
                icon: 'credit-card'
            },{
                nombre: 'Habitaciones familiares',
                icon: 'people-group'
            },{
                nombre: 'No fumadores',
                icon: 'smoking-ban'
            },{
                nombre: 'Fiestas no permitidas',
                icon: 'champagne-glasses',
                banned: true
            }],

            fotos: [],

            habitaciones: [{
                nombre: 'Apartamento 1 dormitorio',
                foto: './images/canary-loft-palace/room_0.jpg',
                descripcion: 'Amplio y luminoso apartamento de aproximadamente 50m2, cuenta con un dormitorio doble, es decir, la habitación dispone de dos camas juntas de 90 cm cada una. Una luminosa sala de estar con sofá cama, una amplia cocina y un baño con bañera. Uno de los principales atractivos de Canary Lofts Palace se encuentra en la tercera planta, nuestro solárium donde podrá relajarse durante su estancia',
                nImagenesCarousel: 18
            },{
                nombre: 'Apartamento 2 dormitorios',
                foto: './images/canary-loft-palace/room_1.jpg',
                descripcion: 'Apartamento con dos dormitorios dobles, con una luminosa sala de estar con sofá cama, un baño con bañera y una amplia cocina dando a un pequeño patio interior. Las habitaciones disponen de dos camas juntas de 90 cm cada una',
                nImagenesCarousel: 9
            },{
                nombre: 'Estudio con terraza vistas al mar',
                foto: './images/canary-loft-palace/room_2.jpg',
                descripcion: 'Nuestro ático es un estudio de unos 25 m2, es pequeño pero coqueto, con una amplia y soleada terraza privada con vistas al mar y a todo Santa Cruz. Está distribuido en un acogedor y luminoso espacio abierto con dos camas juntas de 90 cm cada una, una cocina americana y un baño con ducha abierta',
                nImagenesCarousel: 7
            }],

            comentariosClientes: [
                {
                    comentario: 'La limpieza del piso, la ubicación, el trato de Lorena, la facilidad para entrar y salí a cualquier hora y las instalaciones en general.',
                    valoracion: 5,
                    nombre: 'Daniel'
                }
            ]
        }
    },
    components:{
        CanaryLoft
    }
}
</script>