<template>
    <div>
        <div class="level">
            <div class="container">
                <b-field>
                    <b-switch v-model="mostrarSoloActivos" type="is-primary">
                        Mostrar sólo lo que hay
                    </b-switch>
                </b-field>
            </div>
            <div class="container">
                <b-button
                v-if="hayCambiosRealizados"
                expanded
                icon-left="save"
                @click="mostrarModalConfirmarCambios = true"
                type="is-primary">
                    Confirmar cambios
                </b-button>
            </div>
        </div>
        <div class="columns">
            <div
            class="column is-one-fifth"
            v-for="(item,indiceItem) in itemsInventario"
            :key="indiceItem">
                <div class="card has-background-primary has-text-secondary">
                    <div
                    @click="modalItem.show = true; modalItem.item = item"
                    class="card-image is-clickable">
                        <figure class="image is-4by3 m-0">
                            <img
                            :src="item.imagen ? item.imagen : 'https://bulma.io/images/placeholders/1280x960.png'"
                            alt="Cargando imagen...">
                        </figure>
                    </div>
                    <div class="card-header">
                        <p class="card-header-title has-text-secondary">
                            {{item.nombre}}
                        </p>
                    </div>
                    <footer class="card-footer">
                        <a
                        @click="agregar(item.id)"
                        type="is-info" class="card-footer-item has-text-secondary">
                            Agregar
                        </a>
                        <b class="card-footer-item has-background-secondary has-text-primary">
                            <span v-if="itemCambiado(item.id)">
                                {{cambiosRealizados[item.id]}}
                            </span>
                            <span v-else>
                                {{estaItem(item.id) ? items[item.id] : 0}}
                            </span>
                        </b>
                        <a
                        @click="quitar(item.id)"
                        class="card-footer-item has-text-secondary">
                            Quitar
                        </a>
                    </footer>
                    <footer class="card-footer">
                        <a
                        @click="itemRechazar = item; modalRechazarItemActivo = true"
                        type="is-info" class="card-footer-item has-text-secondary">
                            Rechazar
                        </a>
                    </footer>
                </div>
            </div>
        </div>

        <!-- Confirmar cambios -->
        <b-modal v-model="mostrarModalConfirmarCambios">
            <b-notification :closable="false" type="is-info">
                <h1 class="subtitle">
                    ¿Confirmar los cambios?
                </h1>

                <ul>
                    <li
                    class="mb-1"
                    v-for="(nuevoValor,idItem) in cambiosFinales"
                    :key="'cambio-' + idItem">
                        <b class="mr-1">{{inventario[idItem].nombre}}</b>
                        <b-tag type="is-danger" size="is-medium">antes <b>{{estaItem(idItem) ? items[idItem] : 0}}</b></b-tag>
                        <b-icon icon="arrow-right" size="is-small" class="mx-1"></b-icon>
                        <b-tag type="is-success" size="is-medium">ahora <b>{{cambiosFinales[idItem]}}</b></b-tag>
                    </li>
                </ul>

                <div class="buttons is-right">
                    <b-button
                    @click="mostrarModalConfirmarCambios = false" type="is-danger">
                        Cancelar
                    </b-button>
                    <b-button
                    :disabled="confirmandoCambios"
                    @click="confirmandoCambios = true; $emit('confirmarCambios', cambiosFinales)" type="is-info" inverted outlined>
                        {{confirmandoCambios ? 'Confirmando...' : 'Confirmar'}}
                    </b-button>
                </div>
            </b-notification>
        </b-modal>

        <!-- Ver item -->
        <b-modal v-model="modalItem.show">
            <div class="card">
                <div class="card-image">
                    <figure v-if="modalItem.item" class="image is-4by3 m-0">
                        <img
                        :src="modalItem.item.imagen ? modalItem.item.imagen : 'https://bulma.io/images/placeholders/1280x960.png'"
                        alt="Cargando imagen...">
                    </figure>
                </div>
                <div class="card-header">
                    <p v-if="modalItem.item" class="card-header-title">
                        {{modalItem.item.nombre}}
                    </p>
                </div>
            </div>
        </b-modal>

        <!--Rechazar item-->
        <b-modal v-model="modalRechazarItemActivo">
            <b-notification :closable="false" type="is-info">
                <h2 v-if="itemRechazar" class="subtitle">Enviar {{itemRechazar.nombre}} a rechazo</h2>

                <b-field label="¿Cuántas unidades quieres rechazar?" custom-class="has-text-white" type="is-light">
                    <b-input
                    placeholder="Unidades"
                    v-model="unidadesRechazar"></b-input>
                </b-field>

                <div class="buttons is-right">
                    <b-button @click="modalRechazarItemActivo = false" type="is-danger">
                        Cancelar
                    </b-button>
                    <b-button
                    @click="rechazarItems"
                    type="is-info" outlined inverted>
                        Confirmar
                    </b-button>
                </div>
            </b-notification>
        </b-modal>
    </div>
</template>

<script>
export default {
    data(){
        return {
            mostrarSoloActivos:true,
            vertical:false,
            cambiosRealizados:{},
            mostrarModalConfirmarCambios:false,
            confirmandoCambios:false,
            modalItem:{
                show:false,
                item:null
            },
            confirmandoRechazos:{},

            // rechazar item
            modalRechazarItemActivo: false,
            itemRechazar: null,
            unidadesRechazar: 1
        }
    },
    watch:{
        mostrarModalConfirmarCambios(mostrar){
            if(mostrar){
                this.confirmandoCambios = false
            }
        }
    },
    computed:{
        hayCambiosRealizados(){
            for(let idItem in this.cambiosFinales){
                return true
            }

            return false
        },
        cambiosFinales(){
            let cambiosFinales = {}

            for(let idItem in this.cambiosRealizados){
                let estaItemActual = this.estaItem(idItem)

                // si es el mismo valor, descartamos el cambio
                if(estaItemActual !== 'undefined' && this.cambiosRealizados[idItem] == this.items[idItem]){
                    continue
                }

                // si es cero y no había de ese ítem inicialmente
                else if(!estaItemActual && this.cambiosRealizados[idItem] == 0){
                    continue
                }

                cambiosFinales[idItem] = this.cambiosRealizados[idItem]
            }

            return cambiosFinales
        },
        itemsInventario(){
            if(!this.hayItems || !this.mostrarSoloActivos){
                return this.inventario
            }

            let itemsInventario = {}

            for(let idItem in this.inventario){
                if(this.estaItem(idItem) || typeof this.cambiosRealizados[idItem] !== 'undefined'){
                    itemsInventario[idItem] = this.inventario[idItem]
                }
            }

            return itemsInventario
        },
        hayItems(){
            if(this.items){
                for(let i in this.items){
                    return true
                }
            }

            return false
        },
        inventario(){
            return this.$store.state.inventario
        }
    },
    props:['items'],
    methods:{
        rechazarItems(){
            this.$emit('rechazar', {
                idItem: this.itemRechazar.id,
                unidades: this.unidadesRechazar
            })
        },
        estaItem(idItem){
            if(!this.hayItems){
                return false
            }

            return typeof this.items[idItem] !== 'undefined'
        },
        itemCambiado(idItem){
            return typeof this.cambiosRealizados[idItem] !== 'undefined'
        },
        cambiosConfirmados(){
            this.mostrarSoloActivos = true
            this.mostrarModalConfirmarCambios = false
            this.confirmandoCambios = false
            this.cambiosRealizados = {}
        },
        agregar(idItem){
            // valor original del servidor
            let valorAnterior = this.estaItem(idItem) ? parseInt(this.items[idItem]) : 0

            // último valor cambio realizado
            if(typeof this.cambiosRealizados[idItem] !== 'undefined'){
                valorAnterior = parseInt(this.cambiosRealizados[idItem])
            }

            this.$set(
                this.cambiosRealizados,
                idItem,
                valorAnterior + 1
            )
        },
        quitar(idItem){
            // valor original del servidor
            let valorAnterior = this.estaItem(idItem) ? parseInt(this.items[idItem]) : 0

            // último valor cambio realizado
            if(typeof this.cambiosRealizados[idItem] !== 'undefined'){
                valorAnterior = parseInt(this.cambiosRealizados[idItem])
            }

            let nuevoValor = valorAnterior - 1

            if(nuevoValor < 0){
                nuevoValor = 0
            }

            this.$set(
                this.cambiosRealizados,
                idItem,
                nuevoValor
            )
        }
    }
}
</script>
