import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// MomentJS
Vue.use(require('vue-moment'))

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleLeft, faFileExcel, faImage, faBuilding, faPlusCircle, faTimes, faTrash, faDog, faStar, faSlash, faSave, faPowerOff, faAngleRight, faClock, faChampagneGlasses, faExclamationCircle, faQuoteLeft, faShower, faBookBookmark, faFireBurner, faToolbox, faTree, faQuoteRight, faPeopleGroup, faParking, faSmokingBan, faBan, faWifi, faCreditCard, faChildren, faPersonCane, faWheelchair, faFileImage, faCalendar, faUser, faTimesCircle, faChild, faPhone, faKey, faSearch, faLocationDot, faSignsPost, faHouse, faCheckCircle, faQuestionCircle, faArrowRight, faChevronLeft, faChevronRight, faChevronDown, faChevronUp, faUtensils, faList, faBoxOpen, faDoorOpen, faDoorClosed, faSoap, faPencil, faUsers, faBath, faPaperPlane, faCog, faFilter } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

library.add(faAngleLeft, faFileExcel, faImage, faBuilding, faPlusCircle, faTimes, faTrash, faDog, faStar, faSlash, faSave, faPowerOff, faAngleRight, faClock, faChampagneGlasses, faExclamationCircle, faQuoteLeft, faShower, faBookBookmark, faFireBurner, faToolbox, faTree, faQuoteRight, faPeopleGroup, faParking, faSmokingBan, faBan, faWifi, faCreditCard, faChildren, faPersonCane, faWheelchair, faFileImage, faCalendar, faUser, faTimesCircle, faChild, faPhone, faKey, faSearch, faLocationDot, faSignsPost, faHouse, faCheckCircle, faQuestionCircle, faArrowRight, faChevronLeft, faChevronRight, faChevronDown, faChevronUp, faUtensils, faList, faBoxOpen, faDoorOpen, faDoorClosed, faSoap, faPencil, faUsers, faBath, faPaperPlane, faCog, faFilter, faFacebook, faInstagram)
Vue.component('vue-fontawesome', FontAwesomeIcon)
Vue.component('vue-fontawesome-layers', FontAwesomeLayers)

// Estilos personalizados
require('./assets/main.scss')

// Buefy
import { ConfigProgrammatic, Button, Field, Input, Datepicker, Timepicker, Navbar, Icon, Tag, Modal, Tabs, Collapse, Loading, Autocomplete, Carousel, Image, Tooltip, Dropdown, Notification, Steps, Switch, Select, ToastProgrammatic } from 'buefy'
import 'buefy/dist/buefy.css'

Vue.use(Button)
Vue.use(Input)
Vue.use(Navbar)
Vue.use(Field)
Vue.use(Datepicker)
Vue.use(Timepicker)
Vue.use(Icon)
Vue.use(Tag)
Vue.use(Modal)
Vue.use(Tabs)
Vue.use(Collapse)
Vue.use(Loading)
Vue.use(Autocomplete)
Vue.use(Carousel)
Vue.use(Image)
Vue.use(Tooltip)
Vue.use(Dropdown)
Vue.use(Notification)
Vue.use(Steps)
Vue.use(Switch)
Vue.use(Select)
Vue.use(ToastProgrammatic)

ConfigProgrammatic.setOptions({
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas'
})

// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBhFwZk_IJwNupE99UhprS1Z2MA7xMXOLM',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

/**
 * Wysiwyg
 */
import wysiwyg from 'vue-wysiwyg'

Vue.use(wysiwyg, {})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
