<template>
    <div class="content has-text-centered">
        <a href="tel:+34 922 099 046" :class="{'has-text-white': hasTextWhite}">+34 922 099 046 </a>
        <b-tag type="is-primary">/</b-tag>
        <a href="tel:+34 922 099 047" :class="{'has-text-white': hasTextWhite}"> +34 922 099 047</a>
        <br>
        <a href="mailto:reservas@canarylofts.com" :class="{'has-text-white': hasTextWhite}">reservas@canarylofts.com</a>
    </div>
</template>

<script>
export default {
    name: 'DatosContacto',
    props:{
        hasTextWhite:{
            type: Boolean,
            default: true
        }
    }
}
</script>