<template>
    <section class="section">
        <div class="container">
            <b-field label="Cargar plantillas">
                <b-select
                v-model="plantillaSeleccionada"
                @input="cambioPlantilla()"
                expanded size="is-large">
                    <option
                    v-for="(plantilla,indicePlantilla) in plantillas"
                    :key="indicePlantilla" :value="indicePlantilla">
                        {{plantilla.nombre}}
                    </option>
                </b-select>
            </b-field>
            
            <EditorWysiwyg ref="editorWysiwyg" />

            <div class="buttons is-centered">
                <b-button @click="modalCrearPlantilla = true" icon-left="save" type="is-primary" size="is-large">
                    Guardar como plantilla
                </b-button>
                <b-button icon-left="paper-plane" @click="modalEmitir = true" type="is-primary" size="is-large">
                    Emitir a suscriptores
                </b-button>
            </div>

            <!--Modal guardar plantilla-->
            <b-modal :active.sync="modalCrearPlantilla">
                <b-notification type="is-info" :closable="false">
                    <h1 class="title">
                        ¿Guardar plantilla?
                    </h1>

                    <h2 class="subtitle">
                        Se guardará un registro en la base de datos
                    </h2>

                    <b-field label="Nombre nueva plantilla" type="is-light" custom-class="has-text-white">
                        <b-input v-model="nombreNuevaPlantilla" placeholder="Nombre nueva plantilla"></b-input>
                    </b-field>

                    <div class="buttons is-right">
                        <b-button @click="modalCrearPlantilla = false" type="is-danger">
                            Cancelar
                        </b-button>

                        <b-button :disabled="nombreNuevaPlantilla.length < 3" @click="guardarPlantilla()" type="is-info" inverted outlined>
                            Confirmar
                        </b-button>
                    </div>
                </b-notification>
            </b-modal>

            <!--Modal emitir correos-->
            <b-modal :active.sync="modalEmitir">
                <b-notification type="is-info" :closable="false">
                    <h1 class="title">
                        ¿Emitir plantilla?
                    </h1>

                    <h2 class="subtitle">
                        Se enviará un correo a todos los clientes suscritos
                    </h2>

                    <div class="buttons is-right">
                        <b-button @click="modalEmitir = false" type="is-info" inverted outlined>
                            Cancelar
                        </b-button>

                        <b-button type="is-info">
                            Confirmar
                        </b-button>
                    </div>
                </b-notification>
            </b-modal>
        </div>
    </section>
</template>

<script>

/**
 * Editor wysiwyg
 */
import EditorWysiwyg from '../components/EditorWysiwyg.vue'

/**
 * Servicios plantillas
 */
import {obtenerPlantillas, crearPlantilla} from '../services/plantillasCorreo.js'

export default {
    name: 'Editor',
    components:{
        EditorWysiwyg
    },
    data(){
        return {
            // selector
            plantillaSeleccionada: 0,
            plantillas: [{
                nombre: 'Plantilla 1',
                html: '<b>TEST 1</b>'
            },{
                nombre: 'Plantilla 2',
                html: '<b>TEST 2</b>'
            }],

            // emitir correo
            modalEmitir: false,

            // crear nueva plantilla
            modalCrearPlantilla: false,
            nombreNuevaPlantilla: ''
        }
    },
    methods:{
        /**
         * Obtiene las plantillas creadas
         */
        async obtenerPlantillas(){
            try {
                let response = await obtenerPlantillas()

                this.plantillas = response.data
            } catch(e){
                alert('Error cargando plantillas')

                console.error(e)
            }
        },
        /**
         * Crea una plantilla
         */
        async crearPlantilla(){
            try {
                let response = await crearPlantilla(
                    this.nombreNuevaPlantilla,
                    this.$refs.editorWysiwyg.getHTML()
                )

                if(typeof response.data !== 'undefined' && response.data){
                    this.plantillas = response.data

                    alert('Plantilla creada correctamente')
                }

                this.modalCrearPlantilla = false

                this.nombreNuevaPlantilla = ''
            } catch(e){
                alert('Error creando la plantilla')

                console.error(e)
            }
        },
        /**
         * Edita una plantilla
         */
        editarPlantilla(){},
        /**
         * Borra una plantilla
         */
        borrarPlantilla(){},
        /**
         * Pinta la plantilla en el editor
         */
        cambioPlantilla(){
            let plantilla = this.plantillas[this.plantillaSeleccionada]

            this.$refs.editorWysiwyg.setHTML(plantilla.html)
        }
    }
}
</script>