<template>
    <div :class="isMedium ? 'buscador-dispo' : 'buscador-dispo-medium'">
        <div class="is-hidden-touch">
            <b-field type="is-primary">
                <AutocompleteEdificios
                v-if="showSelectorEdificios"
                @select="edificioSeleccionado = $event" :size="isMedium ? 'is-medium' : ''" />

                <b-datepicker
                    :size="isMedium ? 'is-medium' : ''"
                    expanded
                    placeholder="Check-in · Check-out"
                    v-model="dates"
                    range
                    icon="calendar"
                    locale="es"
                    :min-date="new Date()">
                </b-datepicker>

                <b-input
                v-model="adults"
                @input="controlOcupacion"
                icon="user" :size="isMedium ? 'is-medium' : ''" type="number" :placeholder="traducir('Adultos')"></b-input>

                <div class="dropdown is-active">
                    <div class="dropdown-trigger">
                        <b-input
                        v-model="children"
                        @input="ajustarInputsEdades"
                        icon="child" :size="isMedium ? 'is-medium' : ''" type="number" :placeholder="traducir('Niños')"></b-input>
                    </div>

                    <div v-if="children > 0" class="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                            <div class="dropdown-item">
                                <span class="has-text-primary">
                                    {{traducir('¿De qué edades son los niños?')}}
                                </span>
                            </div>

                            <div
                            v-for="(edad,indiceEdad) in childrenAges" :key="'ch-'+indiceEdad"
                            v-show="parseInt(indiceEdad) + 1 <= children"
                            class="dropdown-item">
                                <b-input v-model="childrenAges[indiceEdad]" @input="controlEdad(indiceEdad)" type="number"></b-input>
                            </div>
                        </div>
                    </div>
                </div>
                
                <p class="control">
                    <b-button
                    @click="buscar()"
                    type="is-primary" :size="isMedium ? 'is-medium' : ''" :label="traducir('Buscar')" expanded />
                </p>
            </b-field>
        </div>
        
        <div class="is-hidden-desktop">
            <b-field v-if="showSelectorEdificios">
                <AutocompleteEdificios @select="edificioSeleccionado = $event" />
            </b-field>

            <b-field>
                <b-datepicker
                    locale="es"
                    size="is-large"
                    expanded
                    placeholder="Check-in"
                    v-model="dates[0]"
                    range
                    icon="calendar">
                </b-datepicker>
            </b-field>

            <b-field>
                <b-datepicker
                    size="is-large"
                    expanded
                    placeholder="Check-out"
                    v-model="dates[1]"
                    range
                    icon="calendar">
                </b-datepicker>
            </b-field>

            <b-field>
                <b-input
                v-model="adults"
                @input="controlOcupacion"
                icon="user" size="is-large" type="number" :placeholder="traducir('Adultos')"></b-input>
            </b-field>

            <b-field>
                <div class="dropdown is-active">
                    <div class="dropdown-trigger">
                        <b-input
                        @focus="inputMovilFocused = true"
                        @blur="inputMovilFocused = false"
                        v-model="children"
                        @input="ajustarInputsEdades"
                        icon="child" size="is-large" type="number" :placeholder="traducir('Niños')"></b-input>
                    </div>

                    <div v-if="inputMovilFocused && children > 0" class="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                            <div class="dropdown-item">
                                <span class="has-text-primary">
                                    {{traducir('¿De qué edades son los niños?')}}
                                </span>
                            </div>

                            <div
                            v-for="(edad,indiceEdad) in childrenAges" :key="'ch-'+indiceEdad"
                            v-show="(indiceEdad + 1) <= children"
                            class="dropdown-item">
                                <b-input v-model="childrenAges[indiceEdad]"></b-input>
                            </div>
                        </div>
                    </div>
                </div>
            </b-field>
            
            <p class="control">
                <b-button
                @click="buscar()"
                expanded
                type="is-primary" size="is-large" :label="traducir('Buscar')" />
            </p>
        </div>
    </div>
</template>

<script>

/**
 * Autocomplete edificios
 */
import AutocompleteEdificios from './AutocompleteEdificios.vue'

/**
 * Traducciones
 */
import {traducir} from '../services/traducciones.js'

export default {
    name: 'Buscador',
    components:{
        AutocompleteEdificios
    },
    data() {
        return {
            idsAvailRoom: [
                // Todo
                'CF098C43C61E4BB488ECB0A7EC902204',

                // Palace
                '15B1047BDA5940CEA2155FC5A83BFDBA',

                // Salamanca
                '8DFE801B638A11E8811A12B002775151',

                // Glorieta
                'B4CD7FCC31EC4DEF8178CF6EA379420C'
            ],
            edificioSeleccionado:{
                indice: 0
            },
            dates: [
                new Date(),
                new Date()
            ],
            adults: 2,
            children: 0,
            childrenAges: {},

            // habitación que desea el cliente
            habitacion: null,

            // controlar dropdown en móviles
            inputMovilFocused: false
        }
    },
    props:{
        indiceEdificioPredeterminado:{
            type: Number,
            default: 0
        },
        showSelectorEdificios:{
            type: Boolean,
            default: true
        },
        isMedium:{
            type: Boolean,
            default: true
        }
    },
    computed:{
        idioma(){
            return this.$store.state.idioma
        }
    },
    beforeMount(){
        // Por defecto establecemos fecha `hasta` a un día después
        let fechaDesdeMasUnDia = this.$moment(this.dates[0]).add(1, 'days')

        this.$set(
            this.dates,
            1,
            new Date(fechaDesdeMasUnDia)
        )
    },
    methods:{
        traducir(texto){
            return traducir(texto, this.idioma)
        },
        setHabitacion(habitacion){
            this.habitacion = habitacion
        },
        controlEdad(indiceEdad){
            if(this.childrenAges[indiceEdad] < 0){
                setTimeout(() => {
                    this.childrenAges[indiceEdad] = 0
                }, 200)
            }
        },
        controlOcupacion(valor){
            if(valor < 1){
                setTimeout(() => {
                    this.adults = 1
                }, 200)
            }
        },
        ajustarInputsEdades(valor){
            let contadorEdades = Object.keys(this.childrenAges).length

            if(valor > 0){
                // agregar niños
                if(contadorEdades < valor){
                    let diferencia = valor - contadorEdades

                    for(let i = 0; i < diferencia; i++){
                        this.$set(
                            this.childrenAges,
                            contadorEdades + i,
                            5
                        )
                    }
                }
            } else if(valor < 0){
                setTimeout(() => {
                    this.children = 0
                }, 200)
            }
        },
        /**
         * Realiza la búsqueda
         */
        buscar(){
            let query = {
                iniDate: this.$moment(this.dates[0]).format('DD-MM-YYYY'),
                endDate: this.$moment(this.dates[1]).format('DD-MM-YYYY'),
                adults: this.adults,
                language: this.idioma,
                page: 'home'
            }

            // niños
            if(this.children > 0){
                let childrenAges = []

                for(let i = 0; i < this.children; i++){
                    childrenAges.push(this.childrenAges[i])
                }

                query.childrenAges = childrenAges.join(',')
            }

            // edificio preSeleccionado
            if(this.edificioSeleccionado.indice > 0){
                // version 1: funciona pero hay que volver a hacer click en el edificio
                //query.zoneId = this.idsAvailRoom[this.edificioSeleccionado.indice]

                // version 2: funciona pero si no hay dispo no deja ajustarla bien
                query.tourist = true
                
                query.environment = this.idsAvailRoom[0]

                query.approute = 'property/' + this.idsAvailRoom[this.edificioSeleccionado.indice]
            } else if(this.indiceEdificioPredeterminado > 0){
                query.tourist = true
                
                query.environment = this.idsAvailRoom[0]

                query.approute = 'property/' + this.idsAvailRoom[this.indiceEdificioPredeterminado]
            }

            // en estos casos, es posible que el cliente haya especificado que quiere una habitación
            if(this.habitacion && typeof this.habitacion.nombre === 'string'){
                query.habitacion = this.habitacion.nombre
            }

            this.$router.replace({
                name: 'Busqueda',
                query: query
            })
        }
    }
}
</script>

<style>
.buscador-dispo-medium svg{
    height: 1.5rem !important;
}
</style>
