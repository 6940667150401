<template>
    <CanaryLoft
    googleMapsSrc="https://www.google.es/maps/place/C.+de+Manuel+Verdugo,+4,+38006+Santa+Cruz+de+Tenerife/"
    :geolocalizacion="{
        lat: 28.4692543,
        lng: -16.2660282
    }"
    nombre="Canary Lofts Salamanca"
    imagenPortada="./images/canary-loft-salamanca/portada.jpg"
    carpetaImagenesCarousel="canary-loft-salamanca/carousel/"
    :nImagenesCarousel="23"
    :indiceEdificioPredeterminado="2"
    :direccion="direccion"
    :servicios="servicios"
    :comentariosClientes="comentariosClientes"
    :habitaciones="habitaciones"
    :textosDescripcion="textosDescripcion" />
</template>

<script>

/**
 * Perfil edificio Canary Loft
 */
import CanaryLoft from '../components/CanaryLoft.vue'

export default {
    name: 'CanaryLoftSalamanca',
    data(){
        return {
            portada: 'https://www.barcelo.com/pinandtravel/wp-content/uploads/2018/06/1-12.jpg',

            direccion: 'C/ Manuel Verdugo, 4, Santa Cruz de Tenerife, 38006, Santa cruz de tenerife, España',

            textosDescripcion:[
                'Este edificio se encuentra en Santa Cruz de Tenerife, a 1,5 km del Parque García Sanabria, el mayor parque urbano de las islas Canarias. Es perfecto para cortas o largas estancias por motivos laborales o de turismo ya que dispone de un mobiliario moderno, cómodo y bonito. Están bien equipados, con aire acondicionado incluído, y además de disponen de wifi gratuita. Además, está adaptado a personas con movilidad reducida ya que disponemos de ascensor que llega a todas las plantas y rampa de acceso al edificio',
                'Algo que suele gustar mucho es la tranquilidad de la calle donde se encuentra el edificio y que hay una parada de tranvía, parada Puente Zurita, a 3 minutos andando lo que facilita que puedas desplazarte al centro de Santa Cruz o a San Cristóbal de La Laguna de forma rápida y sin necesidad de coger el coche. Por otra parte, aunque en este edificio no disponemos de parking privado, se suele aparcar bien por la zona. Además, el proceso para entrar en el apartamento, es muy sencillo y no es necesario estar esperando para recoger las llaves. Una vez la tengas, mirando el llavero, sabrás qué apartamento es el que te ha tocado. En cada una de las plantas hay unos armarios de limpieza, por si lo necesitas algo en algún momento durante tu estancia, y hay una lavandería en la planta baja del edificio. Por último tiene una terraza en la última planta increíble para poder disfrutar durante la estancia'
            ],

            servicios: [{
                nombre: 'Parking',
                icon: 'parking'
            },{
                nombre: 'Cocina equipada',
                icon: 'utensils'
            },{
                nombre: 'Wi-Fi gratuito',
                icon: 'wifi'
            },{
                nombre: 'Baño privado',
                icon: 'shower'
            },{
                nombre: 'Permitido niños',
                icon: 'children'
            },{
                nombre: 'Terraza comunitaria',
                icon: 'tree'
            },{
                nombre: 'Aceptadas tarjetas de crédito',
                icon: 'credit-card'
            },{
                nombre: 'Habitaciones familiares',
                icon: 'people-group'
            },{
                nombre: 'Fácil acceso',
                icon: 'person-cane'
            },{
                nombre: 'No fumadores',
                icon: 'smoking-ban'
            },{
                nombre: 'Fiestas no permitidas',
                icon: 'champagne-glasses',
                banned: true
            }],

            fotos: [],

            habitaciones: [{
                nombre: 'Estudio',
                foto: './images/canary-loft-salamanca/room_0.jpg',
                descripcion: 'Nuestros estudios están completamente reformados maximizando el espacio útil para que estés lo más cómodo posible durante tu estancia. Están muy equipados: En la cocina podrás encontrar, además de los utensilios más comunes, una cafetera Nesspreso y una italiana, por si te gusta más el café de toda la vida así como una tostadora para que disfrutes de unas tostadas en el desayuno. La zona de dormitorio, que está integrada en la habitación al ser un estudio, cuenta con dos camas de 90 cm unidas. En caso de ser tres personas, el sillón es sillón cama y nos han dicho que, a pesar de eso, se duerme bien y son muy cómodos. La estancia dispone de aire acondicionado para que en los días de calor encuentres la temperatura perfecta. Por último en el baño, que es privado, podrás encontrar además de las toallas correspondientes, un secador de pelo para que no lo estés cargando en la maleta',
                nImagenesCarousel: 10
            },{
                nombre: 'Apartamento 2 niveles',
                foto: './images/canary-loft-salamanca/room_1.jpg',
                descripcion: 'Estos apartamentos los acabamos de reformar y son unos alojamientos independientes a dos alturas con una zona de comedor y zona de estar con TV de pantalla plana con acceso a Netflix. La cocina, que está totalmente equipada, incluye microondas, tostadora, nevera grande y cafetera, tanto Nespresso como la italiana de siempre. Las toallas y la ropa de cama están incluidas. El apartamento está distribuido en dos alturas. En la zona alta, encontrarás un dormitorio con dos camas de 90 cm unidas y con baño privado en la zona alta y con una zona de literas, por si sois un grupo más amplio en la zona baja, junto a la cocina tal y como se ve en las fotos. En esta parte de abajo, también hay otro baño privado',
                nImagenesCarousel: 7
            }],

            comentariosClientes: [
                {
                    comentario: 'El apartamento está muy nuevo, tal y como aparece en las fotos. Cama cómoda.Aunque no dispone de aparcamiento, en los 4 días que estuvimos, aparcamos con facilidad.',
                    valoracion: 5,
                    nombre: 'María Jesús'
                },
                {
                    comentario: 'Todo el apartamento muy nuevo, muy limpio y el personal un trato excelente.',
                    valoracion: 5,
                    nombre: 'Francisco'
                }
            ]
        }
    },
    components:{
        CanaryLoft
    }
}
</script>