import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Paquetes from '../views/Paquetes.vue'
import CheckIn from '../views/CheckIn.vue'
import NuestrosEdificios from '../views/NuestrosEdificios.vue'
import Habitacion from '../views/Habitacion'
import CanaryLoftPalace from '../views/CanaryLoftPalace.vue'
import CanaryLoftSalamanca from '../views/CanaryLoftSalamanca.vue'
import CanaryLoftGlorieta from '../views/CanaryLoftGlorieta.vue'
import Busqueda from '../views/Busqueda.vue'
import PreguntasFrecuentes from '../views/PreguntasFrecuentes.vue'
import Experiencias from '../views/Experiencias.vue'
import AvisoLegal from '../views/AvisoLegal.vue'
import PoliticaPrivacidad from '../views/PoliticaPrivacidad.vue'
import PoliticaCookies from '../views/PoliticaCookies.vue'
//import Feedback from '../views/Feedback.vue'
//import Test from '../views/Test.vue'
import NotFound from '../views/NotFound.vue'

/** Bunabil */
import PortalBunabil from '../views/PortalBunabil.vue'
import LoginPortalBunabil from '../views/LoginPortalBunabil.vue'
import JornadaLimpieza from '../components/JornadaLimpieza.vue'
import Usuarios from '../views/Usuarios.vue'
import Inventario from '../views/Inventario.vue'
import ItemsInventario from '../views/ItemsInventario.vue'
import Lofts from '../views/Lofts.vue'
import Editor from '../views/Editor.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: Home
  },
  {
    path: '/paquetes',
    name: 'Paquetes',
    component: Paquetes
  },
  {
    path: '/checkin',
    name: 'CheckIn',
    component: CheckIn
  },
  {
    path: '/nuestros-edificios',
    name: 'Edificios',
    component: NuestrosEdificios
  },
  {
    path: '/canary-loft-palace',
    name: 'CanaryLoftPalace',
    component: CanaryLoftPalace
  },
  {
    path: '/canary-loft-salamanca',
    name: 'CanaryLoftSalamanca',
    component: CanaryLoftSalamanca
  },
  {
    path: '/canary-loft-glorieta',
    name: 'CanaryLoftGlorieta',
    component: CanaryLoftGlorieta
  },
  {
    path: '/habitacion',
    name: 'Habitacion',
    component: Habitacion
  },
  {
    path: '/busqueda',
    name: 'Busqueda',
    component: Busqueda
  },
  {
    path: '/preguntas-frecuentes',
    name: 'PreguntasFrecuentes',
    component: PreguntasFrecuentes
  },
  {
    path: '/experiencias',
    name: 'Experiencias',
    component: Experiencias
  },
  {
    path: '/aviso-legal',
    name: 'AvisoLegal',
    component: AvisoLegal
  },
  {
    path: '/privacidad',
    name: 'PoliticaPrivacidad',
    component: PoliticaPrivacidad
  },
  {
    path: '/cookies',
    name: 'PoliticaCookies',
    component: PoliticaCookies
  },/*
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },*/
  {
    path: '/portal-bunabil',
    name: 'PortalBunabil',
    component: PortalBunabil,
    children: [{
      path: 'jornada',
      name: 'JornadaLimpieza',
      component: JornadaLimpieza
    },
    {
      path: 'usuarios',
      name: 'Usuarios',
      component: Usuarios
    },
    {
      path: 'inventario',
      name: 'Inventario',
      component: Inventario
    },
    {
      path: 'items',
      name: 'ItemsInventario',
      component: ItemsInventario
    },
    {
      path: 'lofts',
      name: 'Lofts',
      component: Lofts
    },{
      path: 'editor',
      name: 'Editor',
      component: Editor
    }]
  },
  {
    path: '/login-portal-bunabil',
    name: 'LoginPortalBunabil',
    component: LoginPortalBunabil
  },
  {
    path: '*',
    name: '404',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
