<template>
    <div class="container is-fluid">
        <h1 class="title">Ítems inventario</h1>
        <b-button
        @click="modalCrearItemActivo = true"
        type="is-info is-light"
        icon-left="plus-circle"
        expanded>
            Crear nuevo ítem
        </b-button>

        <b-field label="Filtrar ítems inventario" type="is-light" class="mt-1">
            <b-input v-model="filtroItems" placeholder="Filtrar ..."></b-input>
        </b-field>

        <div class="columns">
            <div
            v-for="(item,idItem) in inventarioFiltrado"
            :key="idItem"
            class="column is-2">
                <div class="card">
                    <div
                    @click="modalItem.show = true; modalItem.item = item"
                    class="card-image is-clickable">
                        <figure class="image is-4by3 m-0">
                            <img
                            :src="item.imagen ? item.imagen : 'https://bulma.io/images/placeholders/1280x960.png'"
                            alt="Cargando imagen...">
                        </figure>
                    </div>
                    <div class="card-header">
                        <p class="card-header-title">
                            {{item.nombre}}
                        </p>
                    </div>
                    <div class="card-footer">
                        <a
                        @click="$set(itemEditar,'item',item); $set(itemEditar,'propiedad','nombre'); $set(itemEditar,'nuevoValor',item.nombre); modalEditarItemActivo = true"
                        class="card-footer-item">
                            <b-tooltip type="is-info" label="Cambiar nombre">
                                <b-icon icon="pencil"></b-icon>
                            </b-tooltip>
                        </a>
                        <a
                        @click="$set(itemEditar,'item',item); $set(itemEditar,'propiedad','imagen'); $set(itemEditar,'nuevoValor',item.imagen); modalEditarItemActivo = true"
                        class="card-footer-item">
                            <b-tooltip type="is-info" label="Cambiar imagen">
                                <b-icon icon="image"></b-icon>
                            </b-tooltip>
                        </a>
                        <a
                        @click="$set(itemEditar,'item',item); $set(itemEditar,'propiedad','esLavanderia'); $set(itemEditar,'nuevoValor',item.esLavanderia); modalEditarItemActivo = true"
                        class="card-footer-item">
                            <b-tooltip type="is-info" label="Es lavandería">
                                <b-icon icon="soap"></b-icon>
                            </b-tooltip>
                        </a>
                        <a
                        @click="idItemBorrar = idItem; modalBorrarItemActivo = true"
                        class="card-footer-item has-text-danger">
                            <b-tooltip type="is-danger" :label="'Borrar'">
                                <b-icon icon="trash"></b-icon>
                            </b-tooltip>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Crear nuevo ítem -->
        <b-modal v-model="modalCrearItemActivo">
            <b-notification type="is-info" :closable="false">
                <b-field label="Nombre nuevo ítem" type="is-light" custom-class="has-text-white">
                    <b-input
                    v-model="nombreNuevoItem"
                    expanded
                    placeholder="Nombre nuevo ítem ..."></b-input>
                </b-field>

                <div class="buttons is-right">
                    <b-button @click="modalCrearItemActivo = false" type="is-danger">
                        Cancelar
                    </b-button>

                    <b-button @click="crearItemInventario" type="is-info" outlined inverted>
                        Confirmar
                    </b-button>
                </div>
            </b-notification>
        </b-modal>

        <!-- Eliminar item -->
        <b-modal v-model="modalBorrarItemActivo">
            <b-notification type="is-info" :closable="false">
                <p v-if="inventario[idItemBorrar]" class="subtitle">
                    ¿Borrar <b>{{inventario[idItemBorrar].nombre}}</b> de la base de datos?
                </p>

                <div class="buttons is-right">
                    <b-button @click="modalBorrarItemActivo = false" type="is-danger">
                        Cancelar
                    </b-button>

                    <b-button @click="borrarItemInventario" type="is-info" outlined inverted>
                        Confirmar
                    </b-button>
                </div>
            </b-notification>
        </b-modal>

        <!-- Editar imagen -->
        <b-modal v-model="modalEditarItemActivo">
            <b-notification type="is-info" :closable="false" class="m-0">
                <p v-if="itemEditar.item && inventario[itemEditar.item.id]" class="subtitle">
                    Editando {{itemEditar.propiedad}} <b>{{inventario[itemEditar.item.id].nombre}}</b>
                </p>

                <b-field
                :label="itemEditar.propiedad === 'nombre' ? 'Nuevo nombre' : 'Nueva imagen'"
                custom-class="has-text-white"
                type="is-light">
                    <b-input v-model="itemEditar.nuevoValor"></b-input>
                </b-field>

                <div class="buttons is-right mt-3">
                    <b-button @click="modalEditarItemActivo = false" type="is-danger">
                        Cancelar
                    </b-button>

                    <b-button @click="editarItemInventario" type="is-info" outlined inverted>
                        Confirmar
                    </b-button>
                </div>

                <transition name="slide" v-if="itemEditar.propiedad === 'imagen'">
                    <div v-if="itemEditar.nuevoValor" class="card">
                        <div class="card-image">
                            <figure class="image is-2by1">
                                <img
                                :src="itemEditar.nuevoValor"
                                alt="Cargando imagen...">
                            </figure>
                        </div>
                    </div>
                </transition>
            </b-notification>
        </b-modal>

        <!-- Ver item -->
        <b-modal v-model="modalItem.show">
            <div class="card">
                <div class="card-image">
                    <figure class="image is-4by3 m-0">
                        <img
                        :src="modalItem.imagen ? modalItem.imagen : 'https://bulma.io/images/placeholders/1280x960.png'"
                        alt="Cargando imagen...">
                    </figure>
                </div>
                <div class="card-header">
                    <p v-if="modalItem.item" class="card-header-title">
                        {{modalItem.item.nombre}}
                    </p>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>

/**
 * Manejar inventario
 */
import {crearItemInventario,editarItemInventario,borrarItemInventario} from '../services/inventario.js'

export default {
    data(){
        return {
            // filtrar
            filtroItems: '',

            // ver item
            modalItem:{
                show: false,
                item: null
            },

            // crear item
            modalCrearItemActivo: false,
            nombreNuevoItem: '',

            // borrar item
            modalBorrarItemActivo: false,
            idItemBorrar: '',

            // editar item
            modalEditarItemActivo: false,
            itemEditar: {
                item: null,
                propiedad: '',
                nuevoValor: ''
            },
        }
    },
    computed:{
        inventarioFiltrado(){
            if(this.filtroItems.length === 0){
                return this.inventario
            }

            let inventarioFiltrado = {},
            filtroNormalizado = this.filtroItems.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')

            for(let idItem in this.inventario){
                let nombreNormalizado = this.inventario[idItem].nombre.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')

                if(nombreNormalizado.indexOf(filtroNormalizado) !== -1){
                    inventarioFiltrado[idItem] = this.inventario[idItem]
                }
            }

            return inventarioFiltrado
        },
        inventario(){
            return this.$store.state.inventario
        }
    },
    methods:{
        /**
         * Crea un nuevo ítem en el inventario
         */
        async crearItemInventario(){
            try {
                let respuesta = await crearItemInventario(
                    this.nombreNuevoItem,
                    this.rutaImagenNuevoItem
                )

                this.procesarRespuesta(respuesta)

                alert('Ítem creado correctamente')

                this.modalCrearItemActivo = false
            } catch(e){
                console.error('Error creando ítem inventario:', e)

                alert('Error creando ítem inventario: ' + e.message)
            }
        },
        /**
         * Borra un ítem del inventario
         */
        async borrarItemInventario(){
            try {
                let respuesta = await borrarItemInventario(this.idItemBorrar)

                this.procesarRespuesta(respuesta)

                alert('Ítem borrado correctamente')

                this.modalBorrarItemActivo = false
            } catch(e){
                console.error('Error borrando ítem inventario:', e)

                alert('Error borrando ítem inventario: ' + e.message)
            }
        },
        /**
         * Edita un ítem del inventario
         */
        async editarItemInventario(){
            try {
                let respuesta = await editarItemInventario(
                    this.itemEditar.item.id,
                    this.itemEditar.propiedad,
                    this.itemEditar.nuevoValor
                )

                this.procesarRespuesta(respuesta)

                alert('Ítem editado correctamente')

                this.modalEditarItemActivo = false

                this.itemEditar.item = null

                this.itemEditar.propiedad = ''

                this.itemEditar.nuevoValor = ''
            } catch(e){
                console.error('Error editando ítem inventario:', e)

                alert('Error editando ítem inventario: ' + e.message)
            }
        },
        /**
         * Actualiza el inventario
         */
        procesarRespuesta(respuesta){
            if(typeof respuesta.data === 'object' && respuesta.data){
                this.$store.dispatch('updateInventario', respuesta.data)
            } else {
                let error = typeof respuesta.error === 'string' ? respuesta.error : 'Error inesperado'

                throw new Error(error)
            }
        }
    }
}
</script>
