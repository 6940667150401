<template>
    <div class="container">
        <h1 class="title">Lofts</h1>

        <b-tabs
        v-model="tabEdificioActivo"
        v-if="hayEdificios">
            <b-tab-item
            v-for="(edificio,indiceEdificio) in edificios"
            :key="indiceEdificio"
            :label="edificio.nombre">
                <b-button
                @click="indiceEdificioCrearLoft = indiceEdificio; nuevoLoftEsZonaComun = false; modalCrearLoftActivo = true"
                icon-left="plus-circle"
                type="is-info is-light"
                expanded>Crear loft en <b>{{edificio.nombre}}</b></b-button>

                <table class="table is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                            <th>Nombre loft</th>
                            <th></th>
                            <th class="has-text-centered">Opciones</th>
                        </tr>
                    </thead>
                    <tbody
                    v-for="(loft,idLoft) in lofts[indiceEdificio]"
                    :key="idLoft">
                        <tr>
                            <td>
                                {{loft.nombre}}
                            </td>
                            <td class="has-text-centered">
                                <b-tag v-if="loftEsZonaComun(loft)" type="is-info">
                                    Zona común
                                </b-tag>
                            </td>
                            <td class="has-text-centered">
                                <b-button
                                class="mr-1"
                                icon-left="pencil"
                                type="is-info"
                                @click="loftEditar = loft; modalEditarLoftActivo = true">
                                    Editar
                                </b-button>
                                <b-button
                                icon-left="trash"
                                type="is-danger"
                                @click="loftEliminar = loft; modalEliminarLoftActivo = true">
                                    Borrar
                                </b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </b-tab-item>
        </b-tabs>

        <!-- Crear loft -->
        <b-modal v-model="modalCrearLoftActivo">
            <b-notification type="is-info" :closable="false">
                <div class="columns">
                    <div class="column">
                        <b-field label="Nombre nuevo loft" type="is-light" custom-class="has-text-white">
                            <b-input
                            v-model="nombreNuevoLoft"
                            expanded
                            placeholder="Nombre nuevo loft ..."></b-input>
                        </b-field>
                    </div>

                    <div class="column">
                        <b-field label="¿Es zona común?" type="is-light" custom-class="has-text-white">
                            <b-switch v-model="nuevoLoftEsZonaComun" type="is-light">
                                {{nuevoLoftEsZonaComun ? 'Sí' : 'No'}}
                            </b-switch>
                        </b-field>
                    </div>
                </div>

                <div class="buttons is-right">
                    <b-button @click="modalCrearLoftActivo = false" type="is-danger">
                        Cancelar
                    </b-button>

                    <b-button
                    :disabled="nombreNuevoLoft.length === 0"
                    @click="crearLoft" type="is-info" outlined inverted>
                        Confirmar
                    </b-button>
                </div>
            </b-notification>
        </b-modal>

        <!-- Editar loft -->
        <b-modal v-model="modalEditarLoftActivo">
            <b-notification type="is-info" :closable="false">
                <h2 v-if="loftEditar" class="subtitle">
                    Editando loft <b>{{loftEditar.nombre}}</b>
                </h2>
                <b-field
                v-if="loftEditar"
                :label="'Nuevo nombre para ' + loftEditar.nombre"
                type="is-light" custom-class="has-text-white">
                    <b-input
                    v-model="nuevoNombreLoftEditar"
                    expanded
                    :placeholder="'Nuevo nombre para ' + loftEditar.nombre"></b-input>
                </b-field>

                <div class="buttons is-right">
                    <b-button @click="modalEditarLoftActivo = false" type="is-danger">
                        Cancelar
                    </b-button>

                    <b-button @click="editarLoft" type="is-info" outlined inverted>
                        Confirmar
                    </b-button>
                </div>
            </b-notification>
        </b-modal>

        <!-- Borrar loft -->
        <b-modal v-model="modalEliminarLoftActivo">
            <b-notification type="is-info" :closable="false">
                <h2 v-if="loftEliminar" class="subtitle">
                    ¿Borrar loft <b>{{loftEliminar.nombre}}</b>?
                </h2>

                <div class="buttons is-right">
                    <b-button @click="modalEliminarLoftActivo = false" type="is-danger">
                        Cancelar
                    </b-button>

                    <b-button @click="eliminarLoft" type="is-info" outlined inverted>
                        Confirmar
                    </b-button>
                </div>
            </b-notification>
        </b-modal>
    </div>
</template>

<script>

/**
 * Gestionar lofts
 */
import {crearLoft,eliminarLoft,editarLoft} from '../services/lofts.js'

export default {
    name: 'VistaLofts',
    data(){
        return {
            tabEdificioActivo: 0,

            // crear loft
            modalCrearLoftActivo: false,
            indiceEdificioCrearLoft: null,
            nombreNuevoLoft: '',
            nuevoLoftEsZonaComun: false,

            // eliminar loft
            modalEliminarLoftActivo: false,
            loftEliminar: null,

            // editar loft
            modalEditarLoftActivo: false,
            loftEditar: null,
            nuevoNombreLoftEditar: ''
        }
    },
    computed:{
        hayEdificios(){
            for(let indiceEdificio in this.edificios){
                return true
            }

            return false
        },
        lofts(){
            return this.$store.state.lofts
        },
        edificios(){
            return this.$store.state.edificios
        }
    },
    methods:{
        /**
         * Indica si un loft es una zona común
         */
        loftEsZonaComun(loft){
            return typeof loft.zonaComun === 'boolean' && loft.zonaComun
        },
        /**
         * Edita un loft
         */
        async editarLoft(){
            try {
                let respuesta = await editarLoft(
                    this.loftEditar.id,
                    'nombre',
                    this.nuevoNombreLoftEditar
                )

                this.procesarRespuesta(respuesta)

                this.modalEditarLoftActivo = false

                alert('Loft "' + this.loftEditar.nombre + '" editado correctamente a "' + this.nuevoNombreLoftEditar + '"')

                this.nuevoNombreLoftEditar = ''

                this.loftEditar = null
            } catch(e){
                console.error('Error editando loft:', e)

                alert('Error editando loft:' + e.message)
            }
        },
        /**
         * Elimina un loft
         */
        async eliminarLoft(){
            try {
                let respuesta = await eliminarLoft(this.loftEliminar.id)

                this.procesarRespuesta(respuesta)

                this.modalEliminarLoftActivo = false

                alert('Loft "' + this.loftEliminar.nombre + '" eliminado correctamente')

                this.loftEliminar = null
            } catch(e){
                console.error('Error eliminando loft:', e)

                alert('Error eliminando loft:' + e.message)
            }
        },
        /**
         * Crea un nuevo loft
         */
        async crearLoft(){
            try {
                let respuesta = await crearLoft(
                    this.indiceEdificioCrearLoft,
                    this.nombreNuevoLoft,
                    this.nuevoLoftEsZonaComun
                )

                this.procesarRespuesta(respuesta)

                this.modalCrearLoftActivo = false

                alert('Loft "' + this.nombreNuevoLoft + '" creado correctamente')

                this.nombreNuevoLoft = ''

                this.nuevoLoftEsZonaComun = false

                this.indiceEdificioCrearLoft = null
            } catch(e){
                console.error('Error creando loft:', e)

                alert('Error creando loft:' + e.message)
            }
        },
        procesarRespuesta(respuesta){
            if(typeof respuesta.data === 'object' && respuesta.data){
                this.$store.dispatch('updateLofts', respuesta.data)
            } else {
                let error = typeof respuesta.error === 'string' ? respuesta.error : 'Error inesperado'

                throw new Error(error)
            }
        }
    }
}
</script>