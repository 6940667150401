import axios from 'axios'

/**
 * Endpoint servicios Bunabil
 * @var {String}
 */
//const apiEndPoint = 'http://' + window.location.hostname + '/bunabil/'
//const apiEndPoint = 'https://www.canarylofts.es/v2/bunabil/'

export const call = async (service, params) => {
    let requestBody = new URLSearchParams({
        service: service,
        params: JSON.stringify(params)
    })

    /*return axios.post(
        apiEndPoint,// + '?nocache=' + new Date().getTime(), // Safari fix
        requestBody,
        {
            withCredentials: true
        }
    )*/

    // PRODUCCIÓN: usar ruta relativa para evitar CORS
    return axios.post('/v2/bunabil/', requestBody, {
        withCredentials: true
    })

    // Procesar respuesta
    .then((response) => {
        if(response.status !== 200){
            throw new Error(response)
        }

        return response.data
    })

    // Manejar error
    .catch((e) => {
        throw new Error(e)
    })
}
