<template>
    <section class="not-found section">
        <div class="container has-backbround-primary is-fluid">
            <h1 class="title is-size-1">
                <b-icon icon="signs-post" size="is-large"></b-icon> 404
            </h1>
            <h1 class="subtitle">Ups, estamos trabajando en la web y todavía no tenemos todos los enlaces terminados, ¡lo siento! Si quieres, puedes mirar la disponibilidad que tenemos y hacer tu reserva aquí <b-button tag="router-link" :to="{name: 'Busqueda'}" type="is-primary">Buscar disponibilidad</b-button></h1>
        </div>
    </section>
</template>

<script>

export default {
    name: 'NotFound'
}

</script>