
/**
 * Conectarnos con la API de canary lofts
 */
import {call} from './canaryLofts.js'

/**
 * Envia un correo
 */
export const enviarCorreo = (nombre, correo, mensaje) => {
    return call('enviarCorreo', {
        nombre: nombre,
        email: correo,
        mensaje: mensaje
    })
}