<template>
    <footer class="footer has-text-justified has-background-primary has-text-secondary mt-3">
        <div class="container">
            <h1 class="has-text-secondary">
                <span class="has-text-weight-light is-size-5">Canary</span>
                <span class="has-text-weight-bold is-size-5">Lofts</span>
            </h1>

            <p class="has-text-centered my-6 has-text-secondary">
                {{traducir('Apartamentos en el centro de Santa Cruz de Tenerife para alojarte por días, meses o largas temporadas', idioma)}}
            </p>

            <div class="columns">
                <div class="column">
                    <span class="has-text-weight-bold is-size-5">{{traducir('Información general', idioma)}}</span>
                    <ul>
                        <li>
                            <router-link class="has-text-secondary" :to="{ name: 'Inicio' }">{{traducir('Inicio', idioma)}}</router-link>
                        </li>
                        <li>
                            <router-link class="has-text-secondary" :to="{ name: 'Inicio' }">{{traducir('Acerca de nosotros', idioma)}}</router-link>
                        </li>
                        <li>
                            <router-link class="has-text-secondary" :to="{ name: 'Inicio' }">{{traducir('Reserva con nosotros', idioma)}}</router-link>
                        </li>
                        <li>
                            <router-link class="has-text-secondary" :to="{ name: 'Inicio' }">{{traducir('Nuestros edificios', idioma)}}</router-link>
                        </li>
                        <li>
                            <router-link class="has-text-secondary" :to="{ name: 'Inicio' }">{{traducir('Tarifas', idioma)}}</router-link>
                        </li>
                        <li>
                            <router-link class="has-text-secondary" :to="{ name: 'Inicio' }">{{traducir('Conoce a nuestro equipo', idioma)}}</router-link>
                        </li>
                    </ul>
                </div>

                <div class="column">
                    <span class="has-text-weight-bold is-size-5">{{traducir('Gestión de mi reserva', idioma)}}</span>
                    <ul>
                        <li>
                            <router-link class="has-text-secondary" :to="{ name: 'CheckIn' }">Check In Online!</router-link>
                        </li>
                        <li>
                            <router-link class="has-text-secondary" :to="{ name: 'CheckIn' }">{{traducir('Quiero cancelar mi reserva', idioma)}}</router-link>
                        </li>
                    </ul>
                </div>

                <div class="column">
                    <span class="has-text-weight-bold is-size-5">{{traducir('Condiciones y políticas', idioma)}}</span>
                    <ul>
                        <!--<li>
                            <router-link class="has-text-secondary" :to="{ path: '/politicas-cancelacion' }">Cancelaciones</router-link>
                        </li>-->
                        <li>
                            <router-link class="has-text-secondary" :to="{ path: '/aviso-legal' }">{{traducir('Aviso legal', idioma)}}</router-link>
                        </li>
                        <li>
                            <router-link class="has-text-secondary" :to="{ path: '/cookies' }">{{traducir('Política de cookies', idioma)}}</router-link>
                        </li>
                        <li>
                            <router-link class="has-text-secondary" :to="{ path: '/privacidad' }">{{traducir('Política de privacidad', idioma)}}</router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <span class="has-text-weight-bold is-size-5">{{traducir('Eventos locales', idioma)}}</span>
                    <ul>
                        <li>
                            <router-link class="has-text-secondary" :to="{ path: '/experiencias' }">{{traducir('Reserva de actividades', idioma)}}</router-link>
                        </li>
                        <li>
                            <router-link class="has-text-secondary" :to="{ path: '/experiencias' }">{{traducir('¿Qué ver en Santa Cruz de Tenerife?', idioma)}}</router-link>
                        </li>
                        <li>
                            <router-link class="has-text-secondary" :to="{ path: '/servicios' }">{{traducir('Restaurantes', idioma)}}</router-link>
                        </li>
                        <li>
                            <router-link class="has-text-secondary" :to="{ path: '/servicios' }">{{traducir('¿Necesitas un taxi?', idioma)}}</router-link>
                        </li>
                    </ul>
                </div>

                <div class="column">
                    <span class="has-text-weight-bold is-size-5">{{traducir('Ayuda', idioma)}}</span>
                    <ul>
                        <li>
                            <router-link class="has-text-secondary" :to="{ path: '/preguntas-frecuentes' }">{{traducir('Preguntas frecuentes', idioma)}}</router-link>
                        </li>
                        <li>
                            <router-link class="has-text-secondary" :to="{ path: '/servicios' }">{{traducir('Mapa', idioma)}}</router-link>
                        </li>
                        <li>
                            <router-link class="has-text-secondary" :to="{ path: '/servicios' }">{{traducir('Manuales', idioma)}}</router-link>
                        </li>
                    </ul>
                </div>

                <div class="column">
                    <span class="has-text-weight-bold is-size-5">{{traducir('Encuéntranos en', idioma)}}</span>

                    <RedesSociales type="is-secondary" />

                </div>
            </div>

            <DatosContacto />
        </div>
    </footer>
</template>

<script>

/**
 * Redes sociales
 */
import RedesSociales from './RedesSociales.vue'

/**
 * Datos de contacto
 */
import DatosContacto from './DatosContacto.vue'

/**
 * Traducciones
 */
import {traducir} from '../services/traducciones.js'

export default {
    name: 'Footer',
    components:{
        RedesSociales,
        DatosContacto
    },
    computed:{
        idioma(){
            return this.$store.state.idioma
        }
    },
    methods:{
        traducir:traducir
    }
}
</script>