<template>
    <div class="vista-edificio">
        <ImagenesPortada
        :isFullHeight="false"
        :isMedium="true"
        :imageUrl="imagenPortada"/>

        <section class="section">
            <div class="container">
                <div class="columns">
                    <div class="column is-4 has-background-primary has-text-secondary" style="margin-top:-15vh;border-radius:0.15rem;">
                        <h1 @click="scrollServicios()" id="ancla-titulo-edificio" class="pt-2 title has-text-weight-light has-text-centered has-text-secondary is-clickable">
                            {{nombre}}
                        </h1>

                        <b-dropdown :triggers="['hover']" aria-role="dialog">
                            <template #trigger>
                                <p class="is-size-6 has-text-justified pb-3">
                                    <a
                                    class="has-text-secondary"
                                    :href="googleMapsSrc" target="_blank">
                                        <b-icon icon="location-dot" custom-class="controlar"></b-icon> {{direccion}}
                                    </a>
                                </p>
                            </template>

                            <b-dropdown-item custom focusable paddingless>
                                <GmapMap
                                :center="geolocalizacion"
                                :zoom="18"
                                map-type-id="roadmap"
                                style="width: 500px; height: 300px">
                                    <GmapMarker
                                    :position="geolocalizacion"
                                    :clickable="true"
                                    :draggable="true"
                                    @click="center=geolocalizacion" />
                                </GmapMap>
                            </b-dropdown-item>
                        </b-dropdown>

                        <DatosContacto />

                        <hr class="m-0 p-0 has-background-secondary" />

                        <p
                        v-for="(texto,indiceTexto) in textosDescripcion"
                        :key="'txt'+indiceTexto"
                        class="has-text-justified mt-4">
                            {{texto}}
                        </p>

                        <!-- Servicios -->
                        <div
                        id="ancla-servicios-edificio"
                        v-for="(grupoServicio,indiceGrupoServicio) in gruposServicios"
                        :key="'grp'+indiceGrupoServicio"
                        class="mt-3 columns is-mobile has-text-secondary caja-servicios-canary-loft">
                            <div
                            v-for="(servicio,indiceServicio) in grupoServicio"
                            :key="'srv'+indiceServicio"
                            class="column has-text-centered is-size-7">
                                <vue-fontawesome-layers v-if="servicio.banned" class="mb-3">
                                    <b-icon icon="slash"></b-icon>
                                    <b-icon :icon="servicio.icon"></b-icon>
                                </vue-fontawesome-layers>

                                <b-icon v-else :icon="servicio.icon"></b-icon>

                                <br>

                                {{traducir(servicio.nombre)}}
                            </div>
                        </div>
                    </div>

                    <div class="column pt-0 columna-comentarios">
                        <!--Separador móvil-->
                        <div class="is-hidden-tablet pt-3"></div>

                        <!--Comentarios fotantes ( desktop )-->
                        <div class="caja-comentarios" v-if="!isMobile">
                            <b-carousel
                            :autoplay="verComentarios"
                            :arrow="verComentarios && comentariosClientes.length > 1"
                            :indicator="false">
                                <b-carousel-item
                                v-for="(comentario,indiceComentario) in comentariosClientes"
                                :key="'cmt'+indiceComentario">
                                    <transition name="slide">
                                        <div
                                        v-if="verComentarios"
                                        class="card has-background-secondary has-text-primary">
                                            <div class="card-content">
                                                <p>
                                                    <b class="mr-1">{{comentario.nombre}}</b>
                                                    <b-icon icon="star" v-for="i in comentario.valoracion" :key="'val'+i" size="is-small"></b-icon>
                                                    <br>
                                                    {{comentario.comentario}}
                                                </p>
                                            </div>
                                        </div>
                                    </transition>
                                    <div class="card has-background-secondary has-text-primary">
                                        <div class="card-footer">
                                            <a @click="verComentarios = !verComentarios" class="card-footer-item">
                                                {{textoVerComentarios}}
                                            </a>
                                        </div>
                                    </div>
                                </b-carousel-item>
                            </b-carousel>
                        </div>

                        <!--Fotos-->
                        <b-carousel
                        ref="carouselGaleria"
                        icon-pack="fas"
                        icon-prev="chevron-left"
                        icon-next="chevron-right"
                        :arrow="true"
                        :autoplay="false"
                        :indicator="false"
                        :indicator-inside="false"
                        :overlay="gallery"
                        @click="switchGallery(true)">
                            <b-carousel-item
                            v-for="i in nImagenesCarousel"
                            :key="i">
                                <a class="image">
                                    <img :src="getImgUrl(i)">
                                </a>
                            </b-carousel-item>
                            <span v-if="gallery" @click="switchGallery(false)" class="modal-close is-large"/>
                        </b-carousel>

                        <!--Comentarios estáticos ( móvil )-->
                        <b-carousel
                        v-if="isMobile"
                        :autoplay="verComentarios"
                        :arrow="verComentarios && comentariosClientes.length > 1"
                        :indicator="false">
                            <b-carousel-item
                            v-for="(comentario,indiceComentario) in comentariosClientes"
                            :key="'cmt'+indiceComentario">
                                <transition name="slide">
                                    <div
                                    v-if="verComentarios"
                                    class="card has-background-secondary has-text-primary">
                                        <div class="card-content">
                                            <p>
                                                <b class="mr-1">{{comentario.nombre}}</b>
                                                <b-icon icon="star" v-for="i in comentario.valoracion" :key="'val'+i"></b-icon>
                                                <br>
                                                {{comentario.comentario}}
                                            </p>
                                        </div>
                                    </div>
                                </transition>
                                <div class="card has-background-secondary has-text-primary">
                                    <div class="card-footer">
                                        <a @click="verComentarios = !verComentarios" class="card-footer-item">
                                            {{textoVerComentarios}}
                                        </a>
                                    </div>
                                </div>
                            </b-carousel-item>
                        </b-carousel>

                        <div class="container mt-3">
                            <div class="columns">
                                <div class="column caja-normas-casa">
                                    <h2 class="is-size-4 has-text-primary has-text-weight-light">
                                        {{traducir('Qué debes saber')}}
                                    </h2>
                                    <b class="has-text-primary">{{traducir('Normas de la casa')}}</b>
                                    <ul>
                                        <li class="has-text-primary">
                                            <b-icon icon="clock" class="mr-1"></b-icon>
                                            <span>{{traducir('Llegada')}}: {{traducir('a partir de las')}} 15:00</span>
                                        </li>
                                        <li class="has-text-primary">
                                            <b-icon icon="clock" class="mr-1"></b-icon>
                                            <span>{{traducir('Salida')}}: 10:00</span>
                                        </li>
                                        <li class="has-text-primary">
                                            <b-icon icon="smoking-ban" class="mr-1"></b-icon>
                                            <span>{{traducir('Prohibido fumar')}}</span>
                                        </li>
                                        <li class="has-text-primary">
                                            <vue-fontawesome-layers class="mr-1">
                                                <b-icon icon="slash"></b-icon>
                                                <b-icon icon="dog"></b-icon>
                                            </vue-fontawesome-layers>
                                            <span>{{traducir('No se admiten mascotas')}}</span>
                                        </li>
                                        <li class="has-text-primary">
                                            <!--<b-icon icon="" class="has-text-primary mr-1"></b-icon>-->
                                            <vue-fontawesome-layers class="mr-1">
                                                <b-icon icon="slash"></b-icon>
                                                <b-icon icon="champagne-glasses"></b-icon>
                                            </vue-fontawesome-layers>
                                            <span>{{traducir('No se admiten fiestas')}}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="column"></div>
                            </div>
                            
                            <h2 class="my-3 is-size-4 has-text-primary has-text-weight-light">
                                {{traducir('Disponibilidad')}}
                            </h2>

                            <Buscador
                            ref="buscadorDispo"
                            :indiceEdificioPredeterminado="indiceEdificioPredeterminado"
                            :showSelectorEdificios="false"
                            :isMedium="false" />
                        </div>
                    </div>
                </div>

                <div
                v-for="(habitacion,indiceHabitacion) in habitaciones"
                :key="'hab'+indiceHabitacion"
                class="caja-habitacion columns mt-6"
                :class="{'desktop': !isMobile}">
                    <div class="column is-4 px-0">
                        <figure class="image">
                            <b-image  :src="habitacion.foto" :alt="habitacion.nombre"></b-image>
                        </figure>
                    </div>
                    <div class="column">
                        <div class="content">
                            <h2 class="mb-3 is-size-4 has-text-primary has-text-weight-light">
                                {{habitacion.nombre}}
                            </h2>

                            <p class="has-text-justified has-text-primary">
                                {{habitacion.descripcion}}
                            </p>

                            <div class="buttons is-centered">
                                <b-button
                                @click="irAHabitacion(habitacion, indiceHabitacion)"
                                type="is-primary">{{traducir('Ver más')}}</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

/**
 * Dispositivos
 */
import {getDeviceSizeType} from '../services/devices.js'

/**
 * Buscador
 */
import Buscador from '../components/Buscador.vue'

/**
 * Datos contacto
 */
import DatosContacto from '../components/DatosContacto.vue'

/**
 * Imágenes portada
 */
import ImagenesPortada from '../components/ImagenesPortada.vue'

/**
 * Traducciones
 */
import {traducir} from '../services/traducciones.js'

export default {
    name: 'CanaryLoft',
    data() {
        return {
            gallery: false,
            verComentarios: true
        }
    },
    components:{
        Buscador,
        DatosContacto,
        ImagenesPortada
    },
    props:{
        geolocalizacion:{
            type: Object,
            default: null
        },
        nImagenesCarousel:{
            type: Number,
            default: 0
        },
        carpetaImagenesCarousel:{
            type: String,
            default: ''
        },
        imagenPortada:{
            type: String,
            default: ''
        },
        indiceEdificioPredeterminado:{
            type: Number,
            default: 0
        },
        googleMapsSrc:{
            type: String,
            default: ''
        },
        nombre:{
            type: String,
            default: ''
        },
        direccion:{
            type: String,
            default: ''
        },
        textosDescripcion:{
            type: Array,
            default(){
                return []
            }
        },
        servicios:{
            type: Array,
            default(){
                return []
            }
        },
        habitaciones:{
            type: Array,
            default(){
                return []
            }
        },
        comentariosClientes:{
            type: Array,
            default(){
                return []
            }
        }
    },
    computed:{
        textoVerComentarios(){
            let texto = 'Ver comentarios'

            if(this.verComentarios){
                texto = 'Ocultar comentarios'
            }

            return this.traducir(texto, this.idioma)
        },
        idioma(){
            return this.$store.state.idioma
        },
        isMobile(){
            return this.tipoDispositivo === 'is-mobile'
        },
        tipoDispositivo(){
            return this.getDeviceSizeType()
        },
        gruposServicios(){
            let gruposServicios = [],
            indiceGrupoActual = 0,
            serviciosPorFila = 4,
            contador = 0

            for(let s in this.servicios){
                if(typeof gruposServicios[indiceGrupoActual] === 'undefined'){
                    gruposServicios[indiceGrupoActual] = []
                }

                gruposServicios[indiceGrupoActual].push(this.servicios[s])

                contador++
                
                if(contador % serviciosPorFila === 0){
                    indiceGrupoActual++
                }
            }

            return gruposServicios
        }
    },
    mounted(){
        document.getElementById('ancla-titulo-edificio').scrollIntoView({
            behavior:'smooth',
            block:'end'
        })
    },
    methods: {
        traducir(texto){
            return traducir(texto, this.idioma)
        },
        irAHabitacion(habitacion, indiceHabitacion){
            this.$router.push({
                name: 'Habitacion',
                params: {
                    edificio: {
                        nombre: this.nombre,
                        indice: this.indiceEdificioPredeterminado,
                        servicios: this.servicios
                    },
                    habitacion: habitacion,
                    indiceHabitacion: indiceHabitacion
                }
            })
        },
        getDeviceSizeType:getDeviceSizeType,
        scrollServicios(){
            document.getElementById('ancla-servicios-edificio').scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            })
        },
        getImgUrl(value) {
            return './images/' + this.carpetaImagenesCarousel + value + '.jpg'
        },
        switchGallery(value) {
            this.gallery = value
            if (value) {
                return document.documentElement.classList.add('is-clipped')
            } else {
                return document.documentElement.classList.remove('is-clipped')
            }
        }
    }
}
</script>

<style>
.caja-comentarios{
    position: absolute;
    right: 0;
    z-index: 10;
    width: 25%;
}

.caja-comentarios .card{
    border-radius: 0;
}

p{
    text-indent: 1rem;
}

.caja-habitacion.desktop figure.image{
    height: 30vh;
    overflow: hidden;
}

.caja-habitacion img{
    border-radius: 0.15rem;
}

.caja-habitacion.desktop img{
    margin-top: -125px !important;
}

#ancla-servicios-edificio svg{
    height: 2rem !important;
}

</style>